import React from 'react';
import * as Fields from '../../../lis-shared/lis-layout/components/lis-forms-component';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactToPrint from "react-to-print";
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import { calendarActionCreators } from '../../../stores/lis-calendar-store';
import CommitteeBillsGrid from './lis-committee-bills-grid';
import DocketDetailsComponent from '../../../lis-shared/lis-dockets/lis-docket-details'
import DocketPatronList from './lis-docket-patron-list';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { scheduleActionCreators } from '../../../stores/lis-schedule-store';
import { cancelRequest } from '../../../services/request.service';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { billActionCreators } from '../../../stores/lis-legislation-store';
import { Prompt } from 'react-router';

const PUBLISH_DOCKET = true;
const UNPUBLISH_DOCKET = false;
const SAVE_DOCKET = false;

const TIME_REGEX = /[0-9]:[0-9]{2}\s[AP]M/i;

class DocketFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            data: '',
            categories: [],
            selectAllActive: false,
            selectAllInactive: false,
            showPreview: false,
            isSaving: false,
            pendingSave: false,
            committeeClerk: [],
            committeeStaff: [],
            committeeLeadership: '',
            meetingRoomsList: [],
            selectedSession: '',
            hideClerk: false,
            hideStaff: false,
            hidePatron: false,
            hideSummary: false,
            hideTitle: false,
            hideLDNumber: false,
            hideCaption: false,
            showPatronList: false,
            patronListDocketByIdData: '',
            sessionBills: [],
            isDirty: false
        };
        this.togglePreview = this.togglePreview.bind(this);
        this.updateDocket = this.updateDocket.bind(this);
        this.toggleAllActive = this.toggleAllActive.bind(this);
        this.handleSequenceChange = this.handleSequenceChange.bind(this);
        this.saveDocket = this.saveDocket.bind(this);
        this.handleMeetingRoomChange = this.handleMeetingRoomChange.bind(this);
        this.toggleDocketSection = this.toggleDocketSection.bind(this);
        this.downloadWordFile = this.downloadWordFile.bind(this);
        this.togglePatronList = this.togglePatronList.bind(this);
        this.getBill = this.getBill.bind(this);
        this.handleCommentHeight = this.handleCommentHeight.bind(this);
    }

    componentDidMount() {
        var id = this.props.match.params.docketid;
        var docketBillParams = '?docketId=' + id;
        this.props.actions.getDocketById(docketBillParams, true)
            .then(() => {
                this.props.actions.getCommitteeList("includeSubcommittees=true&effectiveDate=" + this.props.session.sessionList.find(x => x.SessionID === this.props.calendar.docketByIdData.SessionID).SessionEvents.find(x => x.EventTypeID === 1).ActualDate)
                this.getDocketBills(this.props.calendar.docketByIdData);
                if (this.props.calendar.docketByIdData.CalendarDisplay) {
                    for (let i = 0; i < this.props.calendar.docketByIdData.CalendarDisplay.length; i++) {
                        switch (this.props.calendar.docketByIdData.CalendarDisplay[i].DisplayColumn) {
                            case ("Staffs Name"):
                                this.setState({
                                    hideClerk: !this.props.calendar.docketByIdData.CalendarDisplay[i].IsDisplayed
                                });
                                break;
                            case ("Clerk Name"):
                                this.setState({
                                    hideStaff: !this.props.calendar.docketByIdData.CalendarDisplay[i].IsDisplayed
                                });
                                break;
                            case ("Chief Patron"):
                                this.setState({
                                    hidePatron: !this.props.calendar.docketByIdData.CalendarDisplay[i].IsDisplayed
                                });
                                break;
                            case ("Summary"):
                                this.setState({
                                    hideSummary: !this.props.calendar.docketByIdData.CalendarDisplay[i].IsDisplayed
                                });
                                break;
                            case ("Title"):
                                this.setState({
                                    hideTitle: !this.props.calendar.docketByIdData.CalendarDisplay[i].IsDisplayed
                                });
                                break;
                            case ("LD Number"):
                                this.setState({
                                    hideLDNumber: !this.props.calendar.docketByIdData.CalendarDisplay[i].IsDisplayed
                                });
                                break;
                            case ("Caption"):
                                this.setState({
                                    hideCaption: !this.props.calendar.docketByIdData.CalendarDisplay[i].IsDisplayed
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }

                this.props.actions.getBillNumbers('?sessionID=' + this.props.calendar.docketByIdData.SessionID).then(() => {
                    let billNumbers = this.props.bills.billNumbers;
                    billNumbers.forEach(bill => {
                        bill.label = bill.LegislationNumber;
                        bill.value = bill.LegislationNumber;
                    })
                    this.setState({
                        sessionBills: billNumbers
                    });
                })

                this.props.actions.getCalendarCategoriesReferences();
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    message: 'Failed to retrieve data'
                });
            });
    }

    componentWillUnmount() {
        cancelRequest();
    }

    togglePreview() {
        this.setState(state => ({
            showPreview: !state.showPreview
        }), () => {
            if (this.state.showPreview) {
                window.scrollTo({
                    top: 0,
                    left: 0,
                });
            }
        });
    }

    // GET MEETING ROOMS REFERENCES
    getMeetingRoomsReferences(chamberCode) {
        var params = '?chamberCode=' + chamberCode;

        this.props.actions.getMeetingRoomReferences(params).then(() => {
            const meetingRoomsList = Array.from(this.props.schedule.meetingRoomReferences, obj => {
                const rObj = {
                    ...obj,
                    value: obj.VoteRoomID,
                    label: obj.Description + ' ' + (obj.RoomNumber || '')
                };
                return rObj;
            });
            this.setState({
                meetingRoomsList: meetingRoomsList,
            });
        })
    }

    // GET BILLS FOR DOCKET GRID
    getDocketBills(data) {
        const categories = data.DocketCategories;

        //This will be used to tell if any bills have already been saved to the docket. If they have then enable the preview button.
        //If not then grey out the preview button until a save has been performed
        let noSavedItems = true;
        let billList = [];
        categories.forEach(category => {
            billList = category.DocketItems;
            billList.forEach(element => {
                //Item has a docket ID so this means that a bill has been previously saved to this docket
                if (element.DocketItemID) {
                    noSavedItems = false;
                }
            });
        });


        // Set Clerk & Staff
        let committeeClerk = [];
        let committeeStaff = [];


        if (data.Staff) {
            committeeClerk = data.Staff.filter(clerk => clerk.StaffRoleTypeID === 1);
            committeeStaff = data.Staff.filter(staff => staff.StaffRoleTypeID === 2);
        }

        // Set Committee Leadership
        let committeeLeadership = '';

        if (data.CommitteeMember) {
            data.CommitteeMember.forEach((element, i) => {
                var memberDelimiter = data.CommitteeMember.length === 1 || i + 1 !== data.CommitteeMember.length ? '' : ', ';
                committeeLeadership = committeeLeadership + memberDelimiter + element.Title + ': ' + element.MemberDisplayName;
            });
        }

        this.setState({
            pendingSave: noSavedItems,
            committeeLeadership: committeeLeadership,
            committeeClerk: committeeClerk,
            committeeStaff: committeeStaff,
            data: data,
            isLoaded: true
        }, () => {
            this.getMeetingRoomsReferences(data.ChamberCode)
            this.props.actions.getSessionById(data.SessionID)
                .then(() =>
                    this.setState({ selectedSession: this.props.session.selectedSession })
                );
            this.state.data.Schedules.forEach((schedule, i) => {
                this.handleCommentHeight(i)
            })
        });

        //Get the calendar category types. This is used to create a blank notes category
        this.props.actions.getCalendarCategoriesReferences()
            .then(() => {
                // Find out of there is a notes category already saved to the docket. If there isn't then add a default one.
                // Notes categories should be the only non-legislative category
                let notesCategories = categories.filter(category => !category.IsLegislationCategory);
                if (notesCategories.length === 0) {
                    // Using the Senate Docket Notes category for notes
                    let notesCategory = this.props.calendar.calendarCategoriesReferences.find(calCategory => calCategory.CategoryCode === "CSNOT");
                    if (notesCategory) {
                        notesCategory.Sequence = 1;
                        notesCategory.DocketItems = [
                            {
                                Description: '',
                                Sequence: 1,
                            }
                        ];
                        categories.unshift(notesCategory);
                    }
                }
                // Set the categorys' Sequence to sequential order
                categories.forEach((cat, catIndex) => {
                    cat.Sequence = catIndex + 1;
                    cat.DocketItems.forEach((item, itemIndex) => {
                        if (item.IsActive) {
                            item.Sequence = itemIndex + 1;
                        } else {
                            item.Sequence = null;
                        }
                    });
                });
                this.setState({
                    categories: categories
                });
            });
    }

    handleSequenceChange(categories) {
        this.setState({
            categories: categories,
            isDirty: true
        });
    }

    handleInputChange(index, value, key) {
        let data = { ...this.state.data };
        data.Schedules[index][key] = value;
        this.setState({
            data: data,
            isDirty: true
        }, () => {
            if (key === 'Comments') { this.handleCommentHeight(index); }
        });
    }

    convertTime24to12(time24Hr) {
        if (!time24Hr) return null;
        let [hours, minutes] = time24Hr.split(':');
        let modifier = 'AM';

        if (hours === '00') {
            hours = '12';
        } else if (parseInt(hours, 10) >= 12) {
            hours = hours === '12' ? hours : parseInt(hours, 10) - 12;
            modifier = 'PM';
        }

        return `${parseInt(hours)}:${minutes} ${modifier}`;
    }

    handleMeetingRoomChange = (index, value) => {
        let data = { ...this.state.data };
        data.Schedules[index].VoteRoomID = value.VoteRoomID;
        data.Schedules[index].RoomDescription = value.Description;
        this.setState({
            selectedMeetingRoom: value,
            isDirty: true
        });
    }

    updateDocket(categories) {
        this.setState({
            categories: categories,
            pendingSave: true,
            isDirty: true
        });
    }

    toggleAllActive(active) {
        let checked = false;
        if (active) {
            checked = !this.state.selectAllActive
            this.setState({
                selectAllActive: checked
            });
        } else {
            checked = !this.state.selectAllInactive
            this.setState({
                selectAllInactive: checked
            });
        }
        let categories = [...this.state.categories];
        categories.forEach(category => {
            let items = category.DocketItems;
            items.forEach(bill => {
                if (bill.IsActive === active || (active === false && !bill.IsActive)) {
                    bill.checked = checked
                }
            });
        });
        this.updateDocket(categories);
    }

    saveDocket(publishFlag) {
        let data = { ...this.state.data };
        let unpublishing = false;
        if (publishFlag === PUBLISH_DOCKET) {
            //This function was initiated by the publish button so go ahead and publish the docket
            data.IsPublic = PUBLISH_DOCKET;
            // Set the schedule to public as well
            data.Schedules.forEach(schedule => schedule.IsPublic = PUBLISH_DOCKET);
        } else if (this.state.data.IsPublic) {
            //The docket is already published, unpublish the docket
            data.IsPublic = UNPUBLISH_DOCKET;
            unpublishing = true;
            //Unpublish the schedule as well
            data.Schedules.forEach(schedule => schedule.IsPublic = UNPUBLISH_DOCKET);
        } else {
            //This function was initiated by the save button so do not publish the docket
            data.IsPublic = SAVE_DOCKET;
        }

        //These parts are not needed when saving and will cause the save to fail
        delete data.Staff;
        delete data.CommitteeMember;
        delete data.DocketFiles

        let categories = [...this.state.categories];
        categories.forEach(category => {
            category.hasNotes = false;
            let items = category.DocketItems;
            items.forEach((item, index) => {
                // set IsActive to false instead of just 'null'
                if (!item.IsActive) {
                    item.IsActive = false;
                } else {
                    //Fix the Sequence attribute to match what is displayed on the screen
                    item.Sequence = index + 1;
                }
                //Only add the notes category to the save if it has any content
                if (!category.IsLegislationCategory) {
                    if (item.Description.replace(/\s/g, '')) {
                        category.hasNotes = true;
                    }
                }
            });
        });
        //Filter out the notes categories that do not have any notes
        categories = categories.filter(category => {
            if (category.IsLegislationCategory || category.hasNotes) {
                return category;
            }
        });
        data.DocketCategories = categories;
        //Disable the save button and the form while it is saving
        this.setState({ isSaving: true }, () => {
            this.props.actions.saveDocket({ Dockets: [data] })
                .then(() => {
                    if (this.state.showPreview || data.IsPublic) {
                        let content = this.componentRef.innerHTML.replace(/\\"/g, '');
                        this.props.actions.sendDocketHtml({ CommitteeNumber: this.props.committee.committeeList.find(x => x.CommitteeID === data.CommitteeID).CommitteeNumber, DocketDate: data.DocketDate, DocketNumber: data.DocketNumber, ContentHTML: content });
                    }
                    if (publishFlag) {
                        this.setState({
                            isDirty: false
                        }, () => {
                            this.props.history.push('/docket-management');
                            this.props.actions.makeToast([{ message: "Publish Success", type: "success" }])
                        })
                    } else {
                        const docketSave = this.props.calendar.docketSave;
                        //Add back a blank notes category if it is missing
                        let notesCategories = docketSave.DocketCategories.filter(category => !category.IsLegislationCategory);
                        if (notesCategories.length === 0) {
                            // Using the Senate Docket Notes category for notes
                            let notesCategory = this.props.calendar.calendarCategoriesReferences.find(calCategory => calCategory.CategoryCode === "CSNOT");
                            if (notesCategory) {
                                notesCategory.Sequence = 1;
                                notesCategory.DocketItems = [
                                    {
                                        Description: '',
                                        Sequence: 1,
                                    }
                                ];
                                docketSave.DocketCategories.unshift(notesCategory);
                            }
                        }
                        this.setState({
                            data: docketSave,
                            categories: docketSave.DocketCategories,
                            pendingSave: false,
                            isSaving: false,
                            isDirty: false
                        }, () => {
                            if (unpublishing) {
                                this.props.actions.makeToast([{ message: "Unpublish Success", type: "success" }])
                            } else {
                                this.props.actions.makeToast([{ message: "Save Success", type: "success" }])
                            }
                            this.state.data.Schedules.forEach((schedule, i) => {
                                this.handleCommentHeight(i);
                            })
                        });
                    }
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({ isSaving: false });
                    this.props.actions.makeToast([{ message: (this.props.calendar.docketSaveError.includes("Meeting Started") ? "Cannot Unpublish Docket After Meeting Has Started" : "Save Failed"), type: "failure" }])
                    console.log(err)
                });
        });

    }

    toggleDocketSection(section) {
        let data = { ...this.state.data };

        this.setState(state => ({
            [section]: !state[section]
        }), () => {
            //If the section has already been toggled then just modify the column
            //Otherwise insert the column and set it to what the column was just toggled to
            switch (section) {
                case ("hideStaff"):
                    if (data.CalendarDisplay.find(x => x.DisplayColumn === "Staffs Name")) {
                        data.CalendarDisplay.find(x => x.DisplayColumn === "Staffs Name").IsDisplayed = !this.state[section];
                    } else {
                        data.CalendarDisplay.push({
                            DisplayColumn: "Staffs Name",
                            IsDisplayed: !this.state[section]
                        });
                    }
                    break;
                case ("hideClerk"):
                    if (data.CalendarDisplay.find(x => x.DisplayColumn === "Clerk Name")) {
                        data.CalendarDisplay.find(x => x.DisplayColumn === "Clerk Name").IsDisplayed = !this.state[section];
                    } else {
                        data.CalendarDisplay.push({
                            DisplayColumn: "Clerk Name",
                            IsDisplayed: !this.state[section]
                        });
                    }
                    break;
                case ("hidePatron"):
                    if (data.CalendarDisplay.find(x => x.DisplayColumn === "Chief Patron")) {
                        data.CalendarDisplay.find(x => x.DisplayColumn === "Chief Patron").IsDisplayed = !this.state[section];
                    } else {
                        data.CalendarDisplay.push({
                            DisplayColumn: "Chief Patron",
                            IsDisplayed: !this.state[section]
                        });
                    }
                    break;
                case ("hideSummary"):
                    if (data.CalendarDisplay.find(x => x.DisplayColumn === "Summary")) {
                        data.CalendarDisplay.find(x => x.DisplayColumn === "Summary").IsDisplayed = !this.state[section];
                    } else {
                        data.CalendarDisplay.push({
                            DisplayColumn: "Summary",
                            IsDisplayed: !this.state[section]
                        });
                    }
                    break;
                case ("hideTitle"):
                    if (data.CalendarDisplay.find(x => x.DisplayColumn === "Title")) {
                        data.CalendarDisplay.find(x => x.DisplayColumn === "Title").IsDisplayed = !this.state[section];
                    } else {
                        data.CalendarDisplay.push({
                            DisplayColumn: "Title",
                            IsDisplayed: !this.state[section]
                        });
                    }
                    break;
                case ("hideLDNumber"):
                    if (data.CalendarDisplay.find(x => x.DisplayColumn === "LD Number")) {
                        data.CalendarDisplay.find(x => x.DisplayColumn === "LD Number").IsDisplayed = !this.state[section];
                    } else {
                        data.CalendarDisplay.push({
                            DisplayColumn: "LD Number",
                            IsDisplayed: !this.state[section]
                        });
                    }
                    break;
                case ("hideCaption"):
                    if (data.CalendarDisplay.find(x => x.DisplayColumn === "Caption")) {
                        data.CalendarDisplay.find(x => x.DisplayColumn === "Caption").IsDisplayed = !this.state[section];
                    } else {
                        data.CalendarDisplay.push({
                            DisplayColumn: "Caption",
                            IsDisplayed: !this.state[section]
                        });
                    }
                    break;
                default:
                    break;
            }
            this.setState({
                data: data,
                isDirty: true
            })
        });
    }

    downloadWordFile() {
        const html = `<html>
            <head><meta charset="UTF-8"></head>
            <body>${this.componentRef.innerHTML}</body>
        </html>`;
        const filename = `${this.state.data.CommitteeName} Docket ${this.state.data.DocketDate}.doc`;

        let downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', 'data:application/octet-stream;charset=utf-8,' + encodeURIComponent(html));
        downloadLink.setAttribute('download', filename);

        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
    }

    togglePatronList() {
        //Have to pull the list fresh every time we view the patron list in case there was a save
        if (this.state.showPatronList === false) {
            this.props.actions.getPatronListDocketById("?calendarID=" + this.props.match.params.docketid)
                .then(() => {
                    this.setState({
                        showPatronList: true
                    })
                })
        } else {
            this.setState({
                showPatronList: false
            })
        }
    }

    getBill(legislationID, legislationKey) {
        this.props.actions.getBill(legislationID)
            .then(() => {
                let categories = [...this.state.categories];
                let bill = {
                    LegislationDescription: this.props.bills.bill.Description,
                    LegislationID: this.props.bills.bill.LegislationID,
                    LegislationKey: legislationKey,
                    LegislationNumber: this.props.bills.bill.LegislationNumber,
                    Patrons: this.props.bills.bill.Patrons,
                    Summary: this.props.bills.bill.SummaryVersion,
                    IsActive: true
                };

                //If we don't already have the senate general category in this docket then add it and push the bill to it. 
                //Otherwise push the new bill to the category.
                if (!categories.find(x => x.CategoryCode === "CSGEN")) {
                    let category = this.props.calendar.calendarCategoriesReferences.find(x => x.CategoryCode === "CSGEN");
                    //Set new category's Sequence to be the current max sequence value +1
                    category.Sequence = Math.max.apply(Math, categories.map((c) => { return c.Sequence; })) + 1;
                    bill.Sequence = 1;
                    category.DocketItems = [bill];
                    categories.push(category);
                } else {
                    let index = categories.findIndex(x => x.CategoryCode === "CSGEN");
                    //insert the newly included bill alphabetically
                    const nextHighestActiveLegislationNumber = categories[index].DocketItems.filter(i => i.IsActive && i.Sequence).find(i => i.LegislationNumber.substr(0, 2).localeCompare(bill.LegislationNumber.substr(0, 2)) === 1 || (i.LegislationNumber.substr(0, 2).localeCompare(bill.LegislationNumber.substr(0, 2)) === 0 && i.LegislationKey > bill.LegislationKey));
                    if (nextHighestActiveLegislationNumber) {
                        const sequence = nextHighestActiveLegislationNumber.Sequence
                        categories[index].DocketItems.filter(i => i.IsActive && i.Sequence >= nextHighestActiveLegislationNumber.Sequence).forEach(i => i.Sequence++)
                        bill.Sequence = sequence;
                    } else {
                        const currentSequences = categories[index].DocketItems.filter(i => i.Sequence).map(i => { return i.Sequence; });
                        if (currentSequences.length) {
                            bill.Sequence = (Math.max.apply(Math, currentSequences) + 1) || 1;
                        } else {
                            bill.Sequence = 1;
                        }
                    }
                    categories[index].DocketItems.push(bill);
                    categories[index].DocketItems.sort((a, b) => (a.Sequence != null ? a.Sequence : Infinity) - (b.Sequence != null ? b.Sequence : Infinity))
                }

                this.setState({
                    categories: categories
                })
            })
    }

    handleCommentHeight(index) {
        let data = { ...this.state.data };
        if (data.Schedules[index] && data.Schedules[index].Comments) {
            const height = Math.round(data.Schedules[index].Comments.length / 120) * 20 + 40;
            data.Schedules[index].commentHeight = height.toString() + 'px';
            this.setState({
                data
            })
        }
    }

    render() {
        const { committeeName, committeeDate, message, data } = this.state;
        if (message) {
            return (
                <div className="dlas-forms">
                    <span>{message}</span>
                </div>
            );
        } else {
            if (this.state.showPreview) {
                return (
                    <div>
                        <div>
                            <Prompt
                                when={this.state.isDirty}
                                message={`You have unsaved changes. Are you sure you would like to leave?`}
                            />
                        </div>
                        <div className="flex-row">
                            <div className="advanced-search dlas-forms">
                                <h2>Docket Preview</h2>
                                <div className="checkbox-container">
                                    <div className="inner-grid two">
                                        <div className="checkbox">
                                            <label><input type="checkbox" onChange={() => this.toggleDocketSection('hideStaff')} checked={!this.state.hideStaff} /> Staff name</label>
                                        </div>
                                        <div className="checkbox">
                                            <label><input type="checkbox" onChange={() => this.toggleDocketSection('hideClerk')} checked={!this.state.hideClerk} /> Clerk name</label>
                                        </div>
                                    </div>
                                    <hr className="faded-line" />
                                    <div className="inner-grid two">
                                        <div className="checkbox">
                                            <label><input type="checkbox" onChange={() => this.toggleDocketSection('hideTitle')} checked={!this.state.hideTitle} /> Title</label>
                                        </div>
                                        <div className="checkbox">
                                            <label><input type="checkbox" onChange={() => this.toggleDocketSection('hideSummary')} checked={!this.state.hideSummary} /> Summary</label>
                                        </div>
                                    </div>
                                    <hr className="faded-line" />
                                    <div className="inner-grid two">
                                        <div className="checkbox">
                                            <label><input type="checkbox" onChange={() => this.toggleDocketSection('hidePatron')} checked={!this.state.hidePatron} /> Chief Patron</label>
                                        </div>
                                        <div className="checkbox">
                                            <label><input type="checkbox" onChange={() => this.toggleDocketSection('hideLDNumber')} checked={!this.state.hideLDNumber} /> LD Number</label>
                                        </div>
                                    </div>
                                    <hr className="faded-line" />
                                    <div className="inner-grid two">
                                        <div className="checkbox">
                                            <label><input type="checkbox" onChange={() => this.toggleDocketSection('hideCaption')} checked={!this.state.hideCaption} /> Caption</label>
                                        </div>
                                        <div />
                                    </div>
                                </div>
                            </div>
                            <div className="inline-list">
                                <button type="button" onClick={this.downloadWordFile} className="button-link with-icon"><span className="icon word"></span> View in Word</button>
                                <ReactToPrint
                                    trigger={() => <button type="button" className="button-link with-icon"><span className="icon print"></span> Print</button>}
                                    content={() => this.componentRef}
                                    pageStyle={"break-inside: avoid"}
                                />
                            </div>
                        </div>
                        <div ref={el => (this.componentRef = el)}>
                            <DocketDetailsComponent
                                data={this.state.data}
                                dockets={this.state.categories}
                                session={this.state.selectedSession}
                                selectedMeetingRoom={this.state.selectedMeetingRoom}
                                toggleDocketSection={this.toggleDocketSection}
                                hideClerk={this.state.hideClerk}
                                hideStaff={this.state.hideStaff}
                                hidePatron={this.state.hidePatron}
                                hideSummary={this.state.hideSummary}
                                hideTitle={this.state.hideTitle}
                                hideLDNumber={this.state.hideLDNumber}
                                hideCaption={this.state.hideCaption}
                            />
                        </div>
                        <div className="button-bar">
                            <div></div>
                            <div className="align-right">
                                <button onClick={this.togglePreview} className="button secondary">Edit</button>
                                <button onClick={() => this.saveDocket(PUBLISH_DOCKET)} type="button" disabled={this.state.isSaving || this.state.disablePublish} className="button">Publish</button>
                            </div>
                        </div>
                    </div>
                );
            } else if (this.state.showPatronList) {
                return (
                    <DocketPatronList
                        patronListDocketData={this.props.calendar.patronListDocketByIdData}
                        togglePatronList={this.togglePatronList}
                    />
                )
            } else {
                const convertTime12to24 = (time12h) => {
                    if (!time12h) return '';
                    const [time, modifier] = time12h.split(' ');

                    let [hours, minutes] = time.split(':');

                    if (hours === '12') {
                        hours = '00';
                    }

                    if (modifier === 'PM') {
                        hours = parseInt(hours, 10) + 12;
                    } else {
                        hours = hours.padStart(2, '0');
                    }

                    return `${hours}:${minutes}`;
                }
                return (
                    <React.Fragment>
                        <div>
                            <Prompt
                                when={this.state.isDirty}
                                message={`You have unsaved changes. Are you sure you would like to leave?`}
                            />
                        </div>
                        <h1>{committeeName} {committeeDate}</h1>
                        <div className="user-forms" style={{ paddingBottom: "45px" }}>
                            {!this.state.isLoaded &&
                                <div className="center-spinner spinner">Loading results...</div>
                            }
                            {this.state.isLoaded &&
                                <div className="dlas-forms">
                                    <span className="error-message">{message}</span>
                                    <form>
                                        <fieldset className="fieldset-collapse fieldset-open">
                                            <legend>Docket Information for <strong>{data.CommitteeName}</strong> on <strong>{moment(data.DocketDate).format('MM/DD/YYYY')}</strong></legend>
                                            <div className="multi-row eight-and-one no-margin">
                                                <div>
                                                    <div className="multi-row third no-margin">
                                                        <div>
                                                            <Fields.TextInput
                                                                id="leadership"
                                                                label="Committee Leadership"
                                                                type="text"
                                                                placeholder="Committee Leadership"
                                                                value={this.state.committeeLeadership}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div>
                                                            <Fields.TextInput
                                                                id="clerk"
                                                                label="Committee Clerk"
                                                                type="text"
                                                                placeholder="Committee Clerk"
                                                                value={this.state.committeeClerk.map(clerk => clerk.FullName).join(', ')}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div>
                                                            <Fields.TextInput
                                                                id="staff"
                                                                label="Committee Staff"
                                                                type="text"
                                                                placeholder="Committee Staff"
                                                                value={this.state.committeeStaff.map(staff => staff.FullName).join(', ')}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    {data.Schedules.map((schedule, index) =>
                                                        <React.Fragment key={index}>
                                                            <div className="multi-row one-and-three no-margin" style={{ margin: 'initial', width: '60%' }}>
                                                                <div>
                                                                    <label for="meetingTime">Meeting Time</label>
                                                                    {TIME_REGEX.test(schedule.ScheduleTime) && <div className='icon clock' title="Correct Time Format"/>}
                                                                    <div>
                                                                        <input
                                                                            id="meetingTime"
                                                                            value={schedule.ScheduleTime}
                                                                            style={{ maxHeight: '32px', fontFamily: 'arial' }}
                                                                            onChange={(e) => this.handleInputChange(index, e ? e.target.value : e, 'ScheduleTime')}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <label>Meeting Room</label>
                                                                    <Fields.Dropdown
                                                                        value={this.state.meetingRoomsList.find(room => room.VoteRoomID === schedule.VoteRoomID)}
                                                                        placeholder="Meeting Room"
                                                                        options={this.state.meetingRoomsList}
                                                                        id="meetingRoomsDropdown"
                                                                        onChange={(value) => this.handleMeetingRoomChange(index, value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="single-row no-margin">
                                                                <div>
                                                                    <Fields.TextArea
                                                                        id="comments"
                                                                        label="Comments"
                                                                        type="text"
                                                                        placeholder="Comments"
                                                                        value={schedule.Comments}
                                                                        style={{ height: schedule.commentHeight || '40px' }}
                                                                        onChange={(e) => this.handleInputChange(index, e.target.value, 'Comments')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )}

                                                </div>
                                                <div>
                                                    {this.state.data.IsPublic && <button type="button" onClick={this.downloadWordFile} className="button-link with-icon" style={{ marginLeft: "15px" }}><span className="icon word"></span> View in Word</button>}
                                                    <div className="published-status">
                                                        {this.state.data.IsPublic ? <span>Published</span> : <span className="dirty">Not Published</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <CommitteeBillsGrid
                                                categories={this.state.categories}
                                                updateDocket={this.updateDocket}
                                                handleSequenceChange={this.handleSequenceChange}
                                                toggleAllActive={this.toggleAllActive}
                                                chamberCode={this.state.data.ChamberCode}
                                                sessionCode={this.state.data.SessionCode}
                                                sessionBills={this.state.sessionBills}
                                                getBill={this.getBill}
                                            />
                                        </fieldset>
                                        <div className="button-bar floating-button-bar" style={{ paddingBottom: "15px", paddingRight: "15px" }}>
                                            <button onClick={this.togglePatronList} className="button" type="button" style={{ width: "150px" }}>Show Patron List</button>
                                            <div className="align-right">
                                                <button disabled={this.state.isSaving} type="button" onClick={() => this.saveDocket(SAVE_DOCKET)} className="button">{this.state.isSaving ? "Saving..." : this.state.data.IsPublic ? "Unpublish" : "Save"}</button>
                                                <button disabled={this.state.pendingSave} onClick={this.togglePreview} className="button" type="button">Preview</button>
                                            </div>
                                        </div>
                                    </form>
                                    {this.state.data.IsPublic &&
                                        <div ref={el => (this.componentRef = el)} style={{ display: "none" }}>
                                            <DocketDetailsComponent
                                                data={this.state.data}
                                                dockets={this.state.categories}
                                                session={this.state.selectedSession}
                                                selectedMeetingRoom={this.state.selectedMeetingRoom}
                                                toggleDocketSection={this.toggleDocketSection}
                                                hideClerk={this.state.hideClerk}
                                                hideStaff={this.state.hideStaff}
                                                hidePatron={this.state.hidePatron}
                                                hideSummary={this.state.hideSummary}
                                                hideTitle={this.state.hideTitle}
                                                hideLDNumber={this.state.hideLDNumber}
                                                hideCaption={this.state.hideCaption}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </React.Fragment>
                );
            }
        }
    }
};

const DocketManagementForm = connect(
    (state) => {
        const { committee, calendar, session, nav, schedule, bills } = state;
        return {
            committee,
            calendar,
            session,
            nav,
            schedule,
            bills
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, billActionCreators, calendarActionCreators, committeeActionCreators, sessionActionCreators, navActionCreators, scheduleActionCreators), dispatch)
        }
    }
)(DocketFormComponent)

export default DocketManagementForm;








