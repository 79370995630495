import React from 'react';
import moment from 'moment-timezone'
import ReactToPrint from 'react-to-print';

class PrintRoomGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showRow: [],
            printPreviewIndex: undefined
        }
        this.toggleRow = this.toggleRow.bind(this);
        this.togglePrintView = this.togglePrintView.bind(this);

        this.componentRef = React.createRef();
    }

    togglePrintView(value) {
        this.setState({
            printPreviewIndex: value
        })
    }

    toggleRow(batchIndex) {
        let showRow = [...this.state.showRow];
        showRow[batchIndex] = !showRow[batchIndex];
        this.setState({
            showRow: showRow
        });
    }

    componentDidMount() {
        if (this.props.urlBatchId !== "" && this.props.textResults.length > 0 && this.state.urlBatchIndex === "") {
            let index = this.props.textResults.findIndex(x => x.PrintBatchID == this.props.urlBatchId);
            this.toggleRow(index)
        }
    }

    render() {
        const { showRow } = this.state;
        const findMember = memberId => {
            const patron = this.props.members.find(member => member.MemberID === memberId);
            if (patron) {
                return patron.PatronDisplayName;
            } else {
                return '';
            }
        }
        const headerStyle = {
            color: 'white',
            backgroundColor: '#9e9e9e',
            padding: '10px',
            border: '1px solid #e0e0e0'
        }

        const headerStyleComplete = {
            color: 'white',
            backgroundColor: '#dc3545',
            padding: '10px',
            border: '1px solid #e0e0e0'
        }

        const filterTextResults = (textResults) => {
            let filteredTextResults = [...textResults];

            filteredTextResults = filteredTextResults.filter(x =>
                this.props.showComplete
                    ? true
                    : !this.props.viewOnlyCompletedItems
                        ? x.BatchItems.findIndex(y => y.PrintDate === undefined) > -1
                            ? true
                            : false
                        : x.BatchItems.findIndex(y => y.PrintDate !== undefined) > -1
                            ? true
                            : false
            )

            filteredTextResults = filteredTextResults.filter(batch => batch.BatchItems.every(text => {
                if (this.props.introStartDate && this.props.introEndDate) {
                    let formattedStartDate = this.props.introStartDate.clone().hour(0);
                    let formattedEndDate = this.props.introEndDate.clone().endOf('day');

                    return moment(text.VersionDate).isBetween(formattedStartDate, formattedEndDate);
                } else if (this.props.introStartDate) {
                    let formattedStartDate = this.props.introStartDate.clone().hour(0);

                    return moment(text.VersionDate).isSameOrAfter(formattedStartDate);
                } else if (this.props.introEndDate) {
                    let formattedEndDate = this.props.introEndDate.clone().endOf('day');

                    return moment(text.VersionDate).isSameOrBefore(formattedEndDate);
                } else {
                    return true;
                }
            }));

            return filteredTextResults ?? [];
        }

        const flattenBatches = (textResults) => {
            let flatBillList = []

            textResults.forEach(batch => {
                batch.BatchItems.forEach(item => {
                    if (item.PrintDate) {
                        flatBillList.push({ documentCode: item.DocumentCode, printDate: item.PrintDate })
                    }
                })
            });

            return flatBillList.sort((a, b) => { return a.documentCode.localeCompare(b.documentCode) })
        }

        return this.props.viewCompletedByBill ?
            <div className='view-by-completed-bills'>
                {flattenBatches(this.props.textResults).map(x => {
                    return <p>{x.documentCode + " - " + moment(x.printDate).format("M/D/YYYY h:mm:ss")}</p>
                })}
            </div>
            : !this.props.viewCompletedByBill
                ? <div style={{ marginBottom: "35px" }}>
                    {this.state.printPreviewIndex &&
                        <div className="schedule-modal">
                            <div className="schedule-modal-content fit-content center-content" style={{ maxHeight: "fit-content", marginTop: "50px" }}>
                                <ReactToPrint
                                    trigger={() => <button style={{ margin: "0 0 0 10px", float: "right" }} type="button" className="button primary">Print</button>}
                                    content={() => this.componentRef}
                                />
                                <button style={{ float: "right" }} type="button" className="button secondary" onClick={() => this.togglePrintView(undefined)}>Close</button>
                                {/* All styling is being applied inline due to issues with the bill room's computer (for whatever reason) not loading the styling into the browser print preview window. */}
                                <div
                                    ref={el => (this.componentRef = el)}
                                    style={{
                                        padding: "20px 50px",
                                        lineHeight: "2.5em",
                                        fontSize: "16px"
                                    }}
                                >
                                    <h2 style={{ fontFamily: '"Lato", sans-serif', fontWeight: '400', color: '#34495e' }}>
                                        {moment(this.props.textResults[this.state.printPreviewIndex].BatchDate).format("M/D/YYYY h:mm:ss A")
                                            + " "
                                            + "(BATCH " + this.props.textResults[this.state.printPreviewIndex].BatchSequence + ")"
                                            + " "
                                            + (this.props.textResults[this.state.printPreviewIndex].BatchDescription ? this.props.textResults[this.state.printPreviewIndex].BatchDescription : "")
                                            + " "
                                            + (this.props.textResults[this.state.printPreviewIndex].VendorBatch ? "Vendor" : "Bill Room")
                                            + " "
                                            + "Total Pages: " + this.props.textResults[this.state.printPreviewIndex].totalPageCount
                                        }</h2>
                                    <div
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns: "100px 75px 100px 100px 1fr",
                                            gridGap: "10px",
                                            textAlign: "left",
                                            overflow: "hidden"
                                        }}
                                    >
                                        <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                            Doc Code
                                        </div>
                                        <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                            Pages
                                        </div>
                                        <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                            Version Date
                                        </div>
                                        <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                            Complete?
                                        </div>
                                        <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                            Comment
                                        </div>
                                    </div>
                                    {this.props.textResults[this.state.printPreviewIndex].BatchItems.map(item => {
                                        return (
                                            <div
                                                style={{
                                                    display: "grid",
                                                    gridTemplateColumns: "100px 75px 100px 100px 1fr",
                                                    gridGap: "10px",
                                                    textAlign: "left",
                                                    overflow: "hidden"
                                                }}
                                            >
                                                <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                    {item.DocumentCode}
                                                </div>
                                                <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                    {item.PDFFile[0].PageCount}
                                                </div>
                                                <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                    {moment(item.VersionDate).format("MM/DD/YYYY")}
                                                </div>
                                                <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                    {item.PrintDate ? "Complete" : "Incomplete"}
                                                </div>
                                                <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                    {item.Comment}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <ReactToPrint
                                    trigger={() => <button style={{ margin: "-20px 0 0 10px", float: "right" }} type="button" className="button primary">Print</button>}
                                    content={() => this.componentRef}
                                />
                                <button style={{ marginTop: "-20px", float: "right" }} type="button" className="button secondary" onClick={() => this.togglePrintView(undefined)}>Close</button>
                            </div>
                        </div>
                    }
                    {filterTextResults(this.props.textResults).sort((a, b) => {
                        return moment(a.BatchDate).isBefore(moment(b.BatchDate)) ? 1 : -1
                    }).map((batch, batchIndex) => {
                        let batchDate = moment.utc(batch.BatchDate).utcOffset(-300).format('M/D/YYYY h:mm:ss A');

                        return <div key={batchIndex}>
                            {batch.BatchItems.length > 0 &&
                                <React.Fragment>
                                    <div className="flex-row" style={!batch.BatchItems.find(x => !x.pdfMarked) ? headerStyleComplete : headerStyle}>
                                        <div>
                                            <button
                                                className={showRow[batchIndex] ? "arrow-up" : "arrow-down"}
                                                onClick={() => this.toggleRow(batchIndex)}>
                                            </button>
                                            <span>{batchDate}</span>
                                            <span style={{ marginLeft: '5px', fontWeight: "bold" }}>({"BATCH " + batch.BatchSequence})</span>
                                            {batch.BatchDescription &&
                                                <span> - {batch.BatchDescription}</span>
                                            }
                                            {batch.VendorBatch
                                                ? <span> Vendor</span>
                                                : <span> Bill Room</span>
                                            }
                                            <a type="button" className="button print white" onClick={() => {
                                                let batchIndex = this.props.textResults.findIndex(x => x.PrintBatchID === batch.PrintBatchID);
                                                this.togglePrintView(batchIndex);
                                                this.toggleRow(batchIndex);
                                            }}>Open Print View</a>
                                        </div>
                                        <div>
                                            <span>Total pages: {batch.totalPageCount}</span>
                                        </div>
                                    </div>
                                    {showRow[batchIndex] &&
                                        <form>
                                            <div className="bill-grid print-bill-grid print-room-bill-grid">
                                                <div className={!batch.BatchItems.find(x => !x.pdfMarked) ? "bill-grid-header completed" : "bill-grid-header"}>
                                                    <input
                                                        type="checkbox"
                                                        checked={batch.selectAll || ''}
                                                        onChange={() => {
                                                            let batchIndex = this.props.textResults.findIndex(x => x.PrintBatchID === batch.PrintBatchID);
                                                            this.props.toggleSelectAll(batchIndex);
                                                        }} />
                                                </div>
                                                <div className={!batch.BatchItems.find(x => !x.pdfMarked) ? "bill-grid-header completed" : "bill-grid-header"}>
                                                    <span>Bill #</span>
                                                </div>
                                                <div className={!batch.BatchItems.find(x => !x.pdfMarked) ? "bill-grid-header completed" : "bill-grid-header"}>
                                                    <span>Document</span>
                                                </div>
                                                <div className={!batch.BatchItems.find(x => !x.pdfMarked) ? "bill-grid-header completed" : "bill-grid-header"}>
                                                    <span>Pages</span>
                                                </div>
                                                <div className={!batch.BatchItems.find(x => !x.pdfMarked) ? "bill-grid-header completed" : "bill-grid-header"}>
                                                    <span>Date</span>
                                                </div>
                                                <div className={!batch.BatchItems.find(x => !x.pdfMarked) ? "bill-grid-header completed" : "bill-grid-header"}>
                                                    <span>Patron</span>
                                                </div>
                                                <div className={!batch.BatchItems.find(x => !x.pdfMarked) ? "bill-grid-header completed" : "bill-grid-header"}>
                                                    <span>Caption</span>
                                                </div>
                                                <div className={!batch.BatchItems.find(x => !x.pdfMarked) ? "bill-grid-header completed" : "bill-grid-header"}>
                                                    <span>Status</span>
                                                </div>
                                                <div className={!batch.BatchItems.find(x => !x.pdfMarked) ? "bill-grid-header completed" : "bill-grid-header"}>
                                                    <span>Comment</span>
                                                </div>
                                                <div className={!batch.BatchItems.find(x => !x.pdfMarked) ? "bill-grid-header completed" : "bill-grid-header"}>
                                                    <span>View</span>
                                                </div>
                                            </div>
                                            <div>
                                                {batch.BatchItems.filter(x => {
                                                    if (!this.props.viewOnlyCompletedItems) {
                                                        return true
                                                    } else {
                                                        return x.PrintDate ? true : false
                                                    }
                                                }).map((text, textIndex) =>
                                                    <div key={textIndex} className="bill-grid print-bill-grid print-room-bill-grid">
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                checked={text.checked || ''}
                                                                onChange={(e) => {
                                                                    let batchIndex = this.props.textResults.findIndex(x => x.PrintBatchID === batch.PrintBatchID);
                                                                    let textIndex = this.props.textResults[batchIndex].BatchItems.findIndex(x => x.BatchItemID === text.BatchItemID);
                                                                    this.props.toggleText(batchIndex, textIndex, e);
                                                                }} />
                                                        </div>
                                                        <div>
                                                            <span><a
                                                                className={text.pdfMarked && "red"}
                                                                target="_blank"
                                                                href={text.PDFFile[0].FileURL}
                                                                onClick={() => {
                                                                    let batchIndex = this.props.textResults.findIndex(x => x.PrintBatchID === batch.PrintBatchID);
                                                                    let textIndex = this.props.textResults[batchIndex].BatchItems.findIndex(x => x.BatchItemID === text.BatchItemID);
                                                                    this.props.markPdf(batchIndex, textIndex)
                                                                }}>
                                                                {text.LegislationNumber}
                                                            </a></span>
                                                        </div>
                                                        <div>
                                                            {text.IsReprint && <div className="small-text message-error">REPRINT</div>}
                                                            <span>{text.DocumentCode}</span>
                                                        </div>
                                                        <div>
                                                            <span>
                                                                {text.PDFFile && text.PDFFile.length > 0 &&
                                                                    <React.Fragment>{text.PDFFile[0].PageCount}</React.Fragment>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span>{moment(text.VersionDate).format('L')}</span>
                                                        </div>
                                                        <div>
                                                            <span>{findMember(text.MemberID)}</span>
                                                        </div>
                                                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                            <span
                                                                title={text.LegislationDescription || text.Description}
                                                                style={{ whiteSpace: 'nowrap' }}>
                                                                {text.LegislationDescription || text.Description}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span>{text.PrintDate ? "Complete" : "Incomplete"}</span>
                                                        </div>
                                                        <div style={text.Comment && text.Comment.length > 12 ? { overflowX: "scroll" } : {}}>
                                                            <span style={{ whiteSpace: "nowrap" }}>{text.Comment}</span>
                                                        </div>
                                                        <div>
                                                            <div className="inner-grid three" style={{ gridGap: "0px" }}>
                                                                {text.PDFFile && text.PDFFile.length > 0 ?
                                                                    <a target="_blank"
                                                                        href={text.IsPublic ? text.PDFFile[0].FileURL : text.SasURL}
                                                                        onClick={() => {
                                                                            let batchIndex = this.props.textResults.findIndex(x => x.PrintBatchID === batch.PrintBatchID);
                                                                            let textIndex = this.props.textResults[batchIndex].BatchItems.findIndex(x => x.BatchItemID === text.BatchItemID);
                                                                            this.props.markPdf(batchIndex, textIndex)
                                                                        }}>
                                                                        <span className={"icon pdf" + (text.pdfMarked ? " red" : "")}></span>
                                                                    </a>
                                                                    :
                                                                    <br />
                                                                }
                                                                <div>
                                                                    <span
                                                                        onClick={(e) => this.props.togglePortal(this.props.showPortal, e, text.LegislationTextID)}
                                                                        className="icon paper"></span>
                                                                </div>
                                                                <div>
                                                                    {text.IsPublic &&
                                                                        <i className="icon book" />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </form>
                                    }
                                </React.Fragment>
                            }
                        </div>
                    }
                    )}
                </div> : null
    }
}
export default PrintRoomGrid;