const getHtml = (jsonObject) => {
    //Check that the param is an object before beginning
    if (typeof jsonObject !== 'object' || jsonObject === null) {
        console.error("jsonObject param is not an object")
        return null;
    }
    return mapJson(jsonObject);
}


const mapJson = (object) => {
    if (typeof object === "string") {
        return object;
    }

    let headerContent = document.createElement('div');
    headerContent.id = "header-content";

    for (const c of object["Content"]) {
        if (c !== "") {
            if (c["Content"] && c["Content"].length > 1) {
                let content = document.createElement(c["TagName"]);

                for (const nc of c["Content"]) {
                    //If the nested content has its own content then build out an element for it
                    //Otherwise it's a string, so just append it to the parent content                    
                    if (nc["Content"]) {
                        //We have nested content within the nested content
                        if (nc["Content"].length > 1) {
                            let hierarchicalContent = document.createElement(nc["TagName"]);
                            for (const nnc of nc["Content"]) {
                                if (nnc["Content"]) {
                                    let nestedNestedContent = document.createElement(nnc["TagName"]);
                                    nestedNestedContent.appendChild(document.createTextNode(nnc["Content"]));
                                    nestedNestedContent.style.cssText = (nnc["Attributes"] && nnc["Attributes"].Style) ? nnc["Attributes"].Style : "";
                                    if (nnc["Attributes"] && nnc["Attributes"].Class) {
                                        nestedNestedContent.classList = nnc["Attributes"].Class;
                                    }
                                    hierarchicalContent.appendChild(nestedNestedContent);
                                    content.appendChild(hierarchicalContent);
                                } else {
                                    hierarchicalContent.appendChild(document.createTextNode(nnc));
                                    content.appendChild(hierarchicalContent)
                                }
                            }
                        } else {
                            let nestedContent = document.createElement(nc["TagName"]);
                            nestedContent.appendChild(document.createTextNode(nc["Content"]));
                            nestedContent.style.cssText = (nc["Attributes"] && nc["Attributes"].Style) ? nc["Attributes"].Style : "";
                            if (nc["Attributes"] && nc["Attributes"].Class) {
                                nestedContent.classList = nc["Attributes"].Class;
                            }
                            content.appendChild(nestedContent);
                        }
                    } else {
                        content.appendChild(document.createTextNode(nc));
                    }
                }

                content.style.cssText = c["Attributes"].Style ? c["Attributes"].Style : "";
                if (c["Attributes"].Class) {
                    content.classList = c["Attributes"].Class;
                }
                headerContent.appendChild(content)
            } else {
                let content = document.createElement(c["TagName"]);
                content.appendChild(document.createTextNode(c["Content"]));
                content.style.cssText = c["Attributes"].Style ? c["Attributes"].Style : "";
                if (c["Attributes"].Class) {
                    content.classList = c["Attributes"].Class;
                }
                headerContent.appendChild(content)
            }
        }
    }

    return headerContent.outerHTML.toString()
}

export default getHtml;