import React from 'react';
import moment from 'moment-timezone';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { calendarActionCreators } from '../../../stores/lis-calendar-store';
import { cancelRequest } from '../../../services/request.service';
import { navActionCreators } from '../../../stores/lis-nav-store';

import LoaderComponent from '../../../lis-shared/lis-layout/components/lis-loader-component'
import { minutesActionCreators } from '../../../stores/lis-minutes-store';
import { memberActionCreators } from '../../../stores/lis-members-store';

const CURRENTLY_IN = 'Bills in subcommittee <em>today</em>';
const ASSIGNED_TO = 'Bills that have ever been in this subcommittee';

function MinutesData(props) {
    return (
        <tr className="inner-grid one-and-three">
            <td className="no-gap-grid" style={{ gridTemplateColumns: '150px 110px' }}>
                <span>{moment(props.MinutesDate).format("MMMM D[,] YYYY")} {props.MinutesNumber > 1 ? "(" + props.MinutesNumber + ")" : ""}</span>
                <div className="flex-row">
                    {(props.IsPublic || this.props.login.userClaims.scopes.find(x => x === "Senate")) ? <Link to={`/session-details/${props.sessionCode}/committee-information/${props.committeeNumber}/meetings/${props.MinutesBookID}/1`}>
                        Meeting
                    </Link> : <div />}
                    {props.MinutesCalendars && props.MinutesCalendars.length > 0 ?
                        <React.Fragment>{(props.IsPublic || this.props.login.userClaims.scopes.find(x => x === "Senate")) && "|"}
                            <Link to={`/session-details/${props.sessionCode}/committee-information/${props.committeeNumber}/dockets/${props.MinutesCalendars[0].CalendarID}`}>
                                Docket
                            </Link>
                        </React.Fragment> : null}
                </div>
            </td>
        </tr >
    )
}

function MinutesList(props) {
    const listMinutes = props.minutes.map((minute, i) => {
        return (
            <MinutesData key={i} {...minute} sessionCode={props.sessionCode} committeeNumber={props.committeeNumber} />
        )
    });
    return (<React.Fragment>{listMinutes}</React.Fragment>)
}

function DocketData(props) {
    const { docket, formattedSessionCode, committeeNumber } = props;

    if (!docket) {
        return <div />;
    }

    let url = '';
    if (docket.ChamberCode === "H") {
        if (docket.ReferenceNumber) {
            url = `https://virginiageneralassembly.gov/house/agendas/agendaItem.php?id=${docket.ReferenceNumber}&ses=${formattedSessionCode}`
        } else {
            url = `https://virginiageneralassembly.gov/house/agendas/agendaDates.php?id=${committeeNumber}&ses=${formattedSessionCode}`
        }
    } else {
        url = `/session-details/` + docket.SessionCode + `/committee-information/` + committeeNumber + `/dockets/` + docket.DocketID;
    }

    return (
        <tr className="inner-grid one-and-three">
            <td className="no-gap-grid" style={{ gridTemplateColumns: '150px 110px' }}>
                <span>{moment(docket.HouseAgendaDate ? docket.HouseAgendaDate : docket.DocketDate).format("MMMM D[,] YYYY")}</span>
                <Link to={url}>
                    Docket</Link>
            </td>
        </tr>
    )
}

function DocketList(props) {
    let count = 1;
    const listDockets = props.dockets.map((docket, i) => {
        //Proceed with adding the docket if there is not a minutes on the same day as the docket
        //Unless it's a past session in which case show the docket no matter what 
        if ((!props.maxSize || count < props.maxSize) && ((!props.sessionData.IsActive && i !== 0) || (i !== 0 && props.minutes && props.minutes.length > 0 && !props.minutes.find(y => moment(docket.DocketDate).isSame(y.MinutesDate, "days"))))) {
            count++;
            return (
                <DocketData key={i} docket={docket} formattedSessionCode={props.formattedSessionCode} committeeNumber={props.committeeNumber} />
            )
        }
    });
    return (<React.Fragment>{listDockets}</React.Fragment>)
}

function SubCommittees(props) {
    return (
        <li>
            {props.parentCommitteeNumber !== "S05"
                ? <Link to={`/session-details/${props.sessionCode}/committee-information/${props.CommitteeNumber}/committee-details`}>{props.Name}</Link>
                : props.Name === "Capital Outlay & Transportation"
                    ? <a href='https://sfac.virginia.gov/subcommittees/capital-outlay-transport.shtml'>Capital Outlay & Transportation</a>
                    : props.Name === "Education"
                        ? <a href='https://sfac.virginia.gov/subcommittees/education.shtml'>Education</a>
                        : <Link to={`/session-details/${props.sessionCode}/committee-information/${props.CommitteeNumber}/committee-details`}>{props.Name}</Link>
            }
        </li>
    );
}

function SubCommitteesList(props) {
    const listCommittees = props.committees.map((committee, i) => <SubCommittees key={i} sessionCode={props.sessionCode} parentCommitteeNumber={props.parentCommitteeNumber} {...committee} />);
    return listCommittees;
}

class CommitteeDetailsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            committeeData: { Name: '' },
            parentCommitteeData: {},
            memberList: [],
            subcommitteesList: [],
            sessionData: '',
            minutesList: [],
            docketList: [],
            showTooltip: {}
        };
        this.getCommitteeDetails = this.getCommitteeDetails.bind(this);
        this.getCommitteeMembers = this.getCommitteeMembers.bind(this);
        this.getSubcommittees = this.getSubcommittees.bind(this);
        this.getMeetings = this.getMeetings.bind(this);
        this.setupDoc = this.setupDoc.bind(this);
        this.makeLink = this.makeLink.bind(this);
        this.toggleTooltip = this.toggleTooltip.bind(this);
    }

    // When component has mounted:
    // 1. Get session info, add to state
    // 2. Get committee by ID
    // 3. Get committee members
    // 4. Get subcommittees (if any)
    setupDoc(sessionCode) {
        const committeeNumber = this.props.match.params.committeenumber
        this.props.actions.getSessionByCode(sessionCode).then(() => {
            const sessionData = this.props.session.selectedSession;
            this.setState({
                sessionData: sessionData,
                committeeNumber: committeeNumber
            }, () => {
                this.getCommitteeDetails(committeeNumber, sessionData.SessionID);
            });
        });
    }


    componentDidUpdate(prevProps) {
        //If the user clicks a link to a subcommittee then the setup function needs to run again
        if (this.props.match.params.committeenumber !== prevProps.match.params.committeenumber) {
            this.setupDoc(this.props.nav.session);
        }
        // Setup the page if the global selected session changes. Change the URL to reflect the session change
        if (prevProps.nav.session !== this.props.nav.session) {
            this.props.history.push('/session-details/' + this.props.nav.session + '/committee-information/' + this.props.match.params.committeenumber + '/committee-details');
            this.setupDoc(this.props.nav.session);
        } else if (this.props.nav.session !== this.props.match.params.sessioncode) {
            // If the url changes then update the global selected session with whatever the url is
            const sessionCode = this.props.match.params.sessioncode;
            if (!isNaN(sessionCode)) {
                this.props.actions.changeSession(sessionCode);
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const sessionCode = this.props.match.params.sessioncode;
        //Update the session selector component, which will run componentDidUpdate and call setupDoc on its own
        if (this.props.nav.session !== sessionCode) {
            this.props.actions.changeSession(sessionCode)
        } else {
            this.setupDoc(sessionCode);
        }
    }

    componentWillUnmount() {
        cancelRequest();
    }

    getCommitteeDetails(committeeNumber, sessionId) {
        const sessionStartObj = this.props.session.selectedSession.SessionEvents && this.props.session.selectedSession.SessionEvents.length && this.props.session.selectedSession.SessionEvents.find(date => date.DisplayName === "Session Start");
        const startDate = sessionStartObj ? moment(sessionStartObj.ActualDate) : '';

        this.props.actions.getCommitteeByNumber("?committeeNumber=" + committeeNumber + "&sessionId=" + sessionId).then(() => {
            this.setState({
                committeeData: this.props.committee.committeeByNumberData,
                detailsIsLoaded: true
            });

            //Attempt to get any meetings or dockets for the committee
            this.getMeetings(this.state.sessionData, this.props.committee.committeeByNumberData.CommitteeID);
            if (this.props.committee.committeeByNumberData.ChamberCode === "S")
                this.getDockets(this.state.sessionData, this.props.committee.committeeByNumberData);

            if (this.props.committee.committeeByNumberData.ParentCommitteeID) {
                this.props.actions.getCommitteeById("?sessionId=" + sessionId + "&id=" + this.props.committee.committeeByNumberData.ParentCommitteeID + "&effectiveDate=" + moment(this.props.session.selectedSession.StartDate).format("MM/DD/YYYY")).then(() => {
                    this.setState({
                        parentCommitteeData: this.props.committee.committeeByIdData
                    })
                })
            }

            this.getCommitteeMembers(this.props.committee.committeeByNumberData.CommitteeID, sessionId);
            this.getSubcommittees(this.props.committee.committeeByNumberData.CommitteeID, sessionId);
        }
        )
    }


    getCommitteeMembers(committeeID, sessionId) {
        var params = 'sessionID=' + sessionId + '&' + 'CommitteeID=' + committeeID;
        this.props.actions.getMembersByCommittee(params).then(() => {
            // The Chairman needs to be the at the top of the list
            const chairmanIndex = this.props.members.membersByCommittee.findIndex(member => member.CommitteeRoleTitle === 'Chairman');
            let membersByCommittee = [...this.props.members.membersByCommittee];
            if (chairmanIndex !== -1) {
                let chairman = membersByCommittee[chairmanIndex];
                membersByCommittee.splice(chairmanIndex, 1);
                membersByCommittee.unshift(chairman);
            }
            this.setState({
                memberList: membersByCommittee,
                membersIsLoaded: true
            });
        });
    }

    getSubcommittees(parentID, sessionID) {
        var params = 'sessionID=' + sessionID + '&' + 'parentCommitteeID=' + parentID;

        this.props.actions.getCommitteeList(params).then(() =>
            this.setState({
                subcommitteesList: this.props.committee.committeeList,
                subcommitteesIsLoaded: true
            })
        );
    }

    getMeetings(sessionData, committeeId) {
        const meetingParam = "sessionID=" + sessionData.SessionID + "&committeeID=" + committeeId;
        this.props.actions.getMinutesList(meetingParam, false)
            .then(() => {
                let minutesList = [...this.props.minutes.minutesList];
                this.setState({
                    minutesList: minutesList,
                    minutesListIsLoaded: true
                });
            });
    }

    getDockets(sessionData, committeeData) {
        const docketParams = "?sessionID=" + sessionData.SessionID + "&chamberCode=" + committeeData.ChamberCode + "&committeeID=" + committeeData.CommitteeID;
        this.props.actions.getDocketList(docketParams, false)
            .then(() => {
                this.setState({
                    docketList: this.props.calendar.docketList,
                    docketListIsLoaded: true
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err);
                this.setState({
                    docketListIsLoaded: true
                });
            });
    }

    makeLink(statusCategoryId, currentStatus) {
        let params = {
            selectedSession: this.state.sessionData ? this.state.sessionData.SessionID : '',
            selectedBillStatusCategory: statusCategoryId,
            selectedCommittee: this.state.committeeData.ParentCommitteeID || this.state.committeeData.CommitteeID,
            selectedSubcommittee: this.state.committeeData.ParentCommitteeID ? this.state.committeeData.CommitteeID : '',
            currentStatus: currentStatus ?? false
        };

        const json = JSON.stringify(params);
        const query = window.btoa(json);
        return '/bill-search?q=' + query;
    }

    toggleTooltip(text) {
        let showTooltip = { ...this.state.showTooltip };

        //Close all open tooltips
        if (typeof showTooltip === "object") {
            for (const x in showTooltip) {
                if (x !== text)
                    showTooltip[x] = false;
            };
        }

        showTooltip[text] = showTooltip[text] ? false : true;

        this.setState({
            showTooltip: showTooltip
        })
    }

    render() {
        const { sessionData, committeeData, parentCommitteeData, memberList, subcommitteesList, minutesList, docketList } = this.state;
        const formattedSessionCode = sessionData.SessionCode ? sessionData.SessionCode.substr(2) : '';
        const sessionStartObj = sessionData && sessionData.SessionEvents && sessionData.SessionEvents.length ? sessionData.SessionEvents.find(date => date.DisplayName === "Session Start") : '';
        const startDate = sessionStartObj ? moment(sessionStartObj.ActualDate) : moment();
        const closingEvent = sessionData && sessionData.SessionEvents && sessionData.SessionEvents.length ? sessionData.SessionEvents.find(event => event.DisplayName === 'Closing') : '';
        const reconveneEvent = !closingEvent && sessionData && sessionData.SessionEvents && sessionData.SessionEvents.length ? sessionData.SessionEvents.find(event => event.DisplayName === 'Reconvene') : '';
        const adjournmentEvent = !closingEvent && !reconveneEvent && sessionData && sessionData.SessionEvents && sessionData.SessionEvents.length ? sessionData.SessionEvents.find(event => event.DisplayName === 'Adjournment') : '';
        const endDate = closingEvent ? moment(closingEvent.ActualDate) : reconveneEvent ? moment(reconveneEvent.ActualDate) : adjournmentEvent ? moment(adjournmentEvent.ActualDate) : moment();
        //Find the first docket that doesn't have a minutes on the same day
        const defaultDocket = docketList.find(x => !minutesList.find(y => moment(x.DocketDate).isSame(y.MinutesDate, "days")));

        const committeeLabel = (!committeeData.ParentCommitteeID ? committeeData.ChamberCode === "H" ? "House " : "Senate " : "Subcommittee: ") + committeeData.Name

        const buildMembers = (memberList) => {
            let members = [];
            let changedMembers = [];
            for (let i = 0; i < memberList.length; i++) {
                if (memberList[i].RemoveDate && moment(memberList[i].RemoveDate).isBefore(endDate)) {
                    memberList[i].Change = "Left"
                    memberList[i].ChangeDate = memberList[i].RemoveDate;
                    changedMembers.push(memberList[i])
                } else if (memberList[i].AssignDate && moment(memberList[i].AssignDate).isAfter(startDate) && moment(memberList[i].AssignDate).isBefore(endDate)) {
                    members.push(memberList[i])
                    memberList[i].Change = "Joined"
                    memberList[i].ChangeDate = memberList[i].AssignDate
                    changedMembers.push(memberList[i])
                } else {
                    members.push(memberList[i])
                }
            }

            changedMembers.sort((a, b) => (a.ChangeDate > b.ChangeDate) ? 1 : ((b.ChangeDate > a.ChangeDate) ? -1 : 0))

            return <React.Fragment>
                <p>
                    {members.map((member, memberIndex) =>
                        <React.Fragment key={memberIndex}>
                            <Link style={{ display: 'inline' }} to={`/session-details/${sessionData.SessionCode}/member-information/${member.MemberNumber}/member-details`}>{member.PatronDisplayName}</Link>
                            {member.CommitteeRoleTitle && member.CommitteeRoleTitle === "Vice-Chair"
                                ? this.state.committeeData.ChamberCode === "H"
                                    ? " (Vice Chair)"
                                    : " (Co-Chair)"
                                : member.CommitteeRoleTitle !== "Member" && member.CommitteeRoleTitle !== "Ex-Officio" && " (" + member.CommitteeRoleTitle + ")"
                            }
                            {memberIndex !== members.length - 1 && ", "}
                        </React.Fragment>
                    )}
                </p>
                <p className="committee-member-details">
                    {changedMembers.map((member, memberIndex) =>
                        <React.Fragment key={memberIndex}>
                            {member.Change === "Joined"
                                ? moment(member.AssignDate).format('MM/DD/YYYY') + " " + member.PatronDisplayName + " joined\n"
                                : moment(member.RemoveDate).format('MM/DD/YYYY') + " " + member.PatronDisplayName + " left\n"
                            }
                        </React.Fragment>
                    )}
                </p>
            </React.Fragment>;
        }

        return (
            <div className="committee-details-page generic-details-page public-details inner-grid">
                <div>
                    <h2 style={{ marginBottom: "7px" }}>{sessionData.SessionYear} {sessionData.DisplayName}</h2>
                </div>
                {committeeData.ParentCommitteeID && <Link className="parent-committee-link" to={`/session-details/${sessionData.SessionCode}/committee-information/${parentCommitteeData.CommitteeNumber}/committee-details`}><i className="icon button-left" style={{ marginRight: "2px" }} />{(parentCommitteeData.ChamberCode === "H" ? "House " : "Senate ") + parentCommitteeData.Name}</Link>}
                <div className="details-header">
                    <h3 style={{ marginBottom: "10px" }}>{committeeLabel}</h3>
                    <LoaderComponent data={this.state.detailsIsLoaded} />
                </div>
                <div className="details-section">
                    <h4 >Membership</h4>
                    <LoaderComponent data={this.state.membersIsLoaded} />
                    {memberList.length === 0 &&
                        <p>No Members</p>
                    }
                    <React.Fragment>
                        {buildMembers(memberList)}
                    </React.Fragment>
                </div>
                {this.state.minutesListIsLoaded || this.state.docketListIsLoaded ?
                    <React.Fragment>
                        {committeeData.ChamberCode === "H" ?
                            <div className={this.state['slideDockets'] ? 'parent-li slidedown-open' : 'parent-li slidedown-closed'}>
                                {committeeData.ParentCommitteeID && <p>Committee chairs serve as ex officio members of House subcommittees.</p>}
                                {docketList.length === 0 ?
                                    this.state.sessionData && this.state.sessionData.IsActive &&
                                    <div className="table-container description">
                                        <p style={{ marginBottom: "0px" }}>{committeeData.MeetingNote}</p>
                                        <a target="_blank" href={`https://virginiageneralassembly.gov/house/agendas/agendaDates.php?id=${committeeData.CommitteeNumber}&ses=${formattedSessionCode}`} >{committeeData.ParentCommitteeID ? "Subcommittee Agendas" : "Committee Agendas"}</a>
                                    </div>
                                    :
                                    <React.Fragment>
                                        <div className={this.state['slideDockets'] ? 'parent-li slidedown-open' : 'parent-li slidedown-closed'}>
                                            <div className="toggle-row">
                                                <div className="table-container description">
                                                    <h4>Agendas</h4>
                                                    <p>{committeeData.MeetingNote}</p>
                                                </div>
                                                {docketList.length > 3 &&
                                                    <div className="button-container text-align-left">
                                                        <label aria-expanded={this.props.slideDockets} className="arrow-down-label" onClick={this.props.toggleShowData.bind(this, 'slideDockets')}>All Agendas</label>
                                                        <button aria-expanded={this.props.slideDockets} className="arrow-down" onClick={this.props.toggleShowData.bind(this, 'slideDockets')}>All Agendas</button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {!this.state.sessionData.IsActive &&
                                            <div>
                                                <table className="line-table">
                                                    <tbody className="toggle-table">
                                                        {!this.props.slideDockets &&
                                                            <DocketList dockets={docketList.slice(0, 3)} formattedSessionCode={formattedSessionCode} committeeNumber={committeeData.CommitteeNumber} />
                                                        }
                                                        {this.props.slideDockets && docketList.length > 3 &&
                                                            <div className="list-desc">
                                                                <DocketList dockets={docketList} formattedSessionCode={formattedSessionCode} committeeNumber={committeeData.CommitteeNumber} />
                                                            </div>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                    </React.Fragment>
                                }
                                <div className="details-section">
                                    {this.state.sessionData && this.state.sessionData.IsActive &&
                                        <a rel="noreferrer" target="_blank" href={`https://virginiageneralassembly.gov/house/reports/reportsDates.php?id=${this.state.committeeData.CommitteeNumber}&ses=${formattedSessionCode}`} >{committeeData.ParentCommitteeID ? "Subcommittee Reports" : "Committee Reports"}</a>
                                    }
                                    {this.state.sessionData && this.state.sessionData.IsActive &&
                                        <p><a rel="noreferrer" target="_blank" href={`https://hodspeak.house.virginia.gov/committees/${this.state.committeeData.CommitteeNumber}/bill_feedback?ses=${formattedSessionCode}`} >Comments on Bills</a></p>
                                    }
                                </div>
                            </div>
                            :
                            <React.Fragment>
                                <div className={this.state['slideDockets'] ? 'parent-li slidedown-open' : 'parent-li slidedown-closed'}>
                                    {committeeData.ParentCommitteeID && <p>The Chair of the full Committee shall be an ex officio member of all subcommittees and entitled to vote, but shall not be counted as a member for purposes of a quorum. </p>}
                                    <div className="toggle-row">
                                        <div className="table-container description">
                                            <h4>Meetings (Dockets, Votes, Reports)</h4>
                                            <p>{this.state.committeeData.MeetingNote}</p>
                                        </div>
                                    </div>
                                    {minutesList.length > 3 &&
                                        <div className="button-container text-align-left">
                                            <label aria-expanded={this.props.slideDockets} className="arrow-down-label" onClick={this.props.toggleShowData.bind(this, 'slideDockets')}>All Meetings</label>
                                            <button aria-expanded={this.props.slideDockets} className="arrow-down" onClick={this.props.toggleShowData.bind(this, 'slideDockets')}>All Meetings</button>
                                        </div>
                                    }
                                    {this.state.committeeData.AgendaURL &&
                                        <div className="table-container description">
                                            <p>
                                                <a href={this.state.committeeData.AgendaURL} target="_blank">Agendas</a>
                                            </p>
                                        </div>
                                    }
                                    {this.state.committeeData.CommitteeID === 26 && //Senate Finance
                                        <div className="table-container description">
                                            <p>
                                                <a href={"http://sfac.virginia.gov/about.shtml"} target="_blank">Staff/Information</a>
                                            </p>
                                        </div>
                                    }
                                    {this.state.docketList.length > 0 && defaultDocket && !this.state.sessionData.IsActive &&
                                        <div>
                                            {/*don't show the 'All Meetings' button twice -- already shown if minutesList.length > 3*/
                                                minutesList.length <= 3 &&
                                                <div className="button-container text-align-left">
                                                    <label aria-expanded={this.props.slideDockets} className="arrow-down-label" onClick={this.props.toggleShowData.bind(this, 'slideDockets')}>All Meetings</label>
                                                    <button aria-expanded={this.props.slideDockets} className="arrow-down" onClick={this.props.toggleShowData.bind(this, 'slideDockets')}>All Meetings</button>
                                                </div>
                                            }
                                            <table className="line-table">
                                                <tbody className="toggle-table">
                                                    <DocketData
                                                        docket={defaultDocket}
                                                        formattedSessionCode={formattedSessionCode}
                                                        committeeNumber={committeeData.CommitteeNumber}
                                                    />
                                                    {this.props.slideDockets && !this.state.sessionData.IsActive && docketList.length > 3 ?
                                                        <div className="list-desc">
                                                            <DocketList sessionData={this.state.sessionData} minutes={minutesList} dockets={docketList} formattedSessionCode={formattedSessionCode} committeeNumber={committeeData.CommitteeNumber} />
                                                        </div>
                                                        :
                                                        <div className="list-desc">
                                                            <DocketList sessionData={this.state.sessionData} maxSize={3} minutes={minutesList} dockets={docketList} formattedSessionCode={formattedSessionCode} committeeNumber={committeeData.CommitteeNumber} />
                                                        </div>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                                {minutesList.length > 0 &&
                                    <div>
                                        <table className="line-table">
                                            <tbody className="toggle-table">
                                                {this.props.slideDockets
                                                    ? <div className="list-desc">
                                                        <MinutesList minutes={minutesList} sessionCode={sessionData.SessionCode} committeeNumber={committeeData.CommitteeNumber} />
                                                    </div>
                                                    : <MinutesList minutes={minutesList.slice(0, 3)} sessionCode={sessionData.SessionCode} committeeNumber={committeeData.CommitteeNumber} />}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </React.Fragment>
                        }
                    </React.Fragment>
                    :
                    <div aria-label="Loading" className="spinner"></div>
                }
                {committeeData.CommitteeFiles && committeeData.CommitteeFiles.length > 0 &&
                    <div className="details-section">
                        <p>
                            {committeeData.CommitteeFiles.map((link, linkIndex) =>
                                <a key={linkIndex} target="_blank" href={link.FileURL}>{link.Description}</a>
                            )}
                        </p>
                    </div>
                }
                {committeeData.ParentCommitteeID ? (
                    <div className="details-section">
                        <h4>Subcommittee Legislation</h4>
                        <p>
                            <Link to={this.makeLink(3, false)} style={{ display: 'inline-block' }}>Assigned-To</Link><a onClick={() => this.toggleTooltip(ASSIGNED_TO)} className="button info" style={{ display: 'inline-block' }}>Info</a>
                            {this.state.showTooltip[ASSIGNED_TO] &&
                                <div className="info-box committee-tooltip">
                                    <p>{ASSIGNED_TO}</p>
                                </div>
                            }
                            <br />
                            <Link to={this.makeLink(null, true)} style={{ display: 'inline-block' }}>Currently In</Link><a onClick={(e) => { e.stopPropagation(e); this.toggleTooltip(CURRENTLY_IN) }} className="button info" style={{ display: 'inline-block' }}>Info</a>
                            {this.state.showTooltip[CURRENTLY_IN] &&
                                <div className="info-box committee-tooltip">
                                    <p>{renderHTML(CURRENTLY_IN)}</p>
                                </div>
                            }
                        </p>
                    </div>
                ) : (
                    <div className="details-section">
                        <h4>Committee Legislation</h4>
                        <p>
                            <Link to={this.makeLink(3, false)}>Referred</Link>
                            <Link to={this.makeLink(null, true)}>In Committee</Link>
                            <Link to={this.makeLink(null, true)}>In sub-committee</Link>
                            <Link to={this.makeLink(5, false)}>Reported Out</Link>
                            <Link to={this.makeLink(11, true)}>Continued</Link>
                            <Link to={this.makeLink(12, false)}>Failed</Link>
                        </p>
                    </div>
                )}
                {subcommitteesList.length > 0 &&
                    <div className="details-section">
                        <h4>Subcommittees</h4>
                        <LoaderComponent data={this.state.subcommitteesIsLoaded} />
                        <p>
                            <SubCommitteesList parentCommitteeNumber={this.state.committeeNumber} sessionCode={this.props.match.params.sessioncode} committees={subcommitteesList} />
                        </p>
                    </div>
                }
            </div>
        )
    }
}

const PublicCommitteeDetails = withRouter(connect(
    (state) => {
        const { committee, session, nav, calendar, minutes, members, login } = state;
        return {
            committee,
            session,
            nav,
            calendar,
            minutes,
            members,
            login
        };
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, committeeActionCreators, sessionActionCreators, navActionCreators, calendarActionCreators, minutesActionCreators, memberActionCreators), dispatch)
        };
    }
)(CommitteeDetailsComponent));

export default PublicCommitteeDetails;
