import React from 'react';
import ReactDOM from 'react-dom';
import renderHTML from 'react-render-html';
import moment from 'moment-timezone';
import { saveAs } from 'file-saver';
import { generateCsv } from '../../../services/csv.service';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import stripTags from "striptags";
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import { memberActionCreators } from '../../../stores/lis-members-store';
import { billActionCreators } from '../../../stores/lis-legislation-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { patronActionCreators } from '../../../stores/lis-patron-store';
import { voteActionCreators } from '../../../stores/lis-votes-store';
import { collectionActionCreators } from '../../../stores/lis-collection-store';
import { reportActionCreators } from "../../../stores/lis-report-store";
import { personnelActionCreators } from '../../../stores/lis-personnel-store';
import { Link } from 'react-router-dom';

import ShareReport from './share-report'

const BILL_COLLECTION_AUTHOR = "LegislationCollections";
const STAFF = "STAFF";
const CLERKS = "CLERKS";

const MAX_BILL_HISTORY_CHUNK_SIZE = 125;

const weights = {
    "Committee": 1,
    "Subcommittee": 2,
    "recent": 3,
    "all": 4
}
const sortBillHistoryTypes = (typeA, typeB) => {
    return weights[typeA] - weights[typeB];
}

class ReportMaker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCatchline: true,
            showCurrentStatus: true,
            showTitle: false,
            showChiefPatron: false,
            showAllPatrons: false,
            showSummary: false,
            showNotes: false,
            selectedNotes: 'all',
            showHistory: false,
            showHouseAmendments: false,
            showSenateAmendments: false,
            showConferenceAmendments: false,
            showGovernorsRecommendations: false,
            showStatus: {},
            showCommitteeEvent: {},
            showLdNumber: false,
            showClerkNames: false,
            showDlsStaffNames: false,
            billData: [],
            billList: [],
            collections: [],
            committeeClerksAndStaff: [],
            generatingBillNumbers: true,
            generatingReport: true,
            hbCount: 0,
            hjCount: 0,
            sbCount: 0,
            sjCount: 0,
            showTooltip: false,
            compositeSortValue: "LegislationNumberAsc",
            showNotes: true
        };
        this.getReportData = this.getReportData.bind(this);
        this.makeHtml = this.makeHtml.bind(this);
        this.makePrintout = this.makePrintout.bind(this);
        this.makeSpreadsheet = this.makeSpreadsheet.bind(this);
        this.toggleShareForm = this.toggleShareForm.bind(this);
        this.toggleTooltip = this.toggleTooltip.bind(this);
        this.getBillHistoryTypeLabel = this.getBillHistoryTypeLabel.bind(this);
        this.toggleCompositeSort = this.toggleCompositeSort.bind(this);
        this.toggleShowNotes = this.toggleShowNotes.bind(this);

        this.reportData = React.createRef();
        this.htmlRef = React.createRef();
        this.printOutRef = React.createRef();
    }

    async getCommitteeClerksOrStaff(bill, sessionStartDate, role) {
        if (role === STAFF) {
            bill.loadingStaffNames = true;
        } else {
            bill.loadingClerkNames = true;
        }
        this.props.actions.getPersonnelList("staffroletypeid=" + role === STAFF ? this.state.staffRoleTypeID : this.state.clerkRoleTypeID + "&affiliationID=" + bill.CommitteeID + "&effectiveBeginDate=" + sessionStartDate, true)
            .then(() => {
                let committeeClerksAndStaff = [...this.state.committeeClerksAndStaff];
                let personnelList = role === STAFF ? this.props.personnel.personnelStaffList : this.props.personnel.personnelClerkList;
                personnelList.forEach(p => {
                    p.SessionID = bill.SessionID
                })
                committeeClerksAndStaff = committeeClerksAndStaff.concat(personnelList);
                this.setState({ committeeClerksAndStaff })
            })
        if (role === STAFF) {
            bill.loadingStaffNames = false;
        } else {
            bill.loadingClerkNames = false;
        }
    }

    async getLdNumber(bill, versions) {
        bill.loadingLdNumber = true;
        if (!versions) {
            const params = '?BillNumber=' + bill.LegislationNumber + '&sessionID=' + bill.SessionID;
            await this.props.actions.getBillVersionByBillNumber(params).then(async () => {
                versions = this.props.bills.billVersion || [];
            })
        }
        const fullTextVersions = versions.filter(v => v.IsActive && ["Engrossed", "Reengrossed", "Enrolled", "Reenrolled", "Chaptered", "Governor's Veto", "Introduced", "Substitute"].includes(v.Version));
        const latestFullTextVersion = fullTextVersions.length ? fullTextVersions.reduce(function (r, a) {
            return r.DraftDate > a.DraftDate ? r : a;
        }) : null;
        bill.LdNumber = latestFullTextVersion ? latestFullTextVersion.LDNumber : null;
        bill.loadingLdNumber = false;
    }

    async getAmendments(bill) {
        bill.loadingAmendments = true;
        const params = '?BillNumber=' + bill.LegislationNumber + '&sessionID=' + bill.SessionID;
        await this.props.actions.getBillVersionByBillNumber(params).then(async () => {
            if (this.state.showLdNumber) {
                bill.LdNumber = this.getLdNumber(this.props.bills.billVersion || []);
            }
            bill.amendments = [];
            const amendments = this.props.bills.billVersion.filter(version => {
                if (version.Version.includes('Amendment') && ((this.state.showHouseAmendments && version.ChamberCode === 'H') || (this.state.showSenateAmendments && version.ChamberCode === 'S'))) {
                    return true;
                } else if (version.Version.includes('Conference') && this.state.showConferenceAmendments) {
                    return true;
                } else if (version.Version.includes('Recommendation') && this.state.showGovernorsRecommendations) {
                    return true;
                }
                return false;
            });
            for (let i = 0; i < amendments.length; i++) {
                await this.props.actions.getBillText('?isPublic=true&legislationTextID=' + amendments[i].LegislationTextID)
                    .then(() => {
                        if (this.props.bills.billText !== 0) {
                            bill.amendments.push(this.props.bills.billText[0]);
                        }
                    })
            }
            bill.loadingAmendments = false;
            return Promise.resolve();
        }).catch(err => {
            bill.loadingAmendments = false;
            return Promise.reject(err);
        });
    }

    async getBillHistory(bills) {
        const { showHistory } = this.state;
        let legislationIDs = "";
        bills.forEach((bill, i) => {
            bill.loadingHistory = true;
            legislationIDs += `${i === 0 ? '?' : '&'}legislationIDs=${bill.LegislationID}`;
        })
        await this.props.actions.getBillHistoryByBillID(legislationIDs)
            .then(async () => {
                const allResults = this.props.bills.billHistory;
                for (const bill of bills) {
                    let results = allResults.filter(b => b.LegislationID === bill.LegislationID);

                    let billHistory = {};
                    if (showHistory.recent) {
                        if (results.length > showHistory.mostRecentAmount) {
                            billHistory.recent = results.slice(results.length - parseInt(showHistory.mostRecentAmount || 0));
                        } else {
                            billHistory.recent = results;
                        }
                    } else if (showHistory.all) {
                        billHistory.all = results;
                    }
                    if (showHistory.Committee || showHistory.Subcommittee) {
                        results.forEach(historyItem => {
                            if (historyItem.CommitteeName && !historyItem.ParentCommitteeName && showHistory.Committee) {
                                //Need to get the full list of committee events in composite view
                                //so we can get the latest committee event for both chambers -JGB
                                if (!this.props.match.path.includes("composite")) {
                                    billHistory.Committee = [historyItem];
                                } else if (historyItem.Status === "In Committee") {
                                    if (!billHistory.Committee) {
                                        billHistory.Committee = [];
                                    }
                                    billHistory.Committee.push(historyItem);
                                }
                            } else if (historyItem.ParentCommitteeName && showHistory.Subcommittee) {
                                billHistory.Subcommittee = [historyItem];
                            }
                        });
                    }
                    let committeeEvents = {};
                    results.forEach(historyItem => {
                        if (!committeeEvents.firstSenateCommitteeEvent && historyItem.ChamberCode === "S" && historyItem.Status === "In Committee") {
                            committeeEvents.firstSenateCommitteeEvent = historyItem;
                        }
                        if (!committeeEvents.firstHouseCommitteeEvent && historyItem.ChamberCode === "H" && historyItem.Status === "In Committee") {
                            committeeEvents.firstHouseCommitteeEvent = historyItem;
                        }
                        if (historyItem.ChamberCode === "S" && historyItem.Status === "In Subcommittee") {
                            committeeEvents.lastSenateSubcommitteeEvent = historyItem;
                        }
                        if (historyItem.ChamberCode === "H" && historyItem.Status === "In Subcommittee") {
                            committeeEvents.lastHouseSubcommitteeEvent = historyItem;
                        }
                    });
                    bill.committeeEvents = committeeEvents;
                    bill.billHistory = billHistory;
                    bill.loadingHistory = false;
                    if (showHistory.showVotes) {
                        //Can't use a foreach function right here because async will not work since foreach takes a function.
                        for (let i = 0; i < results.length; i++) {
                            if (results[i].VoteID) {
                                this.getVoteDetails(results, i);
                            }
                        }
                    }
                };
                return Promise.resolve()
            }).catch(err => { bills.map(bill => bill.loadingHistory = false); Promise.reject(err) });
    }

    async getVoteDetails(billHistory, i) {
        billHistory[i].loadingVotes = true;
        await this.props.actions.getVoteDetails('?voteID=' + billHistory[i].VoteID)
            .then(() => {
                const voteData = this.props.votes.voteDetailsResponse[0];
                let voteDetails = {};
                voteDetails.ChamberCode = voteData.ChamberCode;
                voteDetails.VoteType = voteData.VoteType;
                voteDetails.memberYeas = voteData.VoteMember.filter(nObj => nObj.ResponseCode === 'Y');
                voteDetails.memberNays = voteData.VoteMember.filter(nObj => nObj.ResponseCode === 'N');
                voteDetails.memberAbstentions = voteData.VoteMember.filter(nObj => nObj.ResponseCode === 'A');
                voteDetails.memberNoVote = voteData.VoteMember.filter(nObj => nObj.ResponseCode === 'X');
                voteDetails.voteStatements = voteData.VoteMember.filter(member => member.VoteStatement);
                // Abstentions appear as rule 36 on senate floor tally sheets
                if (voteData.ChamberCode === "S" && voteData.VoteType === "Floor") {
                    voteDetails.memberRule36 = voteData.VoteMember.filter(nObj => nObj.ResponseCode === 'A');
                } else {
                    voteDetails.memberRule36 = voteData.VoteMember.filter(nObj => nObj.ResponseCode === 'R');
                }
                billHistory[i].voteDetails = voteDetails;
                billHistory[i].loadingVotes = false;
                return Promise.resolve()
            }).catch(err => {
                billHistory[i].loadingVotes = false;
                return Promise.reject(err);
            });
    }

    async getPatrons(bill) {
        bill.loadingPatrons = true;
        await this.props.actions.getPatronList(bill.LegislationID)
            .then(() => {
                bill.Patrons = this.props.patrons.patronList
                bill.loadingPatrons = false;
                return Promise.resolve
            }).catch(err => { bill.loadingPatrons = false; Promise.reject(err) });
    }

    async getBillStatusHistory(bill) {
        bill.loadingStatusHistory = true;
        let body = { "LegislationIDs": [{ "LegislationID": bill.LegislationID }] }
        await this.props.actions.getBillStatusHistory(body)
            .then(() => {
                bill.billStatuses = this.props.bills.legislationStatusHistory[0]
                bill.loadingStatusHistory = false;
                return Promise.resolve
            }).catch(err => { bill.loadingStatusHistory = false; Promise.reject(err) });
    }

    async getWatchlists(params) {
        await this.props.actions.getCollections(params)
            .then(() => {
                this.setState({
                    collections: this.props.collection.collections
                }, () => {
                    return Promise.resolve()
                })
            }).catch(err => Promise.reject(err));
    }

    getReportData() {
        const { showTitle, showChiefPatron, showAllPatrons, showNotes, userCreated, showHistory, showHouseAmendments, showSenateAmendments, showConferenceAmendments, showGovernorsRecommendations, showStatus, showCommitteeEvent, showLdNumber, showClerkNames, showDlsStaffNames, selectedCollection, billList } = this.state;
        const params = {
            LegislationNumbers: [{ LegislationNumber: billList.map(bill => bill.LegislationNumber).join() }],
            SessionID: billList[0].SessionID
        };
        this.setState({
            sbCount: billList.filter(bill => bill.LegislationNumber.includes('SB')).length,
            sjCount: billList.filter(bill => bill.LegislationNumber.includes('SJ')).length,
            hbCount: billList.filter(bill => bill.LegislationNumber.includes('HB')).length,
            hjCount: billList.filter(bill => bill.LegislationNumber.includes('HJ')).length
        });
        this.props.actions.getBillList(params).then(() => {
            this.setState({
                generatingBillNumbers: false
            })
            let committeePromises = [];
            let patronPromises = [];
            let historyPromises = [];
            let textPromises = [];
            let statusHistoryPromises = [];
            let billData = this.props.bills.billList;
            //Filter to only one result per bill per session since DB sometimes returns duplicates
            billData = billData.filter((item, pos) => billData.findIndex(i => i.LegislationNumber === item.LegislationNumber && i.SessionID === item.SessionID) === pos);
            //The backend will return the requested bill numbers in alphanumeric order, regardless of the bill number order in the request
            //With that in mind, if the report is created from within bill search, the list of bill numbers stored in the report & sent in the request above will be sorted alphanumerically, so the backend sort is the same and desired
            //If coming from a calendar, however, the list of bill numbers will initially be sorted in calendar order, which is desired, but the api will return the bills alphanumerically
            //That said, the initial bill number order (stored in the report object) is always the proper order, so we need to sort the response back to that
            billData.sort((a, b) => billList.findIndex(l => l.LegislationNumber === a.LegislationNumber && l.SessionID === a.SessionID) - billList.findIndex(l => l.LegislationNumber === b.LegislationNumber && l.SessionID === b.SessionID))
            let showStatusBool = false;
            for (const key in showStatus) {
                if (showStatus[key] === true && key !== "showEmergency") {
                    showStatusBool = true;
                }
            }
            let showCommitteeEventBool = false;
            for (const key in showCommitteeEvent) {
                if (showCommitteeEvent[key] === true) {
                    showCommitteeEventBool = true;
                }
            }
            this.setState({
                billData,
                gettingBillHistory: showHistory || showCommitteeEventBool ? true : false,
                gettingBillStatusHistory: showStatusBool ? true : false,
                gettingClerkNames: showClerkNames ? true : false,
                gettingStaffNames: showDlsStaffNames ? true : false,
                gettingPatrons: showAllPatrons ? true : false,
                gettingVotes: showHistory && showHistory.showVotes ? true : false,
                gettingLdNumbers: showLdNumber ? true : false,
                gettingAmendments: showHouseAmendments || showSenateAmendments || showConferenceAmendments || showGovernorsRecommendations ? true : false
            }, () => {
                let watchlistPromise = null;
                if (showNotes && this.props.login.userProfile.email === userCreated) {
                    watchlistPromise = this.getWatchlists("?SessionID=" + billList[0].SessionID);
                } else {
                    watchlistPromise = new Promise(resolve => resolve());
                }
                watchlistPromise.then(() => {
                    if (showClerkNames || showDlsStaffNames) {
                        this.props.actions.getRoleTypes()
                            .then(() => {
                                const clerkRoleType = this.props.personnel.roleTypes.find(x => x.Name.toLowerCase() === 'committee clerk')
                                const clerkRoleTypeID = clerkRoleType ? clerkRoleType.StaffRoleTypeID : 0;
                                const staffRoleType = this.props.personnel.roleTypes.find(x => x.Name.toLowerCase() === 'committee staff')
                                const staffRoleTypeID = staffRoleType ? staffRoleType.StaffRoleTypeID : 0;
                                this.setState({ clerkRoleTypeID, staffRoleTypeID }, () => {
                                    this.props.actions.getSessionList().then(() => {
                                        let committeesAndSessionsToSearch = [];
                                        const sessionList = this.props.session.sessionList;
                                        billData.forEach(b => {
                                            if (b.CommitteeID && b.SessionID && !committeesAndSessionsToSearch.find(c => c.CommitteeID === b.CommitteeID && c.SessionID === b.SessionID)) {
                                                committeesAndSessionsToSearch.push({ CommitteeID: b.CommitteeID, SessionID: b.SessionID });
                                                if (showClerkNames) {
                                                    committeePromises.push(this.getCommitteeClerksOrStaff(b, sessionList.find(sl => sl.SessionID === b.SessionID).SessionEvents.find(se => se.EventTypeID === 1).ActualDate, CLERKS));
                                                }
                                                if (showDlsStaffNames) {
                                                    committeePromises.push(this.getCommitteeClerksOrStaff(b, sessionList.find(sl => sl.SessionID === b.SessionID).SessionEvents.find(se => se.EventTypeID === 1).ActualDate, STAFF));
                                                }
                                            }
                                        })
                                    })
                                })
                            })
                    }
                    let billHistoryChunks = [];
                    billData.forEach(bill => {
                        bill['Chief Patron'] = bill.Patrons;
                        if (showAllPatrons) {
                            patronPromises.push(this.getPatrons(bill));
                        } else {
                            patronPromises.push(() => Promise.resolve());
                        }
                        bill.Notes = [];
                        if (showStatus.showEmergency) {
                            bill.Emergency = bill.EffectiveType === 'Emergency' ? "Y" : "N"
                        }
                        if (showStatusBool) {
                            statusHistoryPromises.push(this.getBillStatusHistory(bill));
                        } else {
                            statusHistoryPromises.push(() => Promise.resolve());
                        }
                        if (showNotes) {
                            if (this.state.selectedNotes === 'all') {
                                this.state.collections.forEach(coll => {
                                    let note = coll.WatchListLegislation.find(leg => leg.LegislationID === bill.LegislationID && leg.Note);
                                    if (note) {
                                        bill.Notes.push({ ...note, ListName: coll.Name });
                                    }
                                });
                            }
                            if (this.state.selectedNotes === 'current') {
                                this.state.collections.forEach(coll => {
                                    if (coll.WatchListID === selectedCollection) {
                                        let note = coll.WatchListLegislation.find(leg => leg.LegislationID === bill.LegislationID && leg.Note);
                                        if (note) {
                                            bill.Notes.push({ ...note, ListName: coll.Name });
                                        }
                                    }
                                });
                            }
                        }
                        if (showHistory || showCommitteeEventBool) {
                            if (!billHistoryChunks[0] || billHistoryChunks[billHistoryChunks.length - 1].length >= MAX_BILL_HISTORY_CHUNK_SIZE) {
                                billHistoryChunks.push([bill]);
                            } else if (billHistoryChunks[billHistoryChunks.length - 1].length < MAX_BILL_HISTORY_CHUNK_SIZE) {
                                billHistoryChunks[billHistoryChunks.length - 1].push(bill);
                            }
                        }
                        if (showHouseAmendments || showSenateAmendments || showConferenceAmendments || showGovernorsRecommendations) {
                            textPromises.push(this.getAmendments(bill));
                        } else if (showLdNumber) {
                            textPromises.push(this.getLdNumber(bill))
                        } else {
                            textPromises.push(() => Promise.resolve());
                        }
                        this.setState({ billData });
                    });
                    if (billHistoryChunks.length) {
                        billHistoryChunks.forEach(chunk => historyPromises.push(this.getBillHistory(chunk)))
                    } else {
                        historyPromises.push(() => Promise.resolve());
                    }
                    Promise.all(committeePromises).then(() => this.setState({ gettingClerkNames: false, gettingStaffNames: false }));
                    Promise.all(patronPromises).then(() => this.setState({ gettingPatrons: false }));
                    Promise.all(historyPromises).then(() => this.setState({ gettingBillHistory: false, gettingVotes: false }));
                    Promise.all(textPromises).then(() => this.setState({ gettingAmendments: false, gettingLdNumbers: false }));
                    Promise.all(statusHistoryPromises).then(() => this.setState({ gettingBillStatusHistory: false }));

                    Promise.all(committeePromises.concat(patronPromises).concat(historyPromises).concat(textPromises).concat(statusHistoryPromises)).then(() => {
                        this.setState({ generatingReport: false })
                    }).catch((e) => {
                        console.log(e);
                        this.setState({ generatingReport: false })
                    })
                });
            });
        }).catch(err => {
            this.setState({
                reportError: err.toString(),
                generatingReport: false,
                generatingBillNumbers: false
            });
        });
    }

    makePrintout() {
        let printWindow = this.printOutRef.current;

        const pageStyles = document.querySelectorAll("style, link[rel='stylesheet']");
        pageStyles.forEach(el => {
            printWindow.contentDocument.head.appendChild(el.cloneNode(true));
        });
        const reportDataNode = ReactDOM.findDOMNode(this.reportData.current);
        const contentData = reportDataNode.outerHTML;
        printWindow.contentDocument.body.innerHTML = contentData;
        printWindow.focus();
        // timeout is because some browsers do not set the content of the document in time. Since there is no way to check when it is actually finished this 10 millisecond timeout should do the trick.
        setTimeout(() => printWindow.contentWindow.print(), 10);
    }

    makeHtml() {
        const htmlRefNode = ReactDOM.findDOMNode(this.htmlRef.current);
        let x = htmlRefNode.querySelectorAll('a');
        x.forEach(el => {
            el.setAttribute('href', el.href);
        })
        let breaks = htmlRefNode.querySelectorAll('hr');
        [...breaks].forEach(b => {
            b.parentNode.removeChild(b);
        });
        const fileName = `Search Results ${moment().format("MM_DD_YYYY HH mm")}.html`
        saveAs(new Blob([htmlRefNode.innerHTML], { type: "text/html" }), fileName);
    }

    makeSpreadsheet() {
        const { showCatchline, showCurrentStatus, showTitle, showChiefPatron, showAllPatrons, showSummary, showNotes, showHistory, showHouseAmendments, showSenateAmendments, showConferenceAmendments, showGovernorsRecommendations, showStatus, showCommitteeEvent, billData } = this.state;
        let headers = ["Billno"];
        let historyRows = [];
        let historyHeaders = ["Billno", "Date", "Description"];
        if ((showHistory.all || (showHistory.recent && showHistory.mostRecentAmount > 1)) && showHistory.showVotes) {
            historyHeaders.push("YEAS", "NAYS", "NOT VOTING", "ABSTENTIONS", "RULE 36");
        }
        billData.forEach(bill => {
            //Billno needs to be in hyperlink format; to get the appropriate href attr, create a fake element and attach to the DOM and use the resulting href
            let el = document.createElement("a");
            el.style.display = 'none';
            el.href = `/bill-details/${bill["SessionCode"]}/${bill["LegislationNumber"]}`; //relative path; the following logic will turn it into the real/appropriate href (e.g. https://test.lis... or https://qa.lis...)
            el.className = bill["LegislationNumber"];
            document.body.appendChild(el);
            const href = document.getElementsByClassName(bill["LegislationNumber"])[0];
            document.body.removeChild(el);
            const hyperlinkedBill = `=HYPERLINK(""${href}` + `"",""` + bill["LegislationNumber"] + `"")`;
            bill["Billno"] = hyperlinkedBill;
            if (showCatchline) {
                headers.push("Catch line");
                bill["Catchline"] = bill["Description"];
            }
            if (showCurrentStatus)
                headers.push("LegislationStatus");
            if (showTitle)
                headers.push("LegislationTitle");
            if (showSummary) {
                headers.push("LegislationSummary");
            }
            if (showChiefPatron) {
                let patrons = bill.Patrons ? bill.Patrons.filter(p => p.Name === 'Chief Patron').map(patron => patron.PatronDisplayName) : [];
                patrons = patrons.join(', ');
                const header = "Chief Patron";
                headers.push(header);
                bill[header.replaceAll(" ", "")] = patrons;
            }
            if (showAllPatrons) {
                let patrons = bill.Patrons ? bill.Patrons.map(patron => {
                    let formattedName = patron.PatronDisplayName
                    if (patron.Name === 'Chief Patron') {
                        formattedName += ' ' + patron.DisplayName;
                    }
                    return formattedName;
                }) : [];
                patrons = patrons.join(', ');
                const header = "All Patrons";
                headers.push(header);
                bill[header.replaceAll(" ", "")] = patrons;
            }
            if (showStatus.showEmergency) {
                headers.push("Emergency");
            }
            if (showHistory) {
                if (showHistory.Committee) {
                    const header = this.getBillHistoryTypeLabel("Committee");
                    headers.push(header);
                }
                if (showHistory.Subcommittee) {
                    const header = this.getBillHistoryTypeLabel("Subcommittee");
                    headers.push(header);
                }
                if (showHistory.recent && showHistory.mostRecentAmount < 2) {
                    const header = this.getBillHistoryTypeLabel("recent");
                    headers.push(header);
                }
                Object.entries(bill.billHistory).sort(([typeA, itemsA], [typeB, itemsB]) => sortBillHistoryTypes(typeA, typeB)).map(([billHistoryType, billHistoryItems]) => {
                    if (billHistoryType === 'all' || (billHistoryType === 'recent' && showHistory.mostRecentAmount > 1)) {
                        billHistoryItems.map(historyItem => {
                            let newRow = { ...historyItem, Billno: hyperlinkedBill, Date: moment(historyItem.EventDate).format('MM/DD/YYYY') }
                            if (showHistory.showVotes) {
                                if (historyItem.voteDetails) {
                                    newRow.YEAS = historyItem.voteDetails.memberYeas.map(item => item.PatronDisplayName).join();
                                    newRow.NAYS = historyItem.voteDetails.memberNays.map(item => item.PatronDisplayName).join();
                                    newRow["NOTVOTING"] = historyItem.voteDetails.memberNoVote.map(item => item.PatronDisplayName).join();
                                    newRow.ABSTENTIONS = historyItem.voteDetails.memberAbstentions.map(item => item.PatronDisplayName).join();
                                    newRow["RULE36"] = historyItem.voteDetails.memberRule36.map(item => item.PatronDisplayName).join();
                                }
                            }
                            historyRows.push(newRow)
                        });
                    } else {
                        billHistoryItems.map(historyItem => {
                            if (showHistory.showVotes) {
                                if (historyItem.voteDetails) {
                                    headers.push("YEAS", "NAYS", "NOT VOTING", "ABSTENTIONS", "RULE 36");
                                    bill.YEAS = historyItem.voteDetails.memberYeas.map(item => item.PatronDisplayName).join();
                                    bill.NAYS = historyItem.voteDetails.memberNays.map(item => item.PatronDisplayName).join();
                                    bill["NOTVOTING"] = historyItem.voteDetails.memberNoVote.map(item => item.PatronDisplayName).join();
                                    bill.ABSTENTIONS = historyItem.voteDetails.memberAbstentions.map(item => item.PatronDisplayName).join();
                                    bill["RULE36"] = historyItem.voteDetails.memberRule36.map(item => item.PatronDisplayName).join();
                                }
                            }
                            const header = this.getBillHistoryTypeLabel(billHistoryType);
                            bill[header.replaceAll(" ", "")] = historyItem.Description;
                        });
                    }
                });
            }
            if (bill.billStatuses) {
                if (showStatus.showPassedHouse) {
                    headers.push("Passed House");
                    bill["PassedHouse"] = bill.billStatuses.PassedHouse;
                }
                if (showStatus.showPassedSenate) {
                    headers.push("Passed Senate");
                    bill["PassedSenate"] = bill.billStatuses.PassedSenate;
                }
                if (showStatus.showPassed) {
                    headers.push("Passed");
                    bill["Passed"] = bill.billStatuses.Passed;
                }
                if (showStatus.showFailed) {
                    headers.push("Failed");
                    bill["Failed"] = bill.billStatuses.Failed;
                }
                if (showStatus.showContinued) {
                    headers.push("Continued");
                    bill["Continued"] = bill.billStatuses.Continued;
                }
                if (showStatus.showApproved) {
                    headers.push("Approved");
                    bill["Approved"] = bill.billStatuses.Approved;
                }
                if (showStatus.showVetoed) {
                    headers.push("Vetoed");
                    bill["Vetoed"] = bill.billStatuses.Vetoed;
                }
            }

            if (bill.committeeEvents) {
                if (showCommitteeEvent.showFirstHouseCommittee) {
                    headers.push("1st House com")
                    bill["1stHousecom"] = bill.committeeEvents.firstHouseCommitteeEvent ? `${moment(bill.committeeEvents.firstHouseCommitteeEvent.EventDate).format("MM/DD/YY")} ${bill.committeeEvents.firstHouseCommitteeEvent.ChamberCode} ${bill.committeeEvents.firstHouseCommitteeEvent.Description}` : "";
                }
                if (showCommitteeEvent.showFirstSenateCommittee) {
                    headers.push("1st Senate com")
                    bill["1stSenatecom"] = bill.committeeEvents.firstSenateCommitteeEvent ? `${moment(bill.committeeEvents.firstSenateCommitteeEvent.EventDate).format("MM/DD/YY")} ${bill.committeeEvents.firstSenateCommitteeEvent.ChamberCode} ${bill.committeeEvents.firstSenateCommitteeEvent.Description}` : "";
                }
                if (showCommitteeEvent.showLastHouseSubcommittee) {
                    headers.push("Last House Subcommittee Assigned")
                    bill["LastHouseSubcommitteeAssigned"] = bill.committeeEvents.lastHouseSubcommitteeEvent ? bill.committeeEvents.lastHouseSubcommitteeEvent.Description : "";
                }
                if (showCommitteeEvent.showLastSenateSubcommittee) {
                    headers.push("Last Senate Subcommittee Assigned")
                    bill["LastSenateSubcommitteeAssigned"] = bill.committeeEvents.lastSenateSubcommitteeEvent ? bill.committeeEvents.lastSenateSubcommitteeEvent.Description : "";
                }
            }
            if (showHouseAmendments || showSenateAmendments || showConferenceAmendments || showGovernorsRecommendations) {
                bill.amendments.forEach(amendment => {
                    const columnHeader = '"' + amendment.Description + '"';
                    headers.push(columnHeader);
                    bill[columnHeader.replaceAll(" ", "")] = amendment.DraftText;
                });
            }
            if (showNotes) {
                bill.Notes.forEach((note, noteIndex) => {
                    bill[`Note${noteIndex + 1}`] = note.Note;
                    headers.push(`Note ${noteIndex + 1} `)
                })
            }

            if (showSummary) {
                bill["LegislationSummary"] = stripTags(bill["LegislationSummary"]);
            }

            if (showTitle) {
                bill["LegislationTitle"] = stripTags(bill["LegislationTitle"]);
            }

        });
        headers = headers.filter((val, i, self) => self.indexOf(val) === i);
        let fileName = `Search Results ${moment().format("MM_DD_YYYY HH mm")}.csv`
        let historyFileName = `Bill History ${moment().format("MM_DD_YYYY HH mm")}.csv`;
        if (!showHistory || headers.length > 1) { //This is here so if the user just wants bill history a csv with just the leg numbers will not created.
            saveAs(new Blob([generateCsv(headers.filter(x => !x.toLowerCase().includes("amendment")), billData)], { type: "data:text/csv;charset=utf-8," }), fileName);
        }
        //History will not fit well in a spreadsheet with the other data so another file is saved here.
        if (showHistory.all || (showHistory.recent && showHistory.mostRecentAmount > 1)) {
            saveAs(new Blob([generateCsv(historyHeaders, historyRows)], { type: "data:text/csv;charset=utf-8," }), historyFileName);
        }
    }

    toggleShareForm() {
        this.setState(state => ({
            showShareForm: !state.showShareForm
        }));
    }

    toggleTooltip() {
        this.setState({
            showTooltip: !this.state.showTooltip
        })
    }

    toggleCompositeSort(value) {
        this.setState({
            compositeSortValue: this.state.compositeSortValue === "LegislationNumberAsc" && value === "LegislationNumberAsc"
                ? "LegislationNumberDesc"
                : value === "LegislationNumberAsc"
                    ? "LegislationNumberAsc"
                    : this.state.compositeSortValue === "EventDateAsc"
                        ? "EventDateDesc"
                        : "EventDateAsc"
        })
    }

    toggleShowNotes() {
        this.setState({
            showNotes: !this.state.showNotes
        })
    }

    componentDidMount() {
        const reportId = this.props.match.params.id;
        if (reportId) {
            this.props.actions.getBillReportInformation('?ReportNumber=' + reportId).then(() => {
                const billReportInformation = this.props.report.billReportInformation;
                if (billReportInformation && billReportInformation.BillInformation) {
                    try {
                        const json = JSON.parse(window.atob(billReportInformation.BillInformation));
                        this.setState({
                            ...json
                        }, () => {
                            if (this.state.billList.length > 0) {
                                this.getReportData();
                            }
                            if (this.state.selectedCollection) {
                                this.props.actions.getCollection("?WatchListID=" + this.state.selectedCollection);
                            }
                        });
                    } catch {
                        console.error("Malformed url");
                        this.setState({ generatingReport: false });
                    }
                } else {
                    console.log("Request succeeded, but no report information found.");
                    this.setState({ reportError: "This report does not exist.", generatingReport: false, generatingBillNumbers: false })
                }
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err);
                this.setState({ reportError: "This report does not exist.", generatingReport: false, generatingBillNumbers: false })
            })
        } else {
            console.log("No Report ID found to use.");
        }
    }

    getBillHistoryTypeLabel = (type) => {
        switch (type) {
            case 'all':
                return `All Events`;
            case 'recent':
                return this.state.showHistory.mostRecentAmount > 1 ? `${this.state.showHistory.mostRecentAmount} Last Events` : 'Last Event';
            case 'Committee':
                return `Last Committee Event`;
            case 'Subcommittee':
                return `Last Subcommittee Event`;
        }
        return;
    }

    render() {
        const { billData, reportError, generatingBillNumbers, generatingReport, gettingCommittees, gettingPatrons, gettingAmendments, gettingBillHistory, gettingVotes, gettingLdNumbers, gettingClerkNames, gettingStaffNames, showCatchline, showCurrentStatus, showTitle, showChiefPatron, showAllPatrons, showSummary, showNotes, showHistory, showHouseAmendments, showSenateAmendments, showConferenceAmendments, showGovernorsRecommendations, showStatus, showCommitteeEvent, showLdNumber, showClerkNames, showDlsStaffNames, sbCount, sjCount, hbCount, hjCount, userCreated } = this.state;
        const sortBillsNumerically = (a, b, direction) => {
            if (direction === "asc") {
                return a.LegislationNumber.substring(0, 2).localeCompare(b.LegislationNumber.substring(0, 2)) === 0
                    ? a.LegislationKey - b.LegislationKey
                    : a.LegislationNumber.substring(0, 2).localeCompare(b.LegislationNumber.substring(0, 2)) > 0
                        ? 1
                        : -1
            } else {
                return a.LegislationNumber.substring(0, 2).localeCompare(b.LegislationNumber.substring(0, 2)) === 0
                    ? b.LegislationKey - a.LegislationKey
                    : a.LegislationNumber.substring(0, 2).localeCompare(b.LegislationNumber.substring(0, 2)) > 0
                        ? -1
                        : 1
            }
        }

        if (generatingBillNumbers) {
            return (
                <div className="small-text">
                    {reportError && <div>{reportError}</div>}
                    <div>Getting Bill Data <span className="small-spinner"></span></div>
                </div>)
        }
        const shareable = this.props.login.userClaims.resources.find(resource => resource === BILL_COLLECTION_AUTHOR);
        return (<div>
            <div className="full">
                {this.state.showShareForm && !reportError &&
                    <ShareReport
                        toggleShareForm={this.toggleShareForm}
                    />
                }
                <div className="advanced-search dlas-forms">
                    {!reportError &&
                        <>
                            <div className="flex-row">
                                <br />
                                <div className="inline-list" style={{ textAlign: 'right' }}>
                                    {generatingReport &&
                                        <div className="small-text center">Getting Data <span className="small-spinner"></span></div>
                                    }
                                    <React.Fragment>
                                        {this.props.match.path.includes("composite") && (this.props.login.userProfile.email === userCreated) &&
                                            <button type="button" className="button" onClick={() => this.toggleShowNotes()}>{(this.state.showNotes ? "Hide" : "Show") + " Notes"}</button>
                                        }
                                        {shareable &&
                                            <button disabled={generatingReport} onClick={this.toggleShareForm} type="button" className="button">Share</button>
                                        }
                                        <span aria-label="html" title="html" onClick={this.makeHtml} className={`icon paper${generatingReport ? ' disabled' : ''} `}></span>
                                        <span aria-label="print" title="print" onClick={this.makePrintout} className={`icon print${generatingReport ? ' disabled' : ''} `}></span>
                                        <span aria-label="spreadsheet" title="spreadsheet" onClick={this.makeSpreadsheet} className={`icon csv${generatingReport ? ' disabled' : ''} `}></span>
                                        <a onClick={(e) => { e.stopPropagation(e); this.toggleTooltip() }} style={{ cursor: "pointer" }}>CSV Help</a>
                                        {this.state.showTooltip &&
                                            <div className={`info - box report - tooltip${shareable ? ' shareable' : ''} `}>
                                                <p>If you have selected 'All Events' or 'X last events', then these events will be downloaded in a separate .csv file.</p>
                                                <p>Amendment text is not included in .csv file downloads.</p>
                                                <p>Some text selections in the .csv file may include HTML markup.</p>
                                            </div>
                                        }
                                    </React.Fragment>
                                </div>
                            </div>
                            <br />
                        </>
                    }
                    <div ref={this.htmlRef}>
                        {this.state.reportTitle || this.props.collection.collection != "" ?
                            <div>
                                <h2>{this.state.reportTitle || this.props.collection.collection[0].Name}</h2>
                            </div>
                            : null
                        }
                        <div className="small-text">
                            {reportError && <div>{reportError}</div>}
                        </div>
                        <div ref={this.reportData} className="report-container">
                            <span>{this.state.personalMessage}</span>
                            {!this.props.match.path.includes("composite")
                                ? billData.map((bill, i) =>
                                    <div key={i} style={{ pageBreakInside: 'avoid', display: 'block' }}>
                                        <h3><Link className='report-link' to={`/bill-details/${bill.SessionCode}/${bill.LegislationNumber}`}>{bill.LegislationNumber.split(/(\d+)/).join(" ")}</Link > {showCatchline && ` - ${bill.Description}`
                                        }</h3 >
                                        {
                                            showChiefPatron || showAllPatrons ?
                                                (bill.loadingPatrons ?
                                                    <div style={{ margin: '0.3em 0px' }}>Getting Patrons <span className="small-spinner"></span></div>
                                                    :
                                                    <div>
                                                        {showChiefPatron && bill['Chief Patron'] &&
                                                            <p>Chief Patron{bill['Chief Patron'].length > 1 && "s"}: {bill['Chief Patron'].map((patron, patronIndex) => <React.Fragment key={patronIndex}>
                                                                {patronIndex !== 0 && ', '}{patron.PatronDisplayName}
                                                            </React.Fragment>)}</p>
                                                        }
                                                        {showAllPatrons &&
                                                            <p>Patron{bill.Patrons.length > 1 && "s"}: {bill.Patrons.map((patron, patronIndex) => <React.Fragment key={patronIndex}>
                                                                {patronIndex !== 0 && ', '}{patron.PatronDisplayName}
                                                            </React.Fragment>)}</p>
                                                        }
                                                    </div>)
                                                : null
                                        }
                                        {showCurrentStatus && <p>Status: {bill.LegislationStatus}</p>}
                                        {showTitle && bill.LegislationTitle && renderHTML(bill.LegislationTitle)
                                        }
                                        {
                                            showClerkNames && bill.CommitteeID && bill.SessionID &&
                                                bill.loadingClerkNames || (!this.state.committeeClerksAndStaff.find(c => c.AffiliationID === bill.CommitteeID && c.SessionID === bill.SessionID && c.StaffRoleTypeID === this.state.clerkRoleTypeID) && gettingClerkNames) ?
                                                <div style={{ margin: '0.3em 0px' }}>Getting clerk(s) of current committee <span className="small-spinner"></span></div>
                                                :
                                                this.state.committeeClerksAndStaff.find(c => c.AffiliationID === bill.CommitteeID && c.SessionID === bill.SessionID && c.StaffRoleTypeID === this.state.clerkRoleTypeID) ?
                                                    <div>
                                                        <p>
                                                            <span>Clerk{this.state.committeeClerksAndStaff.filter(c => c.AffiliationID === bill.CommitteeID && c.SessionID === bill.SessionID && c.StaffRoleTypeID === this.state.clerkRoleTypeID).length > 1 ? 's' : ''} of current committee:</span>&nbsp;
                                                            {
                                                                this.state.committeeClerksAndStaff.filter(c => c.AffiliationID === bill.CommitteeID && c.SessionID === bill.SessionID && c.StaffRoleTypeID === this.state.clerkRoleTypeID).map((m, i) =>
                                                                    <span>{m.FullName}{i === this.state.committeeClerksAndStaff.filter(c => c.AffiliationID === bill.CommitteeID && c.SessionID === bill.SessionID && c.StaffRoleTypeID === this.state.clerkRoleTypeID).length - 1 ? '' : ', '}</span>
                                                                )
                                                            }
                                                        </p>
                                                    </div>
                                                    : null
                                        }
                                        {
                                            showDlsStaffNames && bill.CommitteeID && bill.SessionID &&
                                                bill.loadingStaffNames || (!this.state.committeeClerksAndStaff.find(c => c.AffiliationID === bill.CommitteeID && c.SessionID === bill.SessionID && c.StaffRoleTypeID === this.state.staffRoleTypeID) && gettingStaffNames) ?
                                                <div style={{ margin: '0.3em 0px' }}>Getting DLS staff of current committee <span className="small-spinner"></span></div>
                                                :
                                                this.state.committeeClerksAndStaff.find(c => c.AffiliationID === bill.CommitteeID && c.SessionID === bill.SessionID && c.StaffRoleTypeID === this.state.staffRoleTypeID) ?
                                                    <div>
                                                        <p>
                                                            <span>DLS staff of current committee:</span>&nbsp;
                                                            {
                                                                this.state.committeeClerksAndStaff.filter(c => c.AffiliationID === bill.CommitteeID && c.SessionID === bill.SessionID && c.StaffRoleTypeID === this.state.staffRoleTypeID).map((m, i) =>
                                                                    <span>{m.FullName}{i === this.state.committeeClerksAndStaff.filter(c => c.AffiliationID === bill.CommitteeID && c.SessionID === bill.SessionID && c.StaffRoleTypeID === this.state.staffRoleTypeID).length - 1 ? '' : ', '}</span>
                                                                )
                                                            }
                                                        </p>
                                                    </div>
                                                    : null
                                        }
                                        {
                                            showNotes && bill.Notes && bill.Notes.length > 0 &&
                                            <div>
                                                <span>Notes</span>
                                                {bill.Notes.map((note, noteIndex) =>
                                                    <div key={noteIndex}>
                                                        <span className="small-text"><span className="bold">{note.ListName}</span>: {note.Note}</span>
                                                    </div>
                                                )}
                                                <br />
                                            </div>
                                        }
                                        {
                                            showLdNumber &&
                                                bill.loadingLdNumber || (!bill.LdNumber && gettingLdNumbers) ?
                                                <div style={{ margin: '0.3em 0px' }}>Getting LD Number of current version <span className="small-spinner"></span></div>
                                                :
                                                bill.LdNumber ?
                                                    <div>
                                                        <p>LD Number of current version: {bill.LdNumber}</p>
                                                    </div>
                                                    : null
                                        }
                                        {
                                            showSummary &&
                                            <div>
                                                <strong>{bill.SummaryVersion}:</strong>{renderHTML(bill.LegislationSummary)}
                                            </div>
                                        }
                                        {
                                            (showHouseAmendments || showSenateAmendments || showConferenceAmendments || showGovernorsRecommendations) && (
                                                bill.loadingAmendments ?
                                                    <div style={{ margin: '0.3em 0px' }}>Getting Amendment Text <span className="small-spinner"></span></div>
                                                    :
                                                    bill.amendments && bill.amendments.length ?
                                                        <div>
                                                            {bill.amendments.map((amendment, amendmentIndex) =>
                                                                <div key={amendmentIndex}>
                                                                    <strong>{amendment.Description}</strong>
                                                                    {renderHTML(amendment.DraftText)}
                                                                </div>
                                                            )}
                                                        </div>
                                                        : null
                                            )
                                        }
                                        {
                                            bill.billStatuses &&
                                            <div className="inner-grid four small-text">
                                                {bill.loadingStatusHistory ?
                                                    <div style={{ margin: '0.3em 0px' }}>Getting Amendment Text <span className="small-spinner"></span></div>
                                                    :
                                                    <>
                                                        {showStatus.showPassedHouse && <span>Passed House: {bill.billStatuses.PassedHouse}</span>}
                                                        {showStatus.showPassedSenate && <span>Passed Senate: {bill.billStatuses.PassedSenate}</span>}
                                                        {showStatus.showPassed && <span>Passed: {bill.billStatuses.Passed}</span>}
                                                        {showStatus.showVetoed && <span>Vetoed: {bill.billStatuses.Vetoed}</span>}
                                                        {showStatus.showApproved && <span>Approved: {bill.billStatuses.Approved}</span>}
                                                        {showStatus.showFailed && <span>Failed: {bill.billStatuses.Failed}</span>}
                                                        {showStatus.showContinued && <span>Continued: {bill.billStatuses.Continued}</span>}
                                                        {showStatus.showEmergency && <span>Emergency: {bill.Emergency}</span>}
                                                    </>
                                                }
                                            </div>
                                        }
                                        {
                                            bill.committeeEvents &&
                                            <div className="small-text">
                                                {showCommitteeEvent.showFirstHouseCommittee && bill.committeeEvents.firstHouseCommitteeEvent && <div>First House Committee Referred: {bill.committeeEvents.firstHouseCommitteeEvent.Description}</div>}
                                                {showCommitteeEvent.showFirstSenateCommittee && bill.committeeEvents.firstSenateCommitteeEvent && <div>First Senate Committee Referred : {bill.committeeEvents.firstSenateCommitteeEvent.Description}</div>}
                                                {showCommitteeEvent.showLastHouseSubcommittee && bill.committeeEvents.lastHouseSubcommitteeEvent && <div>Last House Subcommittee Assigned: {bill.committeeEvents.lastHouseSubcommitteeEvent.Description}</div>}
                                                {showCommitteeEvent.showLastSenateSubcommittee && bill.committeeEvents.lastSenateSubcommitteeEvent && <div>Last Senate Subcommittee Assigned: {bill.committeeEvents.lastSenateSubcommitteeEvent.Description}</div>}
                                            </div>
                                        }
                                        {
                                            showHistory && (
                                                bill.loadingHistory ?
                                                    <div style={{ margin: '0.3em 0px' }}>{"Getting Bill Events" + (showHistory.showVotes ? " and Vote Data" : "")} <span className="small-spinner"></span></div>
                                                    :
                                                    bill.billHistory &&
                                                    //Move grid container div inti place of react fragment to create a new grid for each row instead of continuing to overfill the parent div
                                                    <div>
                                                        {Object.entries(bill.billHistory).map(([billHistoryType, billHistoryItems], billHistoryTypeIndex) =>
                                                            <div key={billHistoryTypeIndex}>
                                                                <p>{this.getBillHistoryTypeLabel(billHistoryType)}</p>
                                                                <div style={{ display: "grid", gridTemplateColumns: "90px 75px 1fr" }} className="small-text">
                                                                    {billHistoryItems.map((billHistoryItem, billHistoryItemIndex) =>
                                                                        <>
                                                                            <div style={{ marginRight: "12px" }}>
                                                                                {moment(billHistoryItem.EventDate).format('MM/DD/YYYY')}
                                                                            </div>
                                                                            <div style={{ marginRight: "12px" }}>
                                                                                {billHistoryItem.ActorType}
                                                                            </div>
                                                                            <div style={{ marginRight: "12px" }}>
                                                                                {billHistoryItem.Description}
                                                                            </div>
                                                                            {showHistory.showVotes && (
                                                                                billHistoryItem.voteDetails ?
                                                                                    <div style={{ gridColumn: '3 / 4' }}>
                                                                                        <p>
                                                                                            YEAS--
                                                                                            {billHistoryItem.voteDetails.memberYeas.map((item, index) => (
                                                                                                <span key={index}>{item.PatronDisplayName}{billHistoryItem.voteDetails.memberYeas.length === index + 1 ? '--' : ', '}</span>
                                                                                            ))}
                                                                                            {billHistoryItem.voteDetails.memberYeas.length}
                                                                                        </p>
                                                                                        <p>
                                                                                            NAYS--
                                                                                            {billHistoryItem.voteDetails.memberNays.map((item, index) => (
                                                                                                <span key={index}>{item.PatronDisplayName}{billHistoryItem.voteDetails.memberNays.length === index + 1 ? '--' : ', '}</span>
                                                                                            ))}
                                                                                            {billHistoryItem.voteDetails.memberNays.length}
                                                                                        </p>
                                                                                        <p>
                                                                                            {(billHistoryItem.voteDetails.VoteType === "Floor" && billHistoryItem.voteDetails.ChamberCode === "S") ? "RULE 36--" : "ABSTENTIONS--"}
                                                                                            {billHistoryItem.voteDetails.memberAbstentions.map((item, index) => (
                                                                                                <span key={index}>{item.PatronDisplayName}{billHistoryItem.voteDetails.memberAbstentions.length === index + 1 ? '--' : ', '}</span>
                                                                                            ))}
                                                                                            {billHistoryItem.voteDetails.memberAbstentions.length}
                                                                                        </p>
                                                                                        <p>
                                                                                            NOT VOTING--
                                                                                            {billHistoryItem.voteDetails.memberNoVote.map((item, index) => (
                                                                                                <span key={index}>{item.PatronDisplayName}{billHistoryItem.voteDetails.memberNoVote.length === index + 1 ? '--' : ', '}</span>
                                                                                            ))}
                                                                                            {billHistoryItem.voteDetails.memberNoVote.length}
                                                                                        </p>
                                                                                        {(billHistoryItem.voteDetails.VoteType !== "Floor" && billHistoryItem.voteDetails.ChamberCode !== "S") &&
                                                                                            <p>
                                                                                                RULE 36--
                                                                                                {billHistoryItem.voteDetails.memberRule36.map((item, index) => (
                                                                                                    <span key={index}>{item.PatronDisplayName}{billHistoryItem.voteDetails.memberRule36.length === index + 1 ? '--' : ', '}</span>
                                                                                                ))}
                                                                                                {billHistoryItem.voteDetails.memberRule36.length}
                                                                                            </p>
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    billHistoryItem.loadingVotes ?
                                                                                        <div style={{ gridColumn: '3/4', margin: '0.3em 0px' }}>Getting Vote Data <span className="small-spinner"></span></div>
                                                                                        :
                                                                                        null
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                            )
                                        }
                                        <hr className="faded-line" />
                                    </div >
                                )
                                : <>
                                    <div className='composite-view-header'>
                                        <div className="composite-view-header-label" onClick={() => this.toggleCompositeSort("LegislationNumberAsc")}>Bill</div>
                                        <div>Committee</div>
                                        <div>Last Action</div>
                                        <div className="composite-view-header-label" onClick={() => this.toggleCompositeSort("EventDateAsc")}>Date</div>
                                    </div>
                                    {billData.sort((a, b) => {
                                        return this.state.compositeSortValue === "LegislationNumberAsc"
                                            ? sortBillsNumerically(a, b, "asc")
                                            : this.state.compositeSortValue === "LegislationNumberDesc"
                                                ? sortBillsNumerically(a, b, "desc")
                                                : this.state.compositeSortValue === "EventDateAsc"
                                                    ? moment(b.billHistory.recent[0].EventDate).isAfter(a.billHistory.recent[0].EventDate) ? 1 : -1
                                                    : moment(b.billHistory.recent[0].EventDate).isBefore(a.billHistory.recent[0].EventDate) ? 1 : -1
                                    }).map((bill, i) =>
                                        <>
                                            <div className={'composite-view-wrapper ' + ((!this.state.showNotes || this.props.login.userProfile.email !== userCreated) && "notes-hidden")}>
                                                <div className='composite-view-data'>
                                                    <div><Link to={"/bill-details/" + bill.SessionCode + "/" + bill.LegislationNumber}>{bill.LegislationNumber}</Link>{" - " + (bill.Patrons.find(x => x.PatronTypeID === 1) ? (bill.Patrons.find(x => x.PatronTypeID === 1).PatronDisplayName + " - ") : "") + bill.Description}</div>
                                                    {bill.billHistory && bill.billHistory.Committee
                                                        ? <div className='composite-view-committees'>
                                                            <div>{bill.billHistory.Committee.find(x => x.ChamberCode === "H") ? "(H) " + (bill.billHistory.Committee.sort((a, b) => moment(a.EventDate).isBefore(b.EventDate) ? 1 : -1).find(x => x.ChamberCode === "H").ParentCommitteeName ?? bill.billHistory.Committee.sort((a, b) => moment(a.EventDate).isBefore(b.EventDate) ? 1 : -1).find(x => x.ChamberCode === "H").CommitteeName) : ""}</div>
                                                            <div>{bill.billHistory.Committee.find(x => x.ChamberCode === "S") ? "(S) " + (bill.billHistory.Committee.sort((a, b) => moment(a.EventDate).isBefore(b.EventDate) ? 1 : -1).find(x => x.ChamberCode === "S").ParentCommitteeName ?? bill.billHistory.Committee.sort((a, b) => moment(a.EventDate).isBefore(b.EventDate) ? 1 : -1).find(x => x.ChamberCode === "S").CommitteeName) : ""}</div>
                                                        </div>
                                                        : <div className='composite-view-committees'>
                                                            <div />
                                                            <div />
                                                        </div>
                                                    }
                                                    <div>
                                                        {bill.billHistory
                                                            ? (bill.billHistory.recent[0].ChamberCode ? "(" + bill.billHistory.recent[0].ChamberCode + ") " : "") + bill.billHistory.recent[0].Description
                                                            : ""}
                                                    </div>
                                                    <div>
                                                        {bill.billHistory
                                                            ? moment(bill.billHistory.recent[0].EventDate).format("MM/DD/YY")
                                                            : ""}
                                                    </div>
                                                </div>
                                                {this.state.showNotes && userCreated === this.props.login.userProfile.email
                                                    && <>
                                                        {bill.Notes !== undefined && bill.Notes.length > 0
                                                            ? <div className='composite-view-notes'>{"Notes: " + bill.Notes[0].Note}</div>
                                                            : <div />
                                                        }
                                                    </>
                                                }
                                            </div>

                                        </>
                                    )}
                                </>
                            }


                            {!reportError && <span><b>Counts:</b> {`HB: ${hbCount} HJ: ${hjCount} SB: ${sbCount} SJ: ${sjCount}`}</span>}
                        </div>
                    </div>
                </div>
            </div>
            <iframe ref={this.printOutRef} width="0" height="0" frameBorder="0" src="about:blank"></iframe>
        </div >);
    }
}

export default connect(
    (state) => {
        const { bills, session, members, committee, patrons, nav, login, votes, collection, report, personnel } = state;
        return {
            bills,
            session,
            members,
            committee,
            patrons,
            nav,
            login,
            votes,
            collection,
            report,
            personnel
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, committeeActionCreators, billActionCreators, sessionActionCreators, memberActionCreators, patronActionCreators, voteActionCreators, collectionActionCreators, reportActionCreators, personnelActionCreators), dispatch)
        }
    }
)(ReportMaker);