import React from 'react';
import '../../../stylesheets/lis-public-view/public-view.css';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import renderHtml from 'react-render-html';

const tz = moment.tz.guess();

const TIME_REGEX = /[0-9]:[0-9]{2}\s[AP]M/i;

class Schedule extends React.Component {

    render() {
        return (
            <div>
                <h3 className="homepage-header">Upcoming Schedule{tz !== "America/New_York" && <small>*</small>}</h3>
                <hr />
                {!this.props.isLoaded ?
                    <div className="center-spinner spinner small-height" />
                    :
                    <>
                        {this.props.schedule.length === 0 &&
                            <div className="center" style={{ fontFamily: "Lato" }}>
                                <span>Nothing Scheduled</span>
                            </div>
                        }
                        <div style={{ maxHeight: '370px', overflowY: 'auto' }}>
                            {this.props.schedule.map((date, dateIndex) =>
                                <div className="homepage-schedule-item" key={dateIndex}>
                                    <div className="homepage-schedule-date">
                                        <span>{moment(date.Date).format('MMMM D, YYYY')}</span>
                                    </div>
                                    {date.Times.map((time, timeIndex) => {
                                        return <div>
                                            {time.Items.length > 0 &&
                                                <React.Fragment>
                                                    <ul className="small-text" key={timeIndex}>
                                                        {time.Items.map((item, itemIndex) =>
                                                            <li key={itemIndex}>
                                                                <span className="schedule-time">{TIME_REGEX.test(item.ScheduleTime) && item.ScheduleTime}</span>
                                                                <div className='schedule-info'>
                                                                    <div>
                                                                        <span className="schedule-owner">{item.OwnerName} {item.IsCancelled && <span className="fail"> CANCELLED</span>}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style={{ whiteSpace: 'pre-wrap' }}>{!TIME_REGEX.test(item.ScheduleTime) && item.ScheduleTime}</span>
                                                                        <span style={{ whiteSpace: 'pre-wrap' }}>{item.Description && renderHtml(item.Description)}</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </React.Fragment>
                                            }
                                        </div>
                                    }
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="homepage-links">
                            <Link to="/schedule">Full Schedule</Link>
                        </div>
                        {tz !== "America/New_York" && <div><small><em>*Times are displayed in your local timezone ({moment().tz(tz).format("zz")})</em></small></div>}
                    </>
                }
            </div>
        );
    }
}

export default Schedule;
