import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { voteActionCreators } from '../../../../stores/lis-votes-store';
import Select from 'react-select'
import { calendarActionCreators } from "../../../../stores/lis-calendar-store";
import { billActionCreators } from "../../../../stores/lis-legislation-store";
import InputTypes from "../input-types";
import { LargeListSelect } from '../../../../lis-shared/lis-layout/components/lis-forms-component';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CommentBox from './comment-box';
import { minutesActionCreators } from "../../../../stores/lis-minutes-store";
import { Link } from 'react-router-dom';
import moment from "moment";

const getItemStyle = (isDragging, draggableStyle, pendingDeletion) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? '#34495e' : 'white',
    color: isDragging ? 'white' : '#34495e',
    border: pendingDeletion ? 'red' : 'none',
    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#8b9fb3' : '#666666',
    width: '100%',
});

class LegislativeForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedMotion: '',
            insertEntry: [],
            newEntries: [],
            references: [],
            nonLegislativeActionReferences: [],
            fullEventList: [],
            votesToBeSaved: [],
            savingVotes: [],
            togglingEdit: {},
            templateInputs: [],
            templatedEdited: false,
            smallMotionForm: -1,
            textList: [],
            reconsiderationEvents: [],
            showMenu: -1,
            selectedBillNumber: [],
            showCommentBox: false,
            commentBoxContent: [],
            showAuxBillForm: false,
            auxBill: '',
            savingAuxBill: false,
            savingNonLegAction: false,
            checkedBills: [],
            editingActivityDescription: {},
            otherBillEventOptions: [],
            otherEvent: "",
            otherEventSelected: false
        };
        this.closeNewEntries = this.closeNewEntries.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.insertMotion = this.insertMotion.bind(this);
        this.removeMotion = this.removeMotion.bind(this);
        this.unSelectOtherEvent = this.unSelectOtherEvent.bind(this);
        this.handleOtherEventChange = this.handleOtherEventChange.bind(this);
        this.getOtherBillEventOptions = this.getOtherBillEventOptions.bind(this);
        this.filterBillEventReferences = this.filterBillEventReferences.bind(this);
        this.handleBillChange = this.handleBillChange.bind(this);
        this.handleMotionChange = this.handleMotionChange.bind(this);
        this.handleSelectedMotionDescriptionChange = this.handleSelectedMotionDescriptionChange.bind(this);
        this.handleTemplateChange = this.handleTemplateChange.bind(this);
        this.onActivityDragEnd = this.onActivityDragEnd.bind(this);
        this.onEntryDragEnd = this.onEntryDragEnd.bind(this);
        this.removeActivity = this.removeActivity.bind(this);
        this.addBackActivity = this.addBackActivity.bind(this);
        this.addAllEntries = this.addAllEntries.bind(this);
        this.getCatActionList = this.getCatActionList.bind(this);
        this.getNonLegislativeActionList = this.getNonLegislativeActionList.bind(this);
        this.getTextList = this.getTextList.bind(this);
        this.toggleSmallMotionForm = this.toggleSmallMotionForm.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.addEntry = this.addEntry.bind(this);
        this.handleBillNumberChange = this.handleBillNumberChange.bind(this);
        this.applyLegislationToEntry = this.applyLegislationToEntry.bind(this);
        this.handleCommentBoxChange = this.handleCommentBoxChange.bind(this);
        this.toggleCommentBox = this.toggleCommentBox.bind(this);
        this.saveComment = this.saveComment.bind(this);
        this.toggleShowDeleted = this.toggleShowDeleted.bind(this);
        this.toggleAuxBillAdd = this.toggleAuxBillAdd.bind(this);
        this.setAuxBill = this.setAuxBill.bind(this);
        this.addAuxBill = this.addAuxBill.bind(this);
        this.toggleSwitchCategory = this.toggleSwitchCategory.bind(this);
        this.handleSwitchCategoryChange = this.handleSwitchCategoryChange.bind(this);
        this.switchCategory = this.switchCategory.bind(this);
        this.toggleSelectEntry = this.toggleSelectEntry.bind(this);
        this.getReconsiderationEvents = this.getReconsiderationEvents.bind(this);
        this.toggleEditActivityDescription = this.toggleEditActivityDescription.bind(this);
        this.unSelectOtherEventEdit = this.unSelectOtherEventEdit.bind(this);
        this.handleOtherActivityDescriptionChange = this.handleOtherActivityDescriptionChange.bind(this);

        this.menuBoxRef = React.createRef();
        this.motionRef = React.createRef();
        this.motionDropdownRef = React.createRef();
    }

    closeNewEntries() {
        this.setState({
            newEntries: [],
            smallMotionForm: -1
        });
    }

    saveChange(entryIndex) {
        let entry = this.state.newEntries[entryIndex];
        //If this entry has a vote id then the vote description needs to be saved. So put a button on the form for the user to be able to do that
        if (entry.MinutesActivities && entry.MinutesActivities.find(activity => activity.VoteID)) {
            let votesToBeSaved = this.state.votesToBeSaved;
            votesToBeSaved[entryIndex] = true;
            this.setState({
                votesToBeSaved: votesToBeSaved
            });
        }

        let activitiesToBeSaved = [];

        //Overwrite any activities with their new OtherEvent if present
        entry.MinutesActivities.forEach((activity, activityIndex) => {
            if (activity.OtherEvent) {
                activity.DeletionDate = moment();

                let otherEvent = {...activity.OtherEvent}
                otherEvent.MinutesEntryID = entry.MinutesEntryID;
                otherEvent.InPreview = true;
                otherEvent.IsPublic = false;
                otherEvent.IsPassed = true;

                activitiesToBeSaved.push(activity);
                activitiesToBeSaved.push(otherEvent);
            } else {
                activitiesToBeSaved.push(activity)
            }
        })

        entry.MinutesActivities = activitiesToBeSaved;

        //save any changes to vote tallies
        let promises = [];
        entry.MinutesActivities.forEach((activity, activityIndex) => {
            if (activity.VoteTally !== undefined && !activity.ActivityReferences.find(ar => ar.ActionReferenceType !== "Text")) {
                promises.push(new Promise(res => {
                    this.handleActivityDescriptionChange(null, "VoteTally", entryIndex, activityIndex, true);
                    res();
                }))
            }
        })
        Promise.all(promises).then(() => {
            //Send the index of the minute entry, the entire minute entries object (so we don't have to save the entire book),
            //the value of the text input, and the sequence to show which category to update
            this.props.onEntryChange(entryIndex, entry, this.props.currentActivitySequence);
            this.props.handleSaveEntry(entry);
            //Turn it back into a span
            let newEntries = [...this.state.newEntries];
            newEntries[entryIndex] = null;
            let editingActivityDescription = { ...this.state.editingActivityDescription };
            editingActivityDescription[entryIndex] = {};
            this.setState({
                newEntries: newEntries,
                editingActivityDescription
            });
        });
    }

    handleChange(entryIndex, activityIndex, referenceIndex, reference, _, newMotion) {
        //Update the input with new text. Save change to this components state so it doesn't update the parent just yet (in case the user clicks the X)
        let entries = this.state.newEntries;
        //activities are reversed in display, so reverse here in order to get the indexes aligned
        let reversedActivities = entries[entryIndex].MinutesActivities.reverse();
        let activity = reversedActivities[activityIndex];

        if (newMotion && newMotion.value !== "Other") {
            let engrossingActions = ["S4600", "S4601", "S4602", "S4603", "S4604", "S4605", "S4606"];

            //Engrossing actions only ever need to say "Engrossed" instead of "Engrossed by Senate", etc.
            activity.Description = engrossingActions.includes(newMotion.EventCode)
                ? newMotion.ActionReferences[0].ReferenceText
                : newMotion.Description || newMotion.CalendarDescription

            activity.ActivityReferences = newMotion.ActionReferences;
            activity.EventCode = newMotion.EventCode;
            activity.CalendarActionID = newMotion.CalendarActionID;
            activity.CalendarCategoryTypeID = newMotion.CalendarCategoryTypeID;

            if (!activity.ActivityReferences?.length) {
                const textType = this.props.actionTypes.find(type => type.ActionReferenceType === 'Text');
                if (textType) {
                    //Sometimes ActivityReferences is undefined. Make a blank one so the push does not fail
                    activity.ActivityReferences = [];
                    activity.ActivityReferences.push({
                        ActionReferenceTypeID: textType.ActionReferenceTypeID,
                        ActionReferenceType: textType.ActionReferenceType,
                        ReferenceText: activity.Description
                    });
                }
            }
        } else if (newMotion && newMotion.value === "Other") {
            if (this.state.otherBillEventOptions.length === 0) {
                this.getOtherBillEventOptions();
            }

            activity.OtherMotionSelected = !activity.OtherMotionSelected ? true : false;

            reversedActivities[activityIndex] = { ...activity };
            const unreversedActivities = reversedActivities.reverse();
            entries[entryIndex].MinutesActivities = unreversedActivities;

            this.setState({
                newEntries: entries
            });

            return;
        } else {
            activity.ActivityReferences[referenceIndex] = reference;
            const memberListEvents = ["H6013", "S6013", "H6113", "S6113", "H6213", "S6213"];
            activity.Description = activity.ActivityReferences.map((tInput, i) => {
                //For events H6013/S6013/H6113/S6113/H6213/S6213 there are sometimes incomplete lists of members. This will prevent the UI from adding blank member dropdown values to the description
                if (memberListEvents.includes(activity.EventCode) && !tInput.ReferenceText) {
                    return;
                }

                if (!tInput.ReferenceText && !activity.ReconsiderationGrammar) {
                    return `${tInput.ActionReferenceType}`
                }
                return activity.ActivityReferences.find(x => x.ActionReferenceType === "LegislationEvent") ? this.state.fullEventList.find(e => e.EventCode === activity.ActivityReferences?.find(x => x.ActionReferenceType === "LegislationEvent")?.EventCode && e.IsPassed === activity.ActivityReferences?.find(x => x.ActionReferenceType === "LegislationEvent")?.IsPassed)?.ReconsiderationDescription : tInput.ReferenceText + (memberListEvents.includes(activity.EventCode) && tInput.ActionReferenceType === "Member" && tInput.ReferenceText && activity.ActivityReferences[i + 1] && activity.ActivityReferences[i + 1].ActionReferenceType === "Member" && activity.ActivityReferences[i + 1].ReferenceText ? ", " : "")
            }).join('');
        }
        activity.IsPublic = false;
        //reverse back to original
        const unreversedActivities = reversedActivities.reverse();
        entries[entryIndex].MinutesActivities = unreversedActivities;

        this.setState({
            newEntries: entries
        });
    }

    handleOtherChange(entryIndex, activityIndex, referenceIndex, reference, _, newMotion) {
        //Update the input with new text. Save change to this components state so it doesn't update the parent just yet (in case the user clicks the X)
        let entries = this.state.newEntries;
        //activities are reversed in display, so reverse here in order to get the indexes aligned
        let reversedActivities = entries[entryIndex].MinutesActivities.reverse();
        let activity = reversedActivities[activityIndex];
        activity.OtherEvent = {};

        if (newMotion) {
            let engrossingActions = ["S4600", "S4601", "S4602", "S4603", "S4604", "S4605", "S4606"];

            //Engrossing actions only ever need to say "Engrossed" instead of "Engrossed by Senate", etc.
            activity.OtherEvent.Description = engrossingActions.includes(newMotion.EventCode)
                ? newMotion.ActionReferences[0].ReferenceText
                : newMotion.Description || newMotion.CalendarDescription

            activity.OtherEvent.ActivityReferences = newMotion.ActionReferences;
            activity.OtherEvent.EventCode = newMotion.EventCode;
            activity.OtherEvent.CalendarActionID = newMotion.CalendarActionID;
            activity.OtherEvent.CalendarCategoryTypeID = newMotion.CalendarCategoryTypeID;

            if (!activity.OtherEvent.ActivityReferences?.length) {
                const textType = this.props.actionTypes.find(type => type.ActionReferenceType === 'Text');
                if (textType) {
                    //Sometimes ActivityReferences is undefined. Make a blank one so the push does not fail
                    activity.OtherEvent.ActivityReferences = [];
                    activity.OtherEvent.ActivityReferences.push({
                        ActionReferenceTypeID: textType.ActionReferenceTypeID,
                        ActionReferenceType: textType.ActionReferenceType,
                        ReferenceText: activity.OtherEvent.Description
                    });
                }
            }
        } else {
            activity.OtherEvent.ActivityReferences[referenceIndex] = reference;
            const memberListEvents = ["H6013", "S6013", "H6113", "S6113", "H6213", "S6213"];
            activity.OtherEvent.Description = activity.OtherEvent.ActivityReferences.map((tInput, i) => {
                //For events H6013/S6013/H6113/S6113/H6213/S6213 there are sometimes incomplete lists of members. This will prevent the UI from adding blank member dropdown values to the description
                if (memberListEvents.includes(activity.OtherEvent.EventCode) && !tInput.ReferenceText) {
                    return;
                }

                if (!tInput.ReferenceText && !activity.OtherEvent.ReconsiderationGrammar) {
                    return `${tInput.ActionReferenceType}`
                }
                return activity.OtherEvent.ActivityReferences.find(x => x.ActionReferenceType === "LegislationEvent") ? this.state.fullEventList.find(e => e.EventCode === activity.OtherEvent.ActivityReferences?.find(x => x.ActionReferenceType === "LegislationEvent")?.EventCode && e.IsPassed === activity.OtherEvent.ActivityReferences?.find(x => x.ActionReferenceType === "LegislationEvent")?.IsPassed)?.ReconsiderationDescription : tInput.ReferenceText + (memberListEvents.includes(activity.OtherEvent.EventCode) && tInput.ActionReferenceType === "Member" && tInput.ReferenceText && activity.OtherEvent.ActivityReferences[i + 1] && activity.OtherEvent.ActivityReferences[i + 1].ActionReferenceType === "Member" && activity.OtherEvent.ActivityReferences[i + 1].ReferenceText ? ", " : "")
            }).join('');
        }
        activity.OtherEvent.IsPublic = false;
        reversedActivities[activityIndex] = { ...activity };
        //reverse back to original
        const unreversedActivities = reversedActivities.reverse();
        entries[entryIndex].MinutesActivities = unreversedActivities;

        this.setState({
            newEntries: entries
        });
    }

    toggleOOB(entryIndex) {
        // IsOOB key is used to tell whether a piece of legislation is out of block
        let entry = this.props.formData.MinutesEntries[entryIndex];
        entry.IsOOB = !entry.IsOOB;
        this.props.onEntryChange(entryIndex, entry, this.props.currentActivitySequence);
        this.props.handleSaveEntry(entry);
        this.setState({
            showMenu: -1
        });
    }

    toggleSwitchCategory(entryIndex) {
        this.setState({
            showSwitchCategory: !this.state.showSwitchCategory
        }, () => {
            if (this.state.showSwitchCategory) {
                const dropdownOptions = this.props.categories.filter(c => c.CalendarCategoryTypeID === this.props.formData.CalendarCategoryTypeID && c.MinutesCategoryID !== this.props.formData.MinutesCategoryID);
                if (dropdownOptions.length === 1) {
                    this.handleSwitchCategoryChange(dropdownOptions[0]);
                }
                this.toggleSelectEntry(entryIndex);
                this.toggleMenu(-1);
            } else {
                this.setState({ checkedBills: [] })
                this.handleSwitchCategoryChange(null);
            }
        });
    }

    handleSwitchCategoryChange(val) {
        this.setState({
            selectedSwitchCategory: val
        });
    }

    switchCategory() {
        if (this.state.checkedBills.length && this.state.selectedSwitchCategory) {
            this.setState({ switchingCategories: true }, () => {
                const origSequence = this.props.currentActivitySequence;
                let entries = [...this.props.formData.MinutesEntries].filter((e, i) => this.state.checkedBills[i]);
                this.props.bulkMoveEntry(entries, origSequence - 1, this.state.selectedSwitchCategory.MinutesCategoryID);
                this.setState({ checkedBills: [], showSwitchCategory: false, selectedSwitchCategory: null, switchingCategories: false })
            })
        }
    }

    toggleSelectEntry(billIndex, e) {
        let checkedBills = [...this.state.checkedBills];
        let lastCheckedBill = this.state.lastCheckedBill;
        if (e && e.nativeEvent.shiftKey && this.state.lastCheckedBill !== -1) {
            checkedBills = checkedBills.fill(false);
            for (let i = 0; i < Math.abs(billIndex - this.state.lastCheckedBill); i++) {
                const index = this.state.lastCheckedBill + i * (billIndex - this.state.lastCheckedBill) / Math.abs(billIndex - this.state.lastCheckedBill);
                checkedBills[index] = true;
            }
        } else {
            lastCheckedBill = !checkedBills[billIndex] ? billIndex : this.state.lastCheckedBill;
        }
        checkedBills[billIndex] = !checkedBills[billIndex]
        this.setState({
            checkedBills: checkedBills,
            lastCheckedBill: lastCheckedBill
        });
    }

    getOtherBillEventOptions() {
        this.props.actions.getBillEvents("").then(() => {
            let billEventReferences = [...this.props.bills.billEvents];
            billEventReferences.forEach(ref => {
                ref.label = ref.LegislationDescription || ref.CalendarDescription;
                ref.value = `${ref.EventCode}-${ref.IsPassed}`
            });
            billEventReferences = this.filterBillEventReferences(billEventReferences);
            this.setState({
                otherBillEventOptions: [...billEventReferences.filter(x => {
                    return x.EventCode[0] !== "H"
                })]
            });
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            console.log(err)
        });
    }

    unSelectOtherEvent() {
        this.setState({
            otherEventSelected: false,
            templateInputs: [],
            otherEvent: ""
        })
    }

    unSelectOtherEventEdit(entryIndex, activityIndex) {
        let newEntries = [...this.state.newEntries];
        newEntries[entryIndex].MinutesActivities[activityIndex].OtherMotionSelected = false;
        delete newEntries[entryIndex].MinutesActivities[activityIndex].OtherEvent;

        this.setState({
            newEntries: newEntries
        })
    }

    handleOtherEventChange(value) {
        let templateInputs = [];
        if (value.ActionReferences && value.ActionReferences.length > 0) {
            templateInputs = JSON.parse(JSON.stringify(value.ActionReferences));
        }
        this.setState({
            otherEvent: value,
            templateInputs: templateInputs,
            templateEdited: false
        });
    }

    filterBillEventReferences(billEventReferences) {
        return billEventReferences.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.EventCode === value.EventCode && JSON.stringify(t.ActionReferences) === JSON.stringify(value.ActionReferences) && t.IsPassed === value.IsPassed
            ))
        )
    }

    handleBillChange(value) {
        //Set the dropdown's value
        value = value || []
        this.setState({
            insertEntry: value
        }, () => {
            if (this.motionRef?.current)
                this.motionRef.current.focus();
        });
        if (value.length === 0) {
            this.setState({
                selectedMotion: '',
                templateInputs: [],
                templatedEdited: false
            })
        }
    }

    handleMotionChange(value) {
        if (value && value.value !== "Other") {
            this.setState({
                selectedMotion: value,
                templateInputs: value ? JSON.parse(JSON.stringify(value.ActionReferences)) : [],
                templatedEdited: false
            }, () => {
                //If the user has selected an event with at least one ActionReferenceType of LegislationEvent then fetch possible events for reconsideration
                if (this.state.selectedMotion?.ActionReferences && this.state.selectedMotion?.ActionReferences?.find(x => x.ActionReferenceType === "LegislationEvent")) {
                    this.state.insertEntry.forEach(bill => {
                        this.getReconsiderationEvents(this.state.selectedMotion.EventCode, bill);
                    })
                }
            });
        } else {
            this.setState({
                selectedMotion: value,
                otherEventSelected: value ? true : false
            })
            if (this.state.otherBillEventOptions.length === 0) {
                this.getOtherBillEventOptions();
            }
        }
    }

    handleSelectedMotionDescriptionChange(e) {
        let selectedMotion = { ...this.state.selectedMotion };
        selectedMotion.Description = e.target.value;
        this.setState({
            selectedMotion
        });
    }

    insertMotion(openMotionEditor) {
        //A new motion wants to be added so take all this data and send it to the parent div to be added to the state
        const { insertEntry, templateInputs, templatedEdited } = this.state;
        let selectedMotion = !this.state.otherEventSelected ? { ... this.state.selectedMotion } : { ... this.state.otherEvent };
        let entries = [...this.props.formData.MinutesEntries];

        insertEntry.forEach(bill => {
            //Force all non legislative actions into their own entry by always setting the index to -1
            let index = bill.label && bill.label === "No Legislation" ? -1 : entries.findIndex(entry => entry.MinutesEntryID === bill.MinutesEntryID);
            let isNewEntry = false;
            if (index === -1 && bill.label && bill.label === 'No Legislation') {
                isNewEntry = true;
                let minutesEntry = {
                    IsPublic: false,
                    MinutesActivities: [],
                    MinutesCategoryID: this.props.formData.MinutesCategoryID,
                    Sequence: entries.length ? Math.max(...entries.map(e => e.Sequence || 0)) + 1 : 1,
                }
                entries.push(minutesEntry);
                index = entries.length - 1;
            }
            if (index !== -1) {
                let entry = entries[index];
                //If it is a null then create a new one so the push doesn't fail
                if (!entry.MinutesActivities) {
                    entry.MinutesActivities = [];
                }
                let desc = selectedMotion.Description || selectedMotion.CalendarDescription;
                //If the user has changed the value of the action references then use those for the description instead of the existing one.
                let activityRefs = [];
                if (templatedEdited || (templateInputs.length && !templateInputs.find(tInput => !["Text", "HTML"].includes(tInput.ActionReferenceType)))) {
                    desc = '';
                    const memberListEvents = ["H6013", "S6013", "H6113", "S6113", "H6213", "S6213"];
                    templateInputs.forEach((tInput, actIndex) => {
                        if (memberListEvents.includes(selectedMotion.EventCode) && !tInput.ReferenceText) {
                            return;
                        }
                        if (!tInput.ReferenceText && !selectedMotion.ReconsiderationGrammar) {
                            return `${tInput.ActionReferenceType}`
                        }
                        desc += templateInputs.find(x => x.ActionReferenceType === "LegislationEvent") ? this.state.fullEventList.find(e => e.EventCode === templateInputs.find(x => x.ActionReferenceType === "LegislationEvent")?.EventCode && e.IsPassed === templateInputs.find(x => x.ActionReferenceType === "LegislationEvent")?.IsPassed)?.ReconsiderationDescription : tInput.ReferenceText + (memberListEvents.includes(selectedMotion.EventCode) && tInput.ActionReferenceType === "Member" && tInput.ReferenceText && templateInputs[actIndex + 1] && templateInputs[actIndex + 1].ActionReferenceType === "Member" && templateInputs[actIndex + 1].ReferenceText ? ", " : "");
                        activityRefs[actIndex] = {
                            ActionReferenceTypeID: tInput.ActionReferenceTypeID,
                            ActionReferenceType: tInput.ActionReferenceType,
                            Sequence: tInput.Sequence,
                            ReferenceText: tInput.ReferenceText,
                            ReferenceID: tInput.ReferenceID
                        }
                    });

                } else {
                    const textType = this.props.actionTypes.find(type => type.ActionReferenceType === 'Text');
                    if (textType) {
                        activityRefs = [{
                            ActionReferenceTypeID: textType.ActionReferenceTypeID,
                            ActionReferenceType: textType.ActionReferenceType,
                            ReferenceText: selectedMotion.Description || selectedMotion.CalendarDescription
                        }];
                    }
                }
                const newActivity = Object.assign(selectedMotion, {
                    MinutesEntryID: entry.MinutesEntryID,
                    ActivityReferences: activityRefs,
                    Description: desc,
                    Sequence: entry.MinutesActivities.length ? Math.max(...entry.MinutesActivities.map(m => m.Sequence || 0)) + 1 : 1
                })
                entry.MinutesActivities.push(newActivity);
                if (isNewEntry) {
                    //now that this entry is actually going to be in the saved object, remove the temp obj, otherwise it will duplicate (in the UI only, but still not what we want)
                    entries.splice(index, 1);
                } else { //if it's not a new entry, call the onEntryChange prop
                    this.props.onEntryChange(index, entry, this.props.currentActivitySequence);
                }
                this.props.handleSaveEntry(entry, () => {
                    if (openMotionEditor === true) {
                        this.toggleEdit(index)
                    }
                });
            }
        });
        this.setState({
            insertEntry: [],
            selectedMotion: '',
            smallMotionForm: -1,
            showMenu: -1,
            showSwitchCategory: false,
            selectedSwitchCategory: null,
            templateInputs: [],
            otherEvent: '',
            otherEventSelected: false
        });
    }

    removeMotion(e) {
        e.preventDefault();
        //A motion wants to be removed so check each minute entry and remove the motion if it has it
        const { insertEntry, selectedMotion } = this.state;
        const entries = this.props.formData.MinutesEntries;
        insertEntry.forEach(bill => {
            let needToSave = false;
            const index = entries.findIndex(entry => entry.MinutesEntryID === bill.MinutesEntryID);
            if (index !== -1) {
                let entry = JSON.parse(JSON.stringify(entries[index]));
                if (!entry.MinutesActivities) {
                    return;
                }
                entry.MinutesActivities.forEach(activity => {
                    if (selectedMotion.CalendarActionID === activity.CalendarActionID && !activity.DeletionDate) {
                        activity.DeletionDate = new Date();
                        needToSave = true;
                    }
                });
                if (needToSave) {
                    //User cannot remove an entry's activity while they are editing that entry
                    if (this.state.newEntries[index]) {
                        let newEntries = [...this.state.newEntries];
                        newEntries[index] = null;
                        this.setState({
                            newEntries: newEntries
                        });
                    }
                    this.props.onEntryChange(index, entry, this.props.currentActivitySequence);
                    this.props.handleSaveEntry(entry);
                }
            }
        });
        this.setState({
            insertEntry: [],
            selectedMotion: '',
            templateInputs: [],
            templatedEdited: false,
        });
    }

    toggleEdit(index) {
        let togglingEdit = { ...this.state.togglingEdit };
        togglingEdit[index] = true;
        this.setState({ togglingEdit }, () => {
            let newEntries = [...this.state.newEntries];
            let init = [];
            let promises = [];
            if (this.state.newEntries[index]) {
                newEntries[index] = null;
            } else {
                if (this.state.newEntries.length === 0) {
                    newEntries.length = this.props.formData.MinutesEntries.length;
                }
                //If the user has multiple edit entries open clicking the X on one doesn't kill the changes to another
                newEntries[index] = JSON.parse(JSON.stringify(this.props.formData.MinutesEntries[index]));

                //Test to see if the activities have zero activity references. If they have zero then add a blank text one
                const references = newEntries[index].LegislationNumber ? [...this.state.references] : [...this.state.nonLegislativeActionReferences]
                newEntries[index].MinutesActivities.forEach(act => {
                    if (act.EventCode) {
                        const eventReference = references.find(evt => evt.EventCode === act.EventCode && evt.IsPassed === act.IsPassed);
                        if (eventReference) {
                            const actionReferences = eventReference.ActionReferences;
                            if (actionReferences) {
                                const missingActivitiyReferences = actionReferences.filter(ref => !act.ActivityReferences || !act.ActivityReferences.map(existingActivities => existingActivities.ActionReferenceType).includes(ref.ActionReferenceType));
                                if (missingActivitiyReferences.length) {
                                    act.ActivityReferences = act.ActivityReferences ? act.ActivityReferences.concat(JSON.parse(JSON.stringify(missingActivitiyReferences))) : JSON.parse(JSON.stringify(missingActivitiyReferences));
                                }
                            }
                        }
                    }

                    if (!act.ActivityReferences || act.ActivityReferences.length === 0) {
                        const textType = this.props.actionTypes.find(type => type.ActionReferenceType === 'Text');
                        if (textType) {
                            //Sometimes ActivityReferences is undefined. Make a blank one so the push does not fail
                            act.ActivityReferences = [];
                            act.ActivityReferences.push({
                                ActionReferenceTypeID: textType.ActionReferenceTypeID,
                                ActionReferenceType: textType.ActionReferenceType,
                                ReferenceText: act.Description
                            });
                        }
                    }

                    //If the user has selected an event with at least one ActionReferenceType of LegislationEvent then fetch possible events for reconsideration
                    const eventReferenceIndex = act.ActivityReferences?.findIndex(x => x.ActionReferenceType === "LegislationEvent");
                    if (eventReferenceIndex > -1) {
                        promises.push(
                            new Promise(res => {
                                this.getReconsiderationEvents(act.EventCode, newEntries[index], (reconsiderationEvents) => {
                                    if (act.ActivityReferences[eventReferenceIndex].ReferenceID) {
                                        act.ActivityReferences[eventReferenceIndex].EventCode = act.ActivityReferences[eventReferenceIndex].EventCode || reconsiderationEvents.find(e => e.LegislationEventID === act.ActivityReferences[eventReferenceIndex].ReferenceID)?.EventCode;
                                        act.ActivityReferences[eventReferenceIndex].IsPassed = act.ActivityReferences[eventReferenceIndex].IsPassed || reconsiderationEvents.find(e => e.LegislationEventID === act.ActivityReferences[eventReferenceIndex].ReferenceID)?.IsPassed;
                                    }
                                    res();
                                })
                            }));
                    }
                });
            }
            Promise.all(promises).then(() => {
                togglingEdit[index] = false;
                let editingActivityDescription = { ...this.state.editingActivityDescription };
                editingActivityDescription[index] = {};
                this.setState({
                    newEntries: newEntries,
                    togglingEdit,
                    editingActivityDescription
                }, () => {
                    if (this.state.newEntries[index]) {
                        newEntries[index].MinutesActivities.forEach((activity, activityIndex) => {
                            if (activity.VoteTally !== undefined)
                                this.handleActivityDescriptionChange(null, "VoteTally", index, activityIndex)
                        })
                    }
                });
            });
        });
    }

    getReconsiderationEvents(params, bill, cb) {
        params = '?ReconsiderationAction=' + params + '&LegislationID=' + bill.LegislationID;

        this.props.actions.getLegislationEventsByLegislationId(params)
            .then(() => {
                this.setState({
                    reconsiderationEvents: this.props.bills.legislationEventsById
                }, () => {
                    if (cb) cb([...this.state.reconsiderationEvents]);
                });
            })
    }

    toggleEditActivityDescription(entryIndex, activityIndex) {
        let editingActivityDescription = { ...this.state.editingActivityDescription };
        if (!editingActivityDescription[entryIndex]) {
            editingActivityDescription[entryIndex] = {};
        }
        editingActivityDescription[entryIndex][activityIndex] = !editingActivityDescription[entryIndex][activityIndex];
        this.setState({ editingActivityDescription }, () => {
            this.handleActivityDescriptionChange(null, "Description", entryIndex, activityIndex)
            this.handleActivityDescriptionChange(null, "VoteTally", entryIndex, activityIndex)
        });
    }

    handleActivityDescriptionChange(value, attr, entryIndex, activityIndex, save) {
        //Update the input with new text. Save change to this components state so it doesn't update the parent just yet (in case the user clicks the X)
        let entries = this.state.newEntries;
        //activities are reversed in display, so reverse here in order to get the indexes aligned
        let reversedActivities = entries[entryIndex].MinutesActivities.reverse();
        let activity = reversedActivities[activityIndex];

        if (save) {
            activity.Description = activity.tempDescription ?? activity.Description;
            activity.VoteTally = activity.tempVoteTally ?? activity.VoteTally;
        } else {
            activity['temp' + attr] = value ?? activity[attr];
        }

        //reverse back to original
        let unreversedActivities = reversedActivities.reverse();
        entries[entryIndex].MinutesActivities = unreversedActivities;

        this.setState({ entries }, () => {
            if (save) this.toggleEditActivityDescription(entryIndex, activityIndex);
        });
    }

    handleOtherActivityDescriptionChange(value, attr, entryIndex, activityIndex, save) {
        //Update the input with new text. Save change to this components state so it doesn't update the parent just yet (in case the user clicks the X)
        let entries = this.state.newEntries;
        //activities are reversed in display, so reverse here in order to get the indexes aligned
        let reversedActivities = entries[entryIndex].MinutesActivities.reverse();
        let activity = reversedActivities[activityIndex].OtherEvent;

        if (save) {
            activity.Description = activity.tempDescription ?? activity.Description;
            activity.VoteTally = activity.tempVoteTally ?? activity.VoteTally;
        } else {
            activity['temp' + attr] = value ?? activity[attr];
        }

        //reverse back to original
        let unreversedActivities = reversedActivities.reverse();
        entries[entryIndex].MinutesActivities = unreversedActivities;

        this.setState({ entries }, () => {
            if (save) this.toggleEditActivityDescription(entryIndex, activityIndex);
        });
    }

    onActivityDragEnd(result, entryIndex) {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        let entries = [...this.state.newEntries];
        //activities are reversed in display, so reverse here in order to get the indexes aligned
        let reversedActivities = entries[entryIndex].MinutesActivities.reverse();
        const [removed] = reversedActivities.splice(result.source.index, 1);
        reversedActivities.splice(result.destination.index, 0, removed);
        //reverse back to original
        let unreversedActivities = reversedActivities.reverse();
        unreversedActivities.forEach((element, index) => {
            element.Sequence = index + 1
        });
        entries[entryIndex].MinutesActivities = unreversedActivities;
        this.setState({
            newEntries: entries
        });
    }

    onEntryDragEnd(result) {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        let entries = [...this.props.formData.MinutesEntries];
        const [removed] = entries.splice(result.source.index, 1);
        entries.splice(result.destination.index, 0, removed);
        entries.forEach((element, index) => {
            element.Sequence = index + 1;
        });
        this.props.onMultipleEntryChange(entries, this.props.currentActivitySequence);
        this.props.handleSaveEntry(entries);
        // 
        this.setState({
            newEntries: []
        })
    }

    removeActivity(entryIndex, activityIndex) {
        let entries = [...this.state.newEntries];
        //activities are reversed in display, so reverse here in order to get the indexes aligned
        let reversedActivities = entries[entryIndex].MinutesActivities.reverse()
        reversedActivities[activityIndex].DeletionDate = new Date();
        //reverse back to original
        const unreversedActivities = reversedActivities.reverse();
        entries[entryIndex].MinutesActivities = unreversedActivities;
        this.setState({
            newEntries: entries
        });
    }

    addBackActivity(entryIndex, activityIndex) {
        let entries = [...this.state.newEntries];
        //activities are reversed in display, so reverse here in order to get the indexes aligned
        let reversedActivities = entries[entryIndex].MinutesActivities.reverse()
        reversedActivities[activityIndex].DeletionDate = null;
        reversedActivities[activityIndex].IsPublic = false;
        //reverse back to original
        const unreversedActivities = reversedActivities.reverse();
        entries[entryIndex].MinutesActivities = unreversedActivities;
        this.setState({
            newEntries: entries
        });
    }

    addAllEntries(isOOB) {
        const entries = this.props.formData.MinutesEntries;
        // Filter out the ones that are already added so they aren't added twice
        let insertEntry = this.state.insertEntry.filter(entry => Boolean(entry.IsOOB) !== isOOB);
        let newInserEntries = insertEntry.concat(entries.filter(entry => Boolean(entry.IsOOB) === isOOB));
        this.setState({
            insertEntry: newInserEntries
        });
    }

    handleTemplateChange(_entryIndex, _activityIndex, referenceIndex, reference) {
        let templateInputs = [...this.state.templateInputs];
        templateInputs[referenceIndex] = reference;
        //Used when adding the motion to know whether to use the existing description or concatenate together the action reference values
        //If they have change a value it is save to say they want the the description to be the action references stringed together
        if (!this.state.templatedEdited) {
            this.setState({
                templatedEdited: true
            });
        }
        this.setState({
            templateInputs: templateInputs
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentActivitySequence !== this.props.currentActivitySequence) {
            this.setState({
                insertEntry: [],
                newEntries: [],
                selectedMotion: '',
                votesToBeSaved: [],
                savingVotes: [],
                togglingEdit: {},
                templateInputs: [],
                templatedEdited: false,
                smallMotionForm: -1,
                showMenu: -1,
                showSwitchCategory: false,
                selectedSwitchCategory: null,
                textList: [],
                checkedBills: []
            });
            this.getCatActionList();
            this.getTextList();
        } else if (prevProps.formData.MinutesEntries !== this.props.formData.MinutesEntries) {
            let newEntries = [...this.state.newEntries];
            this.props.formData.MinutesEntries.forEach((entry, entryIndex) => {
                if (newEntries[entryIndex] && entry !== newEntries[entryIndex]) {
                    newEntries[entryIndex] = entry;
                }
            });
            this.setState({
                newEntries: newEntries
            });
        }
    }

    componentDidMount() {
        this.getCatActionList();
        this.getNonLegislativeActionList();
        this.getTextList();
    }

    getCatActionList() {
        //Get the calendar action reference data
        const catId = this.props.formData.CalendarCategoryTypeID;
        const referenceParams = `?calendarCategoryTypeID=${catId}&chamberCode=S`;
        this.props.actions.getReferences(referenceParams)
            .then(() => {
                let references = this.props.calendar.references;
                references.forEach(reference => {
                    reference.label = `${reference.EventCode} ${reference.Description}`;
                    reference.value = `${reference.CalendarActionID}-${reference.IsPassed}`;
                });

                references.push({
                    label: "Other",
                    value: "Other"
                })

                this.setState({
                    references: references
                });
            });
    }

    getNonLegislativeActionList() {
        //Get the non legislative action reference data
        this.props.actions.getBillEventReferences("?isActive=true").then(() => {
            let nonLegislativeActionReferences = [...this.props.bills.billEventRef].filter(ref => ref.AdministrativeAction);
            nonLegislativeActionReferences.forEach(ref => {
                ref.label = `${ref.EventCode} ${ref.CalendarDescription}`
                ref.value = `${ref.EventCode}-${ref.IsPassed}`
            });

            this.setState({
                nonLegislativeActionReferences: nonLegislativeActionReferences,
                fullEventList: [...this.props.bills.billEventRef]
            });
        });
    }

    getTextList() {
        const entries = this.props.formData.MinutesEntries;
        entries.forEach(entry => {
            if (entry.LegislationID && !this.state.textList.find(text => text.LegislationID === entry.LegislationID)) {
                this.props.actions.getTextList('?legislationID=' + entry.LegislationID + '&sessionID=' + this.props.sessionId)
                    .then(() => {
                        let textList = [...this.props.bills.textList];
                        textList.forEach(text => {
                            text.label = text.DocumentCode;
                            text.value = text.LegislationTextID;
                        });
                        this.setState(state => ({
                            textList: state.textList.concat(textList)
                        }));
                    });
            }
        })
    }

    toggleSmallMotionForm(entryIndex, e) {
        if (e) {
            //Do not hide the small motion form if a child of the form is focused
            if (e.currentTarget.contains(e.relatedTarget)) {
                return
            }
        }
        let insertEntry = [];
        insertEntry = this.props.formData.MinutesEntries[entryIndex];
        this.setState({
            smallMotionForm: entryIndex,
            insertEntry: insertEntry ? [insertEntry] : [],
            selectedMotion: '',
            templateInputs: [],
            templatedEdited: false,
            otherEventSelected: false
        }, () => {
            if (this.motionDropdownRef.current) {
                this.motionDropdownRef.current.focus();
            }
        });
    }

    toggleMenu(entryIndex, e) {
        if (e) {
            //Do not hide the small motion form if a child of the form is focused
            if (e.currentTarget.contains(e.relatedTarget)) {
                return;
            }
        }
        this.setState({
            showMenu: entryIndex
        }, () => {
            if (this.menuBoxRef.current) {
                this.menuBoxRef.current.focus();
            }
        });
    }

    addEntry(entryIndex, position, recess) {
        let entries = [...this.props.formData.MinutesEntries];
        let newEntry = {
            IsOOB: entries[entryIndex].IsOOB,
            IsPublic: false,
            MinutesCategoryID: entries[entryIndex].MinutesCategoryID,
        }
        if (recess && this.state.references.find(ref => ref.Description === 'Recess')) {
            newEntry.EntryText = "Recess";
            newEntry.MinutesActivities = [{
                MinutesActivityID: this.state.references.find(ref => ref.Description === 'Recess').MinutesActivityID,
                Description: "Recess"
            }];
        }
        entries.splice(entryIndex + position, 0, newEntry);
        entries.forEach((entry, idx) => {
            entry.Sequence = idx + 1
        });
        this.props.handleSaveEntry(entries);
        this.setState({
            showMenu: -1,
            smallMotionForm: -1,
            insertEntry: [],
            newEntries: []
        });
    }

    handleBillNumberChange(entryIndex, value) {
        let selectedBillNumber = [...this.state.selectedBillNumber];
        selectedBillNumber[entryIndex] = value
        this.setState({
            selectedBillNumber: selectedBillNumber
        });
    }

    applyLegislationToEntry(entryIndex) {
        let entries = [...this.props.formData.MinutesEntries];
        let entry = entries[entryIndex];
        entry.LegislationNumber = this.state.selectedBillNumber[entryIndex].LegislationNumber;
        this.props.handleSaveEntry(entry);
        this.setState({
            showMenu: -1,
            smallMotionForm: -1
        });
        this.props.actions.getTextList('?legislationNumber=' + this.state.selectedBillNumber[entryIndex].LegislationNumber + '&sessionID=' + this.props.sessionId)
            .then(() => {
                let textList = [...this.props.bills.textList];
                textList.forEach(text => {
                    text.label = text.DocumentCode;
                    text.value = text.LegislationTextID;
                });
                this.setState(state => ({
                    textList: state.textList.concat(textList)
                }));
            });

    }

    handleCommentBoxChange(content) {
        this.setState({
            commentBoxContent: content
        });
    }

    toggleCommentBox(show) {
        const entries = this.props.formData.MinutesEntries;
        let commentBoxContent = [];
        if (entries[show] && entries[show].MinutesSummaries) {
            if (entries[show].MinutesSummaries.length !== 0) {
                commentBoxContent = entries[show].MinutesSummaries;
            }
        }
        this.setState({
            showCommentBox: show,
            commentBoxContent: commentBoxContent
        });
    }

    saveComment() {
        let entries = [...this.props.formData.MinutesEntries];
        if (entries[this.state.showCommentBox]) {
            let entry = entries[this.state.showCommentBox];
            entry.MinutesSummaries = this.state.commentBoxContent;
            this.props.handleSaveEntry(entry);
            this.setState({
                showCommentBox: false
            });
        }
    }

    toggleShowDeleted(entryIndex) {
        let entries = [...this.state.newEntries];
        entries[entryIndex].showDeleted = !entries[entryIndex].showDeleted;
        this.setState({
            newEntries: entries
        });
    }

    toggleAuxBillAdd() {
        this.setState({
            showAuxBillForm: !this.state.showAuxBillForm,
            auxBill: ''
        })
    }

    setAuxBill(value) {
        this.setState({
            auxBill: value
        })
    }

    addAuxBill() {
        this.setState({
            savingAuxBill: true
        }, () => {
            let minutesEntry = {
                MinutesEntryCreate: [
                    {
                        IsPublic: false,
                        LegislationChamberCode: this.state.auxBill.value[0],
                        LegislationID: this.state.auxBill.LegislationID,
                        LegislationNumber: this.state.auxBill.value,
                        MinutesActivities: [],
                        MinutesCategoryID: this.props.formData.MinutesEntries[0].MinutesCategoryID,
                        Sequence: this.props.formData.MinutesEntries.length ? Math.max(...this.props.formData.MinutesEntries.map(me => me.Sequence || 0)) + 1 : 1,
                    }
                ],
            }

            this.props.actions.createEntries(minutesEntry)
                .then(() => {
                    this.toggleAuxBillAdd();
                    this.setState({
                        savingAuxBill: false
                    })
                })
        })
    }

    onKeyUp(e) {
        if(e.key === "Enter" && !this.state.otherEventSelected) {
            this.insertMotion();
        } else if (e.key === "Enter" && this.state.otherEventSelected && this.state.otherEvent !== "") {
            this.insertMotion();
        }
    }

    formInput(entry, entryIndex, provided = {}) {
        const memberListEvents = ["H6013", "S6013", "H6113", "S6113", "H6213", "S6213"];

        let inputValue = this.state.templateInputs.map((tInput, i) => {
            //For events H6013/S6013/H6113/S6113/H6213/S6213 there are sometimes incomplete lists of members. This will prevent the UI from adding blank member dropdown values to the description
            if (memberListEvents.includes(this.state.selectedMotion?.EventCode) && !tInput.ReferenceText) {
                return;
            }

            if (!tInput.ReferenceText && !this.state.selectedMotion?.ReconsiderationGrammar) {
                return `${tInput.ActionReferenceType}`
            }
            return this.state.templateInputs.find(x => x.ActionReferenceType === "LegislationEvent") ? this.state.fullEventList.find(e => e.EventCode === this.state.templateInputs.find(x => x.ActionReferenceType === "LegislationEvent")?.EventCode && e.IsPassed === this.state.templateInputs.find(x => x.ActionReferenceType === "LegislationEvent")?.IsPassed)?.ReconsiderationDescription : tInput.ReferenceText + (memberListEvents.includes(this.state.selectedMotion?.EventCode) && tInput.ActionReferenceType === "Member" && tInput.ReferenceText && this.state.templateInputs[i + 1] && this.state.templateInputs[i + 1].ActionReferenceType === "Member" && this.state.templateInputs[i + 1].ReferenceText ? ", " : "")
        }).join('');
        const showDeletedItemsCheckbox = this.state.newEntries[entryIndex] ? !!this.state.newEntries[entryIndex].MinutesActivities.find(act => act.DeletionDate) : false
        let reversedActivities = this.state.newEntries[entryIndex] ? [...this.state.newEntries[entryIndex].MinutesActivities].reverse() : []
        // Sometimes the deleted items are shown and other times they are not. If they are not shown then the first activity might not be the first activity on the screen.
        // So this variable is used to figure out whether to show the hr or not that seperates activities.
        const firstActivityIndex = this.state.newEntries[entryIndex] ? reversedActivities.findIndex((act, actIndex) => {
            if (this.state.newEntries[entryIndex].showDeleted) {
                return true
            } else {
                return !act.DeletionDate;
            }
        }) : null

        function extractText(summary) {
            let doc = document.createElement('div')
            doc.insertAdjacentHTML("afterbegin", summary);
            //Bail out and pass back the summary if there is no paragraph tag
            return doc.getElementsByTagName("p")[0] ? doc.getElementsByTagName("p")[0].innerText : summary
        }

        return (
            <div key={entryIndex}>
                <div className="bill-grid">
                    <React.Fragment>
                        <div className={`${this.state.showSwitchCategory ? 'inner-grid half align-center' : ''}`} style={this.state.showSwitchCategory ? { gap: '10px', justifyContent: 'center' } : { textAlign: 'center' }}>
                            <span {...provided.dragHandleProps} className="icon menu" onClick={(e) => this.toggleMenu(entryIndex)}></span>
                            {this.state.showMenu === entryIndex &&
                                <div style={{ textAlign: 'left', marginLeft: '-15px', width: this.state.showSwitchCategory !== entryIndex ? 'auto' : '' }} className="info-box blue" ref={this.menuBoxRef} onBlur={(e) => this.toggleMenu(-1, e)} contentEditable suppressContentEditableWarning={true}>
                                    <div contentEditable={false}>
                                        <div>
                                            <button type="button" className="button-link" disabled={this.props.categoryFormDisabled} onClick={() => this.toggleOOB(entryIndex)}>Place {entry.IsOOB ? "In" : "Out of"} Block</button>
                                        </div>
                                        <div>
                                            <button type="button" disabled={this.props.categoryFormDisabled} onClick={() => this.addEntry(entryIndex, 0)} className="button-link">Add Entry Above</button>
                                        </div>
                                        <div>
                                            <button type="button" disabled={this.props.categoryFormDisabled} onClick={() => this.addEntry(entryIndex, 1)} className="button-link">Add Entry Below</button>
                                        </div>
                                        {this.state.references.find(ref => ref.Description === 'Recess') &&
                                            <div>
                                                <button type="button" disabled={this.props.categoryFormDisabled} onClick={() => this.addEntry(entryIndex, 1, true)} className="button-link">Add Recess Below</button>
                                            </div>
                                        }
                                        {this.props.categories.filter(c => c.CalendarCategoryTypeID === this.props.formData.CalendarCategoryTypeID && c.MinutesCategoryID !== this.props.formData.MinutesCategoryID).length ?
                                            <div>
                                                <button type="button" disabled={this.props.categoryFormDisabled} onClick={() => this.toggleSwitchCategory(entryIndex)} className="button-link">Switch Category</button>
                                            </div>
                                            : null
                                        }
                                        {!entry.LegislationNumber &&
                                            <div>
                                                <hr />
                                                <span className="small-text">Apply Legislation</span>
                                                <LargeListSelect
                                                    styles={legNumSelectStyle}
                                                    options={this.props.sessionBills}
                                                    value={this.state.selectedBillNumber[entryIndex]}
                                                    onChange={val => this.handleBillNumberChange(entryIndex, val)}
                                                />
                                                <button type="button" onClick={() => this.applyLegislationToEntry(entryIndex)} disabled={!this.state.selectedBillNumber[entryIndex] || this.props.categoryFormDisabled} className="button small-button">Apply</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {this.state.showSwitchCategory &&
                                <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={this.state.checkedBills[entryIndex]}
                                    onChange={(e) => this.toggleSelectEntry(entryIndex, e)}
                                />
                            }
                        </div>
                        <div>
                            <div className="flex-row flex-vertical-center">
                                {entry.LegislationNumber
                                    ? <React.Fragment>
                                        <Link to={`/bill-details/${this.props.sessionCode}/${entry.LegislationNumber}`} target="_blank" rel="noreferrer">{entry.LegislationNumber}</Link>
                                        <span onClick={() => this.toggleSmallMotionForm(entryIndex)} className="icon add"></span>
                                    </React.Fragment>
                                    : "No Legislation"
                                }
                            </div>
                            {
                                this.state.smallMotionForm === entryIndex &&
                                /* The contentEditable is so that the element can be focused and the onBlur function is able to run */
                                <div style={{ width: '900px' }} className="info-box blue" onBlur={(e) => this.toggleSmallMotionForm(-1, e)} contentEditable suppressContentEditableWarning={true}>
                                    <div contentEditable={false}>
                                        <div onKeyUp={(e) => this.onKeyUp(e)}>
                                            <Select
                                                value={this.state.selectedMotion}
                                                options={[...this.state.references].filter(r => !r.LegislationChamberCode || !entry.LegislationNumber || entry.LegislationChamberCode === r.LegislationChamberCode)}
                                                onChange={this.handleMotionChange}
                                                styles={selectStyle}
                                                ref={this.motionDropdownRef}
                                            />
                                            {this.state.otherEventSelected &&
                                                <>
                                                    <button type="button" className='icon delete' onClick={() => this.unSelectOtherEvent()}></button>

                                                    <div className="inner-grid bill-history-event-controls" style={{ marginTop: "10px" }}>
                                                        <Select
                                                            options={this.state.otherBillEventOptions}
                                                            styles={selectStyle}
                                                            value={this.state.otherEvent}
                                                            getOptionLabel={opt => opt.EventCode ? "(" + opt.EventCode + ") " + opt.label : opt.label}
                                                            onChange={this.handleOtherEventChange}
                                                            isDisabled={this.state.otherBillEventOptions.length === 0}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div>
                                            <button type='button' disabled={!this.state.selectedMotion || this.props.categoryFormDisabled} className='button' onClick={() => this.insertMotion(!this.state.newEntries[entryIndex])}>Add Motion</button>
                                        </div>
                                        {this.state.templateInputs.length > 0 &&
                                            <div className="multi-row one-small-small no-margin">
                                                <div>
                                                    <div>
                                                        <span className='small-text txt-greyed'>{inputValue}</span>
                                                    </div>
                                                    <InputTypes
                                                        newEvent={this.state.selectedMotion}
                                                        memberList={this.props.memberList}
                                                        committeeList={this.props.committeeList}
                                                        communicationList={this.props.communicationList}
                                                        eventList={this.state.reconsiderationEvents}
                                                        handleChange={this.handleTemplateChange}
                                                        entryIndex={0}
                                                        activityIndex={0}
                                                        references={this.state.templateInputs}
                                                        textList={this.state.textList.filter(text => this.state.insertEntry.find(bill => bill.LegislationID === text.LegislationID) ? true : false)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div >
                        <div>
                            {this.state.newEntries[entryIndex] ?
                                <React.Fragment>
                                    <DragDropContext onDragEnd={res => this.onActivityDragEnd(res, entryIndex)}>
                                        <Droppable droppableId={`activities-${entryIndex}-droppable`}>
                                            {(provided, snapshot) =>
                                                <div
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}>
                                                    {this.state.newEntries[entryIndex].MinutesActivities && reversedActivities.map((activity, activityIndex) => {
                                                        if (!this.state.newEntries[entryIndex].showDeleted && activity.DeletionDate) {
                                                            return null;
                                                        } else {
                                                            const inputValue = (reversedActivities[activityIndex].Description || (reversedActivities[activityIndex].ActivityReferences && reversedActivities[activityIndex].ActivityReferences.map((tInput, i) => {
                                                                //For events H6013/S6013/H6113/S6113/H6213/S6213 there are sometimes incomplete lists of members. This will prevent the UI from adding blank member dropdown values to the description
                                                                if (memberListEvents.includes(reversedActivities[activityIndex].EventCode) && !tInput.ReferenceText) {
                                                                    return;
                                                                }

                                                                if (!tInput.ReferenceText && !reversedActivities[activityIndex].ReconsiderationGrammar) {
                                                                    return `${tInput.ActionReferenceType}`
                                                                }
                                                                return reversedActivities[activityIndex].ActivityReferences.find(x => x.ActionReferenceType === "LegislationEvent") ? this.state.fullEventList.find(e => e.EventCode === reversedActivities[activityIndex].ActivityReferences.find(x => x.ActionReferenceType === "LegislationEvent")?.EventCode && e.IsPassed === reversedActivities[activityIndex].ActivityReferences.find(x => x.ActionReferenceType === "LegislationEvent")?.IsPassed)?.ReconsiderationDescription : tInput.ReferenceText + (memberListEvents.includes(reversedActivities[activityIndex].EventCode) && tInput.ActionReferenceType === "Member" && tInput.ReferenceText && reversedActivities[activityIndex].ActivityReferences[i + 1] && reversedActivities[activityIndex].ActivityReferences[i + 1].ActionReferenceType === "Member" && reversedActivities[activityIndex].ActivityReferences[i + 1].ReferenceText ? ", " : "")
                                                            }).join('')));
                                                            return (
                                                                <Draggable key={`${activity.MinutesActivityID}-${activity.MinutesActivityID}`} draggableId={`${activity.MinutesActivityID}-${activity.Sequence}`} index={activityIndex}>
                                                                    {(provided, snapshot) =>
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                                                            {activityIndex !== firstActivityIndex && <hr className="faded-line" />}
                                                                            <div className="no-gap-grid two-col-sidebar-backwards">
                                                                                <div {...provided.dragHandleProps}>
                                                                                    <button className="button draggable">Drag and drop</button>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="no-gap-grid two-col-sidebar">
                                                                                        <div>
                                                                                            {entry.LegislationNumber && !activity.OtherMotionSelected &&
                                                                                                <Select
                                                                                                    value={[...this.state.references].find(r => r.EventCode === activity.EventCode && r.IsPassed === activity.IsPassed) ? [...this.state.references].find(r => r.EventCode === activity.EventCode && r.IsPassed === activity.IsPassed) : activity.Description ? {label: activity.Description} : {label: "Select..."}}
                                                                                                    options={[...this.state.references].filter(r => !r.LegislationChamberCode || entry.LegislationNumber[0] === r.LegislationChamberCode)}
                                                                                                    onChange={(motion) => this.handleChange(entryIndex, activityIndex, null, null, null, motion)}
                                                                                                    styles={selectStyle}
                                                                                                    isClearable
                                                                                                />
                                                                                            }
                                                                                            {!activity.OtherMotionSelected &&
                                                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                                                                    <span
                                                                                                        style={{ textDecoration: reversedActivities[activityIndex].DeletionDate ? 'line-through' : 'none' }}
                                                                                                        className="small-text">
                                                                                                        {((reversedActivities[activityIndex].tempDescription ?? reversedActivities[activityIndex].Description) || reversedActivities[activityIndex].ActivityReferences && reversedActivities[activityIndex].ActivityReferences.map((tInput, i) => {
                                                                                                            //For events H6013/S6013/H6113/S6113/H6213/S6213 there are sometimes incomplete lists of members. This will prevent the UI from adding blank member dropdown values to the description
                                                                                                            if (memberListEvents.includes(reversedActivities[activityIndex].EventCode) && !tInput.ReferenceText) {
                                                                                                                return;
                                                                                                            }

                                                                                                            if (!tInput.ReferenceText && !reversedActivities[activityIndex].ReconsiderationGrammar) {
                                                                                                                return `${tInput.ActionReferenceType}`
                                                                                                            }
                                                                                                            return reversedActivities[activityIndex].ActivityReferences.find(x => x.ActionReferenceType === "LegislationEvent") ? this.state.fullEventList.find(e => e.EventCode === reversedActivities[activityIndex].ActivityReferences.find(x => x.ActionReferenceType === "LegislationEvent")?.EventCode && e.IsPassed === reversedActivities[activityIndex].ActivityReferences.find(x => x.ActionReferenceType === "LegislationEvent")?.IsPassed)?.ReconsiderationDescription : tInput.ReferenceText + (memberListEvents.includes(reversedActivities[activityIndex].EventCode) && tInput.ActionReferenceType === "Member" && tInput.ReferenceText && reversedActivities[activityIndex].ActivityReferences[i + 1] && reversedActivities[activityIndex].ActivityReferences[i + 1].ActionReferenceType === "Member" && reversedActivities[activityIndex].ActivityReferences[i + 1].ReferenceText ? ", " : "")
                                                                                                        }).join('')) + ((reversedActivities[activityIndex].tempVoteTally ?? reversedActivities[activityIndex].VoteTally) ? ' ' + (reversedActivities[activityIndex].tempVoteTally ?? reversedActivities[activityIndex].VoteTally) : '')}
                                                                                                    </span>
                                                                                                    {(reversedActivities[activityIndex].ActivityReferences && reversedActivities[activityIndex].ActivityReferences.find(ar => ar.ActionReferenceType !== "Text")) && (!this.state.editingActivityDescription[entryIndex] || !this.state.editingActivityDescription[entryIndex][activityIndex]) && <a className={`icon edit`} onClick={() => this.toggleEditActivityDescription(entryIndex, activityIndex)} />}
                                                                                                </div>
                                                                                            }
                                                                                            {!activity.OtherMotionSelected &&
                                                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                                                                    {this.state.editingActivityDescription[entryIndex] && this.state.editingActivityDescription[entryIndex][activityIndex] &&
                                                                                                        <>
                                                                                                            <div style={{ width: '100%', display: 'flex', gap: '5px' }}>
                                                                                                                <textarea style={{ width: '75%' }} value={reversedActivities[activityIndex].tempDescription ?? inputValue} onChange={(e) => this.handleActivityDescriptionChange(e.target.value, "Description", entryIndex, activityIndex)} />
                                                                                                                {reversedActivities[activityIndex].VoteTally !== undefined && <EditVoteTally value={reversedActivities[activityIndex].tempVoteTally} onChange={(e) => this.handleActivityDescriptionChange(e.target.value, "VoteTally", entryIndex, activityIndex)} />}
                                                                                                            </div>
                                                                                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                                                                                {this.state.editingActivityDescription[entryIndex] && this.state.editingActivityDescription[entryIndex][activityIndex] &&
                                                                                                                    <a className={`icon save`} onClick={() => this.handleActivityDescriptionChange(null, null, entryIndex, activityIndex, true)} />}
                                                                                                                <a className={`icon ${this.state.editingActivityDescription[entryIndex] && this.state.editingActivityDescription[entryIndex][activityIndex] ? 'delete' : 'edit'}`} onClick={() => this.toggleEditActivityDescription(entryIndex, activityIndex)} />
                                                                                                            </div>
                                                                                                        </>
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                            {activity.OtherMotionSelected &&
                                                                                                <>
                                                                                                    <button type="button" className='button danger' onClick={() => this.unSelectOtherEventEdit(entryIndex, activityIndex)}>Cancel Other Motion</button>

                                                                                                    <div className="inner-grid bill-history-event-controls" style={{ marginTop: "10px" }}>
                                                                                                        <Select
                                                                                                            options={this.state.otherBillEventOptions}
                                                                                                            styles={selectStyle}
                                                                                                            value={activity.OtherEvent ? this.state.otherBillEventOptions.find(x => x.EventCode === activity.OtherEvent.EventCode) : { label: "Select...", value: "" }}
                                                                                                            getOptionLabel={opt => opt.EventCode ? "(" + opt.EventCode + ") " + opt.label : opt.label}
                                                                                                            onChange={(value) => this.handleOtherChange(entryIndex, activityIndex, undefined, undefined, undefined, value)}
                                                                                                            isDisabled={this.state.otherBillEventOptions.length === 0}
                                                                                                        />
                                                                                                    </div>

                                                                                                    {activity.OtherEvent !== undefined &&
                                                                                                        <>
                                                                                                            < InputTypes
                                                                                                                style={{ display: "inline-block" }}
                                                                                                                newEvent={reversedActivities[activityIndex].OtherEvent}
                                                                                                                memberList={this.props.memberList}
                                                                                                                committeeList={this.props.committeeList}
                                                                                                                communicationList={this.props.communicationList}
                                                                                                                eventList={this.state.reconsiderationEvents}
                                                                                                                actionTypes={this.props.actionTypes}
                                                                                                                handleChange={this.handleChange}
                                                                                                                entryIndex={entryIndex}
                                                                                                                activityIndex={activityIndex}
                                                                                                                references={reversedActivities[activityIndex].OtherEvent.ActivityReferences || []}
                                                                                                                textList={this.state.textList.filter(text => this.state.newEntries[entryIndex].LegislationID === text.LegislationID)}
                                                                                                            />
                                                                                                            {reversedActivities[activityIndex].OtherEvent.VoteTally !== undefined && reversedActivities[activityIndex].OtherEvent.ActivityReferences !== undefined && !reversedActivities[activityIndex].OtherEvent.ActivityReferences.find(ar => ar.ActionReferenceType !== "Text") && <EditVoteTally value={reversedActivities[activityIndex].OtherEvent.tempVoteTally ?? reversedActivities[activityIndex].OtherEvent.VoteTally} onChange={(e) => this.handleActivityDescriptionChange(e.target.value, "VoteTally", entryIndex, activityIndex)} />}
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                            <div>
                                                                                                {!reversedActivities[activityIndex].DeletionDate && !activity.OtherMotionSelected &&
                                                                                                    <>
                                                                                                        <InputTypes
                                                                                                            style={{ display: "inline-block" }}
                                                                                                            newEvent={reversedActivities[activityIndex]}
                                                                                                            memberList={this.props.memberList}
                                                                                                            committeeList={this.props.committeeList}
                                                                                                            communicationList={this.props.communicationList}
                                                                                                            eventList={this.state.reconsiderationEvents}
                                                                                                            actionTypes={this.props.actionTypes}
                                                                                                            handleChange={this.handleChange}
                                                                                                            entryIndex={entryIndex}
                                                                                                            activityIndex={activityIndex}
                                                                                                            references={reversedActivities[activityIndex].ActivityReferences || []}
                                                                                                            textList={this.state.textList.filter(text => this.state.newEntries[entryIndex].LegislationID === text.LegislationID)}
                                                                                                        />
                                                                                                        {reversedActivities[activityIndex].VoteTally !== undefined && reversedActivities[activityIndex].ActivityReferences !== undefined && !reversedActivities[activityIndex].ActivityReferences.find(ar => ar.ActionReferenceType !== "Text") && <EditVoteTally value={reversedActivities[activityIndex].tempVoteTally ?? reversedActivities[activityIndex].VoteTally} onChange={(e) => this.handleOtherActivityDescriptionChange(e.target.value, "VoteTally", entryIndex, activityIndex)} />}
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            {!reversedActivities[activityIndex].DeletionDate ?
                                                                                                <button onClick={() => this.removeActivity(entryIndex, activityIndex)} type="button" className="button remove"></button>
                                                                                                :
                                                                                                <button onClick={() => this.addBackActivity(entryIndex, activityIndex)} type="button" className="button add"></button>
                                                                                            }

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>}
                                                                </Draggable>
                                                            )
                                                        }

                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            }
                                        </Droppable></DragDropContext>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {entry.MinutesActivities && entry.MinutesActivities.length > 0
                                        ? entry.MinutesActivities.filter(a => !a.DeletionDate).map((activity, activityIndex) =>
                                            <span key={activityIndex}>
                                                {activityIndex !== 0 && ', '}{activity.Description}{activity.VoteTally ? ' ' + activity.VoteTally : ''}
                                            </span>
                                        )
                                        //find way to insert summary into document and extract innertext to take substring of
                                        : <span>{entry.MinutesSummaries && entry.MinutesSummaries[0] && entry.MinutesSummaries[0].MinutesSummary.length > 215 ? extractText(entry.MinutesSummaries[0].MinutesSummary).substr(0, 215) + "..." : entry.MinutesSummaries && entry.MinutesSummaries[0] && extractText(entry.MinutesSummaries[0].MinutesSummary)}</span>
                                    }
                                </React.Fragment>
                            }

                        </div>
                        {
                            this.state.newEntries[entryIndex] ? (
                                <div style={{ position: "relative" }}>
                                    <div className="bill-grid-actions" key={entry.MinutesEntryID}>
                                        <button onClick={() => this.saveChange(entryIndex)} type="button" className="icon save"></button>
                                        <button onClick={() => this.toggleEdit(entryIndex)} type="button" className="icon delete"></button>
                                    </div>
                                    {showDeletedItemsCheckbox &&
                                        <div style={!this.state.newEntries[entryIndex].MinutesActivities || !this.state.newEntries[entryIndex].MinutesActivities.filter(ma => !ma.DeletionDate).length ? { width: '105%' } : { width: '105%', position: 'absolute', bottom: '1px' }} className="flex-row flex-vertical-center flex-start">
                                            <div className="toggle-switch" onClick={() => this.toggleShowDeleted(entryIndex)}>
                                                <input type="checkbox" checked={this.state.newEntries[entryIndex].showDeleted} />
                                                <span className="slider"></span>
                                            </div>
                                            <label style={{ fontSize: "12px" }} className="checkbox-label">Show Deleted</label>
                                        </div>}
                                </div>
                            ) : (
                                <div className="bill-grid-actions" key={entry.MinutesEntryID}>
                                    {this.state.savingVotes[entryIndex] || this.state.togglingEdit[entryIndex] ? <span className="small-spinner"></span> :
                                        <React.Fragment>
                                            <button onClick={() => this.toggleCommentBox(entryIndex)} type="button" disabled={this.props.categoryFormDisabled} className="button-link">{entry.MinutesSummaries && entry.MinutesSummaries.length > 0 ? "Edit" : "Add"} Comment</button>
                                            {entry.MinutesActivities && entry.MinutesActivities.length > 0 && <button onClick={() => this.toggleEdit(entryIndex)} type="button" className="button-link" disabled={this.props.categoryFormDisabled}>Edit Motions</button>}
                                        </React.Fragment>}
                                </div>
                            )
                        }
                    </React.Fragment >
                </div >
            </div >
        );
    }

    render() {
        const { insertEntry, selectedMotion, references, nonLegislativeActionReferences, showAuxBillForm } = this.state;
        //Seperate the entries that are in block and out of block
        //Use map instead of filter so that the index is preserved
        const entries = this.props.formData.MinutesEntries;
        const outOfBlockEntries = entries.map(entry => {
            return entry.IsOOB ? entry : null
        });
        let inBlockEntries = entries.map(entry => {
            return !entry.IsOOB ? entry : null
        });
        const billNumbers = [
            {
                options: [{ label: 'No Legislation' }]
            },
            {
                button: <button type="button" onClick={() => this.addAllEntries(false)} className="button small-button">Select All In Block</button>,
                options: inBlockEntries.filter(opt => opt && opt.LegislationNumber),
            },
            {
                button: <button type="button" onClick={() => this.addAllEntries(true)} className="button small-button">Select All Out of Block</button>,
                options: outOfBlockEntries.filter(opt => opt && opt.LegislationNumber),
            }
        ];
        const auxOptions = this.props.sessionBills.filter(bill => {
            //If the bill is not already in the minutes entries then add it to the aux list            
            if (!this.props.formData.MinutesEntries.find(x => x.LegislationID === bill.LegislationID)) {
                return bill;
            }
        })
        const memberListEvents = ["H6013", "S6013", "H6113", "S6113", "H6213", "S6213"];

        let inputValue = this.state.templateInputs.map((tInput, i) => {
            //For events H6013/S6013/H6113/S6113/H6213/S6213 there are sometimes incomplete lists of members. This will prevent the UI from adding blank member dropdown values to the description
            if (memberListEvents.includes(this.state.selectedMotion?.EventCode) && !tInput.ReferenceText) {
                return;
            }

            if (!tInput.ReferenceText && !this.state.selectedMotion?.ReconsiderationGrammar) {
                return `${tInput.ActionReferenceType}`
            }
            return this.state.templateInputs.find(x => x.ActionReferenceType === "LegislationEvent") ? this.state.fullEventList.find(e => e.EventCode === this.state.templateInputs.find(x => x.ActionReferenceType === "LegislationEvent")?.EventCode && e.IsPassed === this.state.templateInputs.find(x => x.ActionReferenceType === "LegislationEvent")?.IsPassed)?.ReconsiderationDescription : tInput.ReferenceText + (memberListEvents.includes(this.state.selectedMotion?.EventCode) && tInput.ActionReferenceType === "Member" && tInput.ReferenceText && this.state.templateInputs[i + 1] && this.state.templateInputs[i + 1].ActionReferenceType === "Member" && this.state.templateInputs[i + 1].ReferenceText ? ", " : "")
        }).join('');
        return (
            <div>
                <div className="user-forms motion-form">
                    <div className="dlas-forms">
                        {this.state.showCommentBox !== false &&
                            <CommentBox
                                toggleBox={this.toggleCommentBox}
                                handleCommentBoxChange={this.handleCommentBoxChange}
                                comments={this.state.commentBoxContent}
                                saveComment={this.saveComment}
                            />
                        }
                        <div className="inner-grid one-and-eight">
                            <p className="small-header">Motion</p>
                            <div className="dlas-forms">
                                {showAuxBillForm
                                    ? <div className="inner-grid" style={{ gridTemplateColumns: "25px 1fr 150px 150px", marginTop: "19px" }}>
                                        <a className="icon delete" onClick={this.toggleAuxBillAdd} />
                                        <span style={{ fontSize: "14px" }}>This form may be used to make any bill in the session available to add to this minutes category. Use with caution.</span>
                                        <Select
                                            options={auxOptions}
                                            onChange={this.setAuxBill}
                                            isSearchable={true}
                                        />
                                        <button type="button" className="button" onClick={this.addAuxBill} disabled={this.state.savingAuxBill || this.props.categoryFormDisabled}>Add Bill to List</button>
                                    </div>
                                    : <div className="inner-grid" style={{ gridTemplateColumns: "25px 8fr 225px", marginTop: "19px" }}>
                                        <a className="icon warning" onClick={this.toggleAuxBillAdd} style={{ marginTop: "19px" }} />
                                        <div />
                                    </div>
                                }
                            </div>
                        </div>
                        <form>
                            <div className="multi-row small-three-one-alt no-margin">
                                <div>
                                    <label>Bill #</label>
                                    <Select
                                        value={insertEntry}
                                        isMulti
                                        onChange={this.handleBillChange}
                                        options={billNumbers}
                                        styles={selectStyle}
                                        formatGroupLabel={data => <div>{data.button}</div>}
                                        getOptionLabel={option => option.LegislationNumber || option.label || "Entry"}
                                        getOptionValue={option => option.MinutesEntryID}
                                        isDisabled={this.state.smallMotionForm !== -1}
                                    />
                                </div>
                                <div onKeyUp={(e) => this.onKeyUp(e)}>
                                    <label>Motion</label>
                                    <Select
                                        value={selectedMotion}
                                        options={insertEntry && insertEntry.length && insertEntry.find(x => x.label === "No Legislation") ? nonLegislativeActionReferences : [...references].filter(r => !r.LegislationChamberCode || !insertEntry || insertEntry.find(b => b.LegislationChamberCode === r.LegislationChamberCode))}
                                        isDisabled={this.state.otherEventSelected || insertEntry.length === 0 || this.state.smallMotionForm !== -1 || insertEntry.find(x => x.LegislationNumber === "No Legislation")}
                                        onChange={(value) => this.handleMotionChange(value)}
                                        styles={selectStyle}
                                        isClearable
                                        ref={this.motionRef}                                        
                                    />
                                    {this.state.otherEventSelected &&
                                        <>
                                            <button type="button" className='icon delete' onClick={() => this.unSelectOtherEvent()}></button>

                                            <div className="inner-grid bill-history-event-controls" style={{ marginTop: "10px" }}>
                                                <Select
                                                    options={this.state.otherBillEventOptions}
                                                    styles={selectStyle}
                                                    value={this.state.otherEvent}
                                                    getOptionLabel={opt => opt.EventCode ? "(" + opt.EventCode + ") " + opt.label : opt.label}
                                                    onChange={this.handleOtherEventChange}
                                                    isDisabled={this.state.otherBillEventOptions.length === 0}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                                <div>
                                    <label>Description</label>
                                    <input
                                        value={this.state.templateInputs.length > 0 ? '' : selectedMotion?.Description || selectedMotion?.CalendarDescription || ''}
                                        placeholder={selectedMotion ? this.state.templateInputs.length > 0 ? "See below..." : "" : "Select motion..."}
                                        disabled={!selectedMotion || this.state.templateInputs.length > 0}
                                        onChange={this.handleSelectedMotionDescriptionChange}
                                    />
                                </div>
                                <div className="inner-grid two">
                                    <div>
                                        <br />
                                        <button type='button' disabled={(!this.state.selectedMotion && !this.state.insertEntry.find(x => x.LegislationNumber === "No Legislation")) || this.state.smallMotionForm !== -1 || this.props.categoryFormDisabled} className='button' onClick={this.insertMotion}>Add Motion</button>
                                    </div>
                                    <div>
                                        <br />
                                        <button type='button' disabled={!this.state.selectedMotion || this.state.smallMotionForm !== -1 || this.props.categoryFormDisabled} onClick={this.removeMotion} className='button danger'>Remove Motion</button>
                                    </div>
                                </div>
                            </div>
                            {this.state.templateInputs.length > 0 &&
                                <div className="multi-row small-two-one-alt no-margin">
                                    <br />
                                    <div>
                                        <div>
                                            <span className='small-text txt-greyed'>{inputValue}</span>
                                        </div>
                                        <InputTypes
                                            newEvent={this.state.selectedMotion}
                                            memberList={this.props.memberList}
                                            committeeList={this.props.committeeList}
                                            communicationList={this.props.communicationList}
                                            eventList={this.state.reconsiderationEvents}
                                            handleChange={this.handleTemplateChange}
                                            entryIndex={0}
                                            activityIndex={0}
                                            references={this.state.templateInputs}
                                            textList={this.state.textList.filter(text => this.state.insertEntry.find(bill => bill.LegislationID === text.LegislationID) ? true : false)}
                                        />
                                    </div>
                                </div>
                            }
                        </form>
                    </div>
                </div>
                {entries.length ?
                    <div className="flex-row flex-end" style={{ marginBottom: '5px' }}>
                        {this.state.showSwitchCategory &&
                            <div className="inner-grid" style={{ gap: '5px', gridTemplateColumns: '20px 4fr .1fr' }}>
                                <div className="inner-grid align-center">
                                    <button onClick={this.toggleSwitchCategory} type="button" className="icon delete"></button>
                                </div>
                                <Select
                                    value={this.state.selectedSwitchCategory}
                                    options={this.props.categories.filter(c => c.CalendarCategoryTypeID === this.props.formData.CalendarCategoryTypeID && c.MinutesCategoryID !== this.props.formData.MinutesCategoryID)}
                                    getOptionLabel={opt => this.props.getCategoryDescriptionText(opt)}
                                    onChange={this.handleSwitchCategoryChange}
                                    styles={selectStyle}
                                />
                                <button onClick={this.switchCategory} disabled={!this.state.checkedBills.length || this.state.switchingCategories || !this.state.selectedSwitchCategory} type="button" className="button float-right">{this.state.switchingCategories ? <div className="small-spinner" /> : 'Switch'}</button>
                            </div>
                        }
                    </div>
                    : null
                }
                <div>
                    <div className="bill-grid">
                        <div className="bill-grid-header">
                            <span></span>
                        </div>
                        <div className="bill-grid-header">
                            <span>Bill #</span>
                        </div>
                        <div className="bill-grid-header">
                            <span>Bill Action</span>
                        </div>
                        <div className="bill-grid-header">
                            <span>&nbsp;</span>
                        </div>
                    </div>
                    <div>
                        <DragDropContext onDragEnd={this.onEntryDragEnd}>
                            <Droppable droppableId={`entries-droppable`}>
                                {(provided, snapshot) =>
                                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                        {inBlockEntries.map((entry, entryIndex) => {
                                            if (entry) {
                                                return (
                                                    <Draggable isDragDisabled={this.props.categoryFormDisabled} key={`${entry.MinutesEntryID}-${entry.Sequence}`} draggableId={`${entry.MinutesEntryID}-${entry.Sequence}`} index={entryIndex}>
                                                        {(provided, snapshot) =>
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}>
                                                                {this.formInput(entry, entryIndex, provided)}
                                                            </div>}
                                                    </Draggable>
                                                );
                                            }
                                        })}
                                        {provided.placeholder}
                                    </div>
                                }
                            </Droppable>
                        </DragDropContext>
                        {outOfBlockEntries.find(ent => ent !== null) &&
                            <div className="bill-grid" style={{ gridTemplateColumns: 'none' }}>
                                <div className="bold">
                                    Out Of Block
                                </div>
                            </div>
                        }
                        {outOfBlockEntries.map((entry, entryIndex) => {
                            if (entry) {
                                return (this.formInput(entry, entryIndex));
                            }
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

const EditVoteTally = props => <textarea style={{ width: '25%' }} value={props.value} onChange={props.onChange} />

//react-select default style makes the element too tall and the font too large
const fontSize = '0.8em'
const selectStyle = {
    control: styles => ({
        ...styles,
        minHeight: '0px',
        padding: '0.12em 0.6em !important',
    }),
    valueContainer: styles => ({
        ...styles,
        padding: 0,
    }),
    input: styles => ({
        ...styles,
        fontSize: fontSize,
        lineHeight: 'normal',
    }),
    singleValue: styles => ({
        ...styles,
        fontSize: fontSize,
    }),
    placeholder: styles => ({
        ...styles,
        fontSize: fontSize
    }),
    option: styles => ({
        ...styles,
        fontSize: fontSize
    }),
}
const legNumSelectStyle = {
    ...selectStyle,
    dropdownIndicator: styles => ({
        ...styles,
        padding: 0,
    }),
    container: styles => ({
        ...styles,
        width: '100%'
    }),
    control: styles => ({
        ...styles,
        padding: 0,
        minHeight: 'auto'
    })

}

export default connect(
    (state) => {
        const { calendar, votes, bills, minutes } = state;
        return {
            calendar,
            votes,
            bills,
            minutes
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, voteActionCreators, calendarActionCreators, billActionCreators, minutesActionCreators), dispatch)
        }
    },
    null,
    {
        forwardRef: true
    }
)(LegislativeForm)