import React from 'react';
import moment from 'moment-timezone';
import renderHTML from 'react-render-html';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactToPrint from "react-to-print";
import CreatableSelect from 'react-select/creatable';
import { billActionCreators } from '../../../stores/lis-legislation-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { patronActionCreators } from '../../../stores/lis-patron-store';
import { voteActionCreators } from '../../../stores/lis-votes-store';
import { authActionCreators } from '../../../stores/lis-auth-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import { orgActionCreators } from '../../../stores/lis-org-store';
import { cancelRequest } from '../../../services/request.service';
import '../../../stylesheets/lis-public-view/public-view.css';
import LoaderComponent from '../../../lis-shared/lis-layout/components/lis-loader-component';
import BillHistoryForm from './lis-history-form';
import ImpactStatementForm from './lis-impact-statements';
import CollectionNotes from './collection-notes';
import queryString from 'query-string';
import { minutesActionCreators } from '../../../stores/lis-minutes-store';
import { memberActionCreators } from '../../../stores/lis-members-store';
import VersionList from '../../../lis-shared/lis-bills/lis-bill-version-list';
import { Editor } from '@tinymce/tinymce-react';
import { collectionActionCreators } from '../../../stores/lis-collection-store';

const BILL_EVENT_VIEWER = "LegislationEvent";
const BILL_EVENT_AUTHOR = "LegislationEventHistory";
const BILL_COLLECTION_AUTHOR = "LegislationCollections";
const BILL_AUTHOR = "Legislation";
const VERSION_AUTHOR = "LegislationText";
const PATRON_AUTHOR = "LegislationPatron";

const SUMMARY = "summary";
const DESCRIPTION = "description";

const isStatic = window.location.pathname.includes('static');

const selectStyle = {
    control: styles => ({
        ...styles,
        minHeight: '0px',
        padding: '0.12em 0.6em !important',
    }),
    valueContainer: styles => ({
        ...styles,
        padding: 0,
    }),
    input: styles => ({
        ...styles,
        fontSize: '0.8em',
        lineHeight: 'normal',
    }),
    singleValue: styles => ({
        ...styles,
        fontSize: '0.8em'
    }),
    placeholder: styles => ({
        ...styles,
        fontSize: '0.8em'
    }),
    option: styles => ({
        ...styles,
        fontSize: '0.8em'
    }),
    dropdownIndicator: styles => ({
        ...styles,
        padding: '1px'
    }),
}

function PatronName(props) {
    return (
        <Link to={`/session-details/${props.session.SessionCode}/member-information/${props.MemberNumber}/member-details`}>{props.MemberDisplayName}{props.ByRequest && props.DisplayName ? ' ' + props.DisplayName.replace(')', ', By Request)') : props.DisplayName ? ' ' + props.DisplayName : props.ByRequest ? ' (By Request)' : null}</Link>
    );
}

function PatronList(props) {
    var patronsLength = props.patrons.length;
    const listPatrons = props.patrons.map((patrons, i) => {
        if (props.list) {
            return <li><PatronName session={props.session} key={i} {...patrons} /></li>
        } else if (patronsLength === i + 1) {
            return <PatronName session={props.session} key={i} {...patrons} />
        } else {
            return <span key={i}><PatronName session={props.session} {...patrons} />, </span>
        }
    });
    return props.list ? <ul className="list-two no-list-style">{listPatrons}</ul> : <span>{listPatrons}</span>;
}

class BillDetailsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            billData: '',
            historyList: [],
            summariesList: [],
            housePatronsList: [],
            senatePatronsList: [],
            versionsList: [],
            chiefPatronsList: [],
            sessionData: '',
            toolTip: false,
            editingHistory: false,
            billEvents: [],
            organizedEvents: [],
            actionTypes: [],
            memberList: [],
            committeeList: [],
            editing: {},
            description: "",
            saving: {},
            displayStatementForm: false,
            statementSubmitting: false,
            statementLegislationTextID: '',
            statementDate: '',
            statementDescription: '',
            agencyOptions: [],
            statementDocumentcode: '',
            reconsiderationEvents: [],
            hideAllEvents: true,
            patronList: [],
            collections: [],
            collectionIsLoading: false,
            showPopup: false,
            watchlists: [],
            selectedWatchlists: [],
            showWatchlistForm: false
        };
        this.setupPage = this.setupPage.bind(this);
        this.getBillDetails = this.getBillDetails.bind(this);
        this.getPatrons = this.getPatrons.bind(this);
        this.getBillVersion = this.getBillVersion.bind(this);
        this.toggleShowLinks = this.toggleShowLinks.bind(this);
        this.toggleEditContent = this.toggleEditContent.bind(this);
        this.handleEventChange = this.handleEventChange.bind(this);
        this.saveEvents = this.saveEvents.bind(this);
        this.changeToNewBillHistoryForm = this.changeToNewBillHistoryForm.bind(this);
        this.saveDescription = this.saveDescription.bind(this);
        this.saveSummary = this.saveSummary.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleStatementForm = this.toggleStatementForm.bind(this);
        this.submitImpactStatement = this.submitImpactStatement.bind(this);
        this.closeStatementForm = this.closeStatementForm.bind(this);
        this.getReconsiderationEvents = this.getReconsiderationEvents.bind(this);
        this.togglehideAllEvents = this.togglehideAllEvents.bind(this);
        this.checkForDuplicates = this.checkForDuplicates.bind(this);
        this.togglePatronModal = this.togglePatronModal.bind(this);
        this.handleWatchlistChange = this.handleWatchlistChange.bind(this);
        this.handleAddToWatchlists = this.handleAddToWatchlists.bind(this);
        this.addToWatchlists = this.addToWatchlists.bind(this);
        this.getCollections = this.getCollections.bind(this);
        this.toggleWatchlistForm = this.toggleWatchlistForm.bind(this);
        this.handleToggleDescriptionEdit = this.handleToggleDescriptionEdit.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.saveVersionDescription = this.saveVersionDescription.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const sessionCode = this.props.match ? this.props.match.params.sessionCode : this.props.selectedBillSession;
        let legislationNumber = this.props.match ? this.props.match.params.billnumber : this.props.selectedBill;

        //if legislation number is zero-padded (such as from a calendar), remove the zero-padding
        const legislationNumberDigitsWithZeroPadding = legislationNumber.match(/\d+/); //e.g. 0259
        if (legislationNumberDigitsWithZeroPadding) {
            const legislationNumberDigitsWithOutZeroPadding = parseInt(legislationNumberDigitsWithZeroPadding[0]); //turns 0259 into 259
            legislationNumber = legislationNumber.replace(/\d+/, legislationNumberDigitsWithOutZeroPadding); //turns HB0259 into HB259
        }

        this.setupPage(sessionCode, legislationNumber);
    }

    componentDidUpdate(prevProps) {
        const sessionCode = this.props.match ? this.props.match.params.sessionCode : this.props.selectedBillSession;
        const legislationNumber = this.props.match ? this.props.match.params.billnumber : this.props.selectedBill;
        const previousSessionCode = prevProps.match ? prevProps.match.params.sessionCode : prevProps.selectedBillSession;
        const previousLegislationNumber = prevProps.match ? prevProps.match.params.billnumber : prevProps.selectedBill;
        if (previousSessionCode !== sessionCode || previousLegislationNumber !== legislationNumber) {
            this.setState({
                historyIsLoaded: false,
                summaryIsLoaded: false,
                versionsIsLoaded: false,
                patronIsLoaded: false,
                billDataIsLoaded: false
            }, () => {
                this.setupPage(sessionCode, legislationNumber);
            })
        } else if (JSON.stringify(prevProps.login.userClaims.resources) !== JSON.stringify(this.props.login.userClaims.resources)) {
            this.setState({
                historyIsLoaded: false
            }, () => {
                if (!this.props.login.userClaims.resources.find(resource => resource === BILL_AUTHOR)) {
                    this.setState({ editing: {} })
                }
                this.setupPage(sessionCode, legislationNumber);
            })
        }

        if (!this.state.switchedToBill && prevProps.location.search !== this.props.location.search) {
            const parsed = queryString.parse(this.props.location.search);
            if (parsed.openBillHistory) {
                this.setState({
                    switchedToBill: true
                });
            }
        }

    }

    setupPage(sessionCode, legislationNumber) {
        this.props.actions.getSessionByCode(sessionCode).then(() => {
            if (this.props.session.selectedSession) {
                this.setState({
                    sessionData: this.props.session.selectedSession,
                    conveneDate: this.props.session.selectedSession.SessionStart,
                    legislationNumber: legislationNumber
                }, () => {
                    this.getBillDetails(legislationNumber, sessionCode);
                    this.getBillSummary(legislationNumber, sessionCode);
                    this.getBillVersion(legislationNumber, sessionCode);

                    if (this.props.login.isAuthenticated) {
                        this.getCollections("?SessionID=" + this.state.sessionData.SessionID);
                    }
                });
                this.props.actions.getCommitteeList('sessionID=' + this.props.session.selectedSession.SessionID + "&includeSubCommittees=true").then(() => {
                    let committeeList = this.props.committee.committeeList;
                    committeeList.forEach(committee => {
                        committee.label = committee.Name;
                        committee.value = committee.CommitteeID;
                        committee.abbrev = committee.Abbreviation;
                    });
                    this.setState({
                        committeeList: committeeList
                    });
                });
            }
        }).catch(err => {
            if (err === "Aborted") {
                return;
            }
            this.setState({
                billDataIsLoaded: true,
                patronIsLoaded: true,
                historyIsLoaded: true,
                billData: ''
            });
        });
        //This is if a user is signed in with the correct roles they can edit the events
        const chamberCode = legislationNumber[0];
        this.props.actions.getBillEvents('?LegislationChamberCode=' + chamberCode)
            .then(() => {
                let billEvents = [...this.props.bills.billEvents];
                billEvents.forEach(evt => {
                    evt.label = evt.LegislationDescription;
                    evt.value = `${evt.LegislationEventTypeID}-${evt.IsPassed}`
                });
                this.setState({
                    billEvents: billEvents
                });
            });
        this.props.actions.getMemberList('sessionCode=' + sessionCode).then(() => {
            let memberList = [...this.props.members.memberList];
            memberList.forEach(member => {
                member.label = member.PatronDisplayName;
                member.value = member.MemberID;
            });
            this.setState({
                memberList: memberList
            });
        });
        this.props.actions.getActionTypes()
            .then(() => {
                let actionTypes = [...this.props.minutes.actionTypes];
                actionTypes.forEach(type => {
                    type.label = type.ActionReferenceType;
                    type.value = type.ActionReferenceTypeID;
                })
                this.setState({
                    actionTypes: actionTypes
                });
            });
        this.props.actions.getAgencyList().then(() => {
            let agencies = [...this.props.organizations.agencyList]
            agencies.forEach((agency, i) => {
                agency.label = agency.AgencyName + " (" + agency.Abbreviation + ")";
                agency.value = agency.AgencyNumber
            })
            this.setState({
                agencyOptions: agencies
            })
        })
    }

    componentWillUnmount() {
        cancelRequest();
    }

    // RETRIEVE PATRONS FOR A BILL
    getPatrons(billID, chamberCode) {
        this.props.actions.getPatronList(billID).then(() => {
            let senatePatronsList = this.props.patrons.senatePatronsList;
            let housePatronsList = this.props.patrons.housePatronsList;
            let chiefPatronsList = this.props.patrons.chiefPatronsList;
            let patronList = this.props.patrons.patronList;
            let originalPatronList = this.props.patrons.patronList;
            if (chamberCode === 'H' && chiefPatronsList && chiefPatronsList.length > 1) {
                for (let i = 1; i < chiefPatronsList.length; i++) {
                    if (chiefPatronsList[i].ChamberCode === 'S') {
                        senatePatronsList.unshift(chiefPatronsList[i]);
                    } else {
                        housePatronsList.unshift(chiefPatronsList[i]);
                    }
                    chiefPatronsList.splice(i, 1);
                    i--;
                }
            }

            housePatronsList.sort((a, b) => { return a.Sequence > b.Sequence ? 1 : b.Sequence > a.Sequence ? -1 : a.PatronDisplayName > b.PatronDisplayName ? 1 : -1 });
            senatePatronsList.sort((a, b) => { return a.Sequence > b.Sequence ? 1 : b.Sequence > a.Sequence ? -1 : a.PatronDisplayName > b.PatronDisplayName ? 1 : -1 });

            this.setState({
                senatePatronsList,
                housePatronsList,
                chiefPatronsList,
                patronList,
                originalPatronList,
                patronIsLoaded: true
            }, () => {
                if (this.props.location.state && this.props.location.state.expandPatrons && chiefPatronsList.length + housePatronsList.length + senatePatronsList.length > 1) {
                    this.toggleShowData('slidePatron');
                }
            })
        }
        )
    }

    // RETRIEVE BILL SUMMARIES
    getBillSummary(billNumber, sessionCode) {
        const params = '?sessionCode=' + sessionCode + '&legislationNumber=' + billNumber;
        this.props.actions.getBillSummary(params).then(() => {
            this.setState({
                summariesList: this.props.bills.billSummary.sort((a, b) => { return b.SummaryVersionID - a.SummaryVersionID }),
                summaryIsLoaded: true
            });
        });
    }

    // RETRIEVE BILL VERSIONS
    getBillVersion(billNumber, sessionCode) {
        const partner = this.props.login.userClaims.resources.find(resource => resource === VERSION_AUTHOR)
        var params = '?isPublic=' + !partner + '&legislationNumber=' + billNumber + '&sessionCode=' + sessionCode;
        this.props.actions.getBillText(params, partner).then(() =>
            this.setState({
                versionsList: [...this.props.bills.billText].filter(v => !v.SessionCode || v.SessionCode === sessionCode),
                versionsIsLoaded: true
            }, () => {
                if (this.props.login.userClaims.scopes.findIndex(x => x === "House") > -1 || this.props.login.userClaims.scopes.findIndex(x => x === "Senate") > -1 || this.props.login.userClaims.roles.findIndex(x => x === "DLSStaff") > -1) {
                    this.setState({
                        slideVersions: true
                    })
                }
            })
        );
    }

    // RETRIEVE THE BILL HISTORY (BILL EVENTS)
    getBillHistory(legislationID, sessionCode) {
        let partner = false;
        let partnerEditor = false;
        //Only use the partner endpoint if the user looking at the bill text is authorized to edit it or is supposed to see non-public events
        if (this.props.login.userClaims.claims.find(claim => claim.RoleName === BILL_EVENT_AUTHOR)) {
            partner = true;
            partnerEditor = true;
        } else if (this.props.login.userClaims.resources.find(resource => resource === BILL_EVENT_VIEWER)) {
            partner = true;
        }

        let params = '?legislationID=' + legislationID + "&sessionCode=" + sessionCode;
        this.props.actions.getBillHistoryByBillID(params, partner)
            .then(() => {
                this.setState({
                    historyList: this.props.bills.billHistory,
                    historyIsLoaded: true
                }, () => {
                    if (partnerEditor) {
                        const parsed = queryString.parse(this.props.location.search);
                        if (parsed.openBillHistory) {
                            this.setState({
                                switchedToBill: true,
                                editingHistory: true
                            });
                        }
                    } else {
                        this.setState({
                            editingHistory: false
                        })
                    }
                })
            });
    }

    getReconsiderationEvents(params) {
        params = '?ReconsiderationAction=' + params + '&LegislationID=' + this.state.billData.LegislationID;

        this.props.actions.getLegislationEventsByLegislationId(params)
            .then(() => {
                this.setState({
                    reconsiderationEvents: this.props.bills.legislationEventsById
                });
            })
    }

    // RETRIEVE THE BILL DETAILS
    getBillDetails(billId, sessionCode) {
        const params = {
            SessionCode: sessionCode,
            LegislationNumbers: [{ LegislationNumber: billId }],
        };

        this.props.actions.getBillList(params).then(() => {
            if (this.props.bills.billList[0]) {
                this.setState({
                    billData: this.props.bills.billList[0],
                    billDataIsLoaded: true,
                }, () => {
                    this.getPatrons(this.state.billData.LegislationID, this.state.billData.ChamberCode);
                    this.getBillHistory(this.state.billData.LegislationID, this.state.billData.SessionCode);
                });
            } else {
                //The bill cannot be found. Everything has stopped loading so set them to true. A message will be displayed to the user that the bill was not found
                this.setState({
                    billDataIsLoaded: true,
                    patronIsLoaded: true,
                    historyIsLoaded: true,
                    billData: ''
                });
            }
        });
    }

    // TOGGLE SHOW 'ALL' SLIDEDOWNS
    toggleShowData(slide) {
        this.setState(prevState => ({
            [slide]: !prevState[slide]
        }));
    }

    // TOGGLE THE INFO TOOLTIP
    toggleShowTooltip() {
        this.setState(state => ({
            toolTip: !state.toolTip
        }));
    }

    // TOGGLE THE 'ALL HISTORY' ARROW
    toggleShowLinks(vote, sessionCode) {
        let historyList = [...this.state.historyList];
        const historyIndex = historyList.findIndex(item => item.LegislationEventID == vote.LegislationEventID);

        historyList[historyIndex].opened = !historyList[historyIndex].opened;
        if (vote.VoteLinks) {
            this.setState({
                historyList: historyList
            });
        } else {
            historyList[historyIndex].isLoading = true;
            this.setState({ historyList: historyList });
            let params = '?legislationEventID=' + vote.LegislationEventID;
            this.props.actions.getVoteLink(params).then(() => {
                let links = [...this.props.votes.voteLinkResponse];
                let linkPromises = [];
                links.forEach(link => {
                    if (link.CommitteeID) {
                        const cmtePromise = this.props.actions.getCommitteeById("?id=" + link.CommitteeID + "&sessionCode=" + vote.SessionCode)
                            .then(() => {
                                link.CommitteeNumber = this.props.committee.committeeByIdData.CommitteeNumber;
                            }).catch((err) => {
                                console.error(err);
                                // Don't care if it fails. That committee won't be visible
                                return Promise.resolve();
                            })
                        linkPromises.push(cmtePromise);
                    }
                    // If there are any vote links of type 'Legislation' then the legislation ID needs to be turned into a legislation number to form the link
                    if (link.ReferenceType === "Legislation") {
                        const promise = this.props.actions.getBill(link.ReferenceID)
                            .then(() => {
                                link.LegislationNumber = this.props.bills.bill.LegislationNumber;
                                link.SessionCode = link.SessionCode || vote.SessionCode;
                                return Promise.resolve();
                            }).catch(err => {
                                console.error(err);
                                // Don't care if it fails. That legislation won't be visible
                                return Promise.resolve();
                            });
                        linkPromises.push(promise);
                    }
                });
                Promise.all(linkPromises)
                    .then(() => {
                        historyList[historyIndex].isLoading = false;
                        historyList[historyIndex].VoteLinks = this.props.votes.voteLinkResponse;
                        this.setState({
                            historyList: historyList
                        });
                    });
            });
        }
    }

    toggleEditContent() {
        this.setState(state => ({
            editingHistory: !state.editingHistory
        }), () => {
            //some browsers do not support the :has() css selector, which is how we remove (i.e. set display: none) the footer-wrapper when editing events.
            //in these instances, we rely on the below js to accomplish the same.
            const footer = document.getElementsByClassName("footer-wrapper");
            if (footer && footer[0]) {
                const unsupportedBrowser = (this.state.editingHistory && window.getComputedStyle(footer[0]).display === 'block') || (!this.state.editingHistory && footer[0].style.display === 'none');
                if (unsupportedBrowser) {
                    if (this.state.editingHistory) {
                        footer[0].style.display = 'none';
                    } else {
                        footer[0].style.display = '';
                    }
                }
            }
        });
    }

    toggleStatementForm(statementDate, statementDescription, documentCode) {
        if (!this.state.displayStatementForm) {
            this.setState({
                displayStatementForm: true,
                statementDate: statementDate,
                statementDescription: statementDescription,
                statementDocumentcode: documentCode
            });
        } else {
            this.setState({
                displayStatementForm: false,
                statementDate: "",
                statementDescription: "",
                statementDocumentcode: ""
            });
        }
    }

    closeStatementForm() {
        this.setState({
            displayStatementForm: false
        }, () => {
            //Refresh the page to show the new impact statement in the version list
            const sessionCode = this.props.match ? this.props.match.params.sessionCode : this.props.selectedBillSession;
            const legislationNumber = this.props.match ? this.props.match.params.billnumber : this.props.selectedBill;
            this.setupPage(sessionCode, legislationNumber);
        })
    }

    handleEventChange(organizedEvents, callback) {
        this.setState({
            organizedEvents: organizedEvents
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    saveEvents(eventsToSave, callback) {
        if (eventsToSave.length > 0) {
            this.props.actions.saveBillEvent({ LegislationEvents: eventsToSave })
                .then(() => {
                    this.props.actions.makeToast([{ message: "Events Saved", type: "success" }]);
                    this.props.actions.getBillHistoryByBillID('?legislationID=' + this.state.billData.LegislationID + "&sessionCode=" + this.state.billData.SessionCode, true)
                        .then(() => {
                            this.setState({
                                historyList: this.props.bills.billHistory,
                            }, () => callback(true))
                        });
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    let msg;
                    if (err) {
                        try {
                            const errJson = JSON.parse(err);
                            if (errJson && errJson.Name) {
                                msg = errJson.Name
                            }
                        } catch (e) { /*do nothing*/ }
                    }
                    this.props.actions.makeToast([{ message: msg && msg.toLowerCase().includes("unable to delete referral") ? msg : "Events Save Failed", type: "failure" }]);
                    this.props.actions.getBillHistoryByBillID('?legislationID=' + this.state.billData.LegislationID + "&sessionCode=" + this.state.billData.SessionCode, true)
                        .then(() => {
                            this.setState({
                                historyList: this.props.bills.billHistory,
                            }, () => callback(false))
                        });
                })
        } else {
            // If there  are no events to save then just show the save succesful banner after a second to give the illusion something happened.
            setTimeout(() => {
                this.props.actions.makeToast([{ message: "Events Saved", type: "success" }]);
                callback(false)
            }, 1000)
        }
    }

    changeToNewBillHistoryForm(billNum) {
        this.props.history.push(`/bill-details/${this.state.sessionData.SessionCode}/${billNum}/?openBillHistory=true`)
    }

    saveDescription() {
        let saving = { ...this.state.saving };
        saving[DESCRIPTION] = true;
        this.setState({
            saving
        }, () => {
            //Partner get bill request to get the mod date
            this.props.actions.getBill(this.state.billData.LegislationID, true)
                .then(() => {
                    let modifiedBillData = { ...this.props.bills.bill };
                    modifiedBillData.Description = this.state.description;

                    this.props.actions.saveBill({ LegislationList: [modifiedBillData] })
                        .then(() => {
                            this.props.actions.makeToast([{ message: "Description Saved", type: "success" }]);
                            let newBillData = { ...this.state.billData }
                            newBillData.Description = this.state.description;
                            let editing = { ...this.state.editing };
                            editing[DESCRIPTION] = false;
                            saving[DESCRIPTION] = false;
                            this.setState({
                                editing,
                                saving,
                                billData: newBillData
                            });
                        }).catch(err => {
                            if (err === 'Aborted') {
                                return;
                            }
                            this.props.actions.makeToast([{ message: "Description Save Failed", type: "failure" }]);
                            let editing = { ...this.state.editing };
                            editing[DESCRIPTION] = false;
                            saving[DESCRIPTION] = false;
                            this.setState({
                                editing: editing,
                                saving: saving
                            });
                        });
                })
        })
    }

    saveSummary(index, deletion) {
        let saving = { ...this.state.saving };
        if (!saving[SUMMARY]) {
            saving[SUMMARY] = {};
        }
        saving[SUMMARY][index] = true;
        this.setState({
            saving
        }, () => {
            let summariesList = [...this.state.summariesList];
            //Partner get summary request to get the mod date
            this.props.actions.getBillSummary('/' + summariesList[index].LegislationSummaryID, true)
                .then(() => {
                    let modifiedSummaryData = [...this.props.bills.billSummary];
                    if (deletion) {
                        //Turn IsActive and IsPublic is false, and that will prevent the DB from turning one or the other true in the future
                        modifiedSummaryData[0].IsActive = false;
                        modifiedSummaryData[0].IsPublic = false;
                    } else {
                        modifiedSummaryData[0].Summary = this.state.summary[index];
                    }
                    this.props.actions.saveBillSummary({ SummaryList: modifiedSummaryData })
                        .then(() => {
                            this.props.actions.makeToast([{ message: "Summary " + (deletion ? "Removed" : "Saved"), type: "success" }]);
                            let editing = { ...this.state.editing };
                            let summary = { ...this.state.summary };
                            if (deletion) {
                                //remove from the UI and reset the editing/saving/summary values to avoid indexing problems
                                this.toggleDeleteSummaryModal();
                                summariesList.splice(index, 1);
                                saving = {};
                                editing = {};
                                summary = {};
                            } else {
                                summariesList[index] = this.props.bills.billSummarySave[0];
                                saving[SUMMARY][index] = false;
                                if (editing[SUMMARY] && editing[SUMMARY][index]) {
                                    editing[SUMMARY][index] = false;
                                }
                            }
                            this.setState({
                                summariesList,
                                summary,
                                editing,
                                saving
                            });
                        }).catch(err => {
                            if (err === 'Aborted') {
                                return;
                            }
                            console.error(err)
                            this.props.actions.makeToast([{ message: "Summary Save Failed", type: "failure" }]);
                            let editing = { ...this.state.editing };
                            let saving = { ...this.state.saving };
                            let summary = { ...this.state.summary };
                            editing = {};
                            saving = {};
                            summary = {};
                            this.setState({
                                summary,
                                editing: editing,
                                saving: saving
                            });
                        });
                })
        })
    }

    toggleEdit(key, value, index) {
        let editing = { ...this.state.editing };
        if (key === SUMMARY) {
            if (!editing[key]) {
                editing[key] = {};
            }
            editing[key][index] = !editing[key][index];
            let keyState = { ...this.state[key] };
            keyState[index] = value;
            this.setState({
                editing,
                [key]: keyState
            })
        } else {
            editing[key] = !editing[key];
            this.setState({
                editing,
                [key]: value
            })
        }
    }

    handleChange(key, e, index) {
        if (key === SUMMARY) {
            let keyState = { ...this.state[key] };
            keyState[index] = e;
            this.setState({
                [key]: keyState
            })
        } else {
            this.setState({
                [key]: e.target.value
            })
        }
    }

    handleWatchlistChange(val) {
        this.setState({
            selectedWatchlists: val
        });
    }

    handleAddToWatchlists() {
        let watchlists = JSON.parse(JSON.stringify(this.state.selectedWatchlists));
        this.addToWatchlists(watchlists, (saveResultCollections) => {
            let showPopup = { ...this.state.showPopup };

            // they are adding bills to one or more watchlist(s) - we need to update the selectedWatchlists state values because their mod dates have now changed
            for (let i = 0; i < watchlists.length; i++) {
                const associatedWatchlist = saveResultCollections.find(c => c.WatchListID === watchlists[i].WatchListID);
                if (associatedWatchlist && associatedWatchlist.ModificationDate !== watchlists[i].ModificationDate) {
                    watchlists[i] = associatedWatchlist
                };
            }

            this.setState({ selectedWatchlists: [] ? watchlists : [], selectedMergeWatchlists: [], showPopup })
        });
    }

    addToWatchlists(watchlists) {
        let duplicatedBills = [];
        watchlists.forEach(watchlist => {
            //Replace each instance of bill with the bill details in state here
            if (watchlist.__isNew__) {
                watchlist.Name = watchlist.label;
                watchlist.Description = `Created on ${moment().format('MM/DD/YYYY')}`;
                watchlist.WatchListLegislation = [];
                delete watchlist.label;
                delete watchlist.__isNew__;
                delete watchlist.value;
            }
            // Make sure the bill isn't already a part of the watchlist
            if (!watchlist.WatchListLegislation.find(leg => leg.LegislationID === this.state.billData.LegislationID) || watchlist.WatchListLegislation.find(leg => leg.LegislationID === this.state.billData.LegislationID).DeletionDate) {
                watchlist.WatchListLegislation.push({
                    LegislationID: this.state.billData.LegislationID,
                    LegislationNumber: this.state.billData.LegislationNumber,
                    Sessions: [{ SessionID: this.state.billData.SessionID }],
                    WatchListID: watchlist.WatchListID,
                    Note: null
                });
            } else {
                duplicatedBills.push({ "Watchlist": watchlist.Name, "LegislationNumber": this.state.billData.LegislationNumber });
            }
        })

        this.setState({
            collectionIsSaving: true
        }, () => {
            this.props.actions.saveCollections({ WatchLists: watchlists })
                .then(() => {
                    this.setState({
                        collectionIsSaving: false,
                        showWatchlistForm: false,
                        selectedWatchlists: []
                    });

                    this.getCollections("?SessionID=" + this.state.billData.SessionID)

                    if (duplicatedBills.length) {
                        this.props.actions.makeToast([{ message: duplicatedBills[0].LegislationNumber + " is already in " + (watchlists.length === 1 ? "this watchlist. " : "one or more of these watchlists. "), type: "warning", long: true }]);
                    } else {
                        this.props.actions.makeToast([{ message: this.state.billData.LegislationNumber + " was added to your watchlist" + (watchlists.length > 1 ? "s" : (" " + watchlists[0].Name)), type: "success" }]);
                    }
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    console.log(err)
                    this.setState({
                        collectionIsSaving: false
                    });
                    let message;
                    if (err && JSON.parse(err)) {
                        const errorMsg = JSON.parse(err).Name;
                        message = errorMsg && errorMsg.includes('Public users are') ? errorMsg : 'Save Failed'
                    } else {
                        message = "Save Failed";
                    }
                    this.props.actions.makeToast([{ message: message, type: "failure", long: message.includes('Public users are') }]);
                })
        });
    }

    getCollections(params) {
        this.setState({
            collectionIsLoading: true
        });
        this.props.actions.getCollections(params)
            .then(() => {
                const parsed = queryString.parse(this.props.location.search);
                const collectionIndex = this.props.collection.collections.findIndex(coll => coll.WatchListID === parseInt(parsed.collection))
                this.setState({
                    collectionIsLoading: false,
                }, () => {
                    if (collectionIndex !== -1) {
                        this.selectCollection(collectionIndex)
                    }
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return
                }
                this.setState({
                    collectionIsLoading: false
                });
            });
    }

    toggleWatchlistForm() {
        this.setState({
            showWatchlistForm: !this.state.showWatchlistForm
        })
    }

    async submitImpactStatement(description, agency, file) {
        let params = "?legislationNumber=" + this.props.match.params.billnumber
            + "&sessionCode=" + this.props.match.params.sessionCode
            + "&referenceNumber=" + this.state.statementDocumentcode + "F" + agency.AgencyNumber
            + "&description=" + description

        this.setState({
            statementSubmitting: true
        }, () => {
            this.props.actions.uploadImpactStatement(params, file)
                .then(() => {
                    this.setState({
                        statementSubmitting: false
                    }, () => {
                        this.closeStatementForm();
                    })
                    this.props.actions.makeToast([{ message: "Impact Statement Upload Successful", type: "success" }]);
                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        statementSubmitting: false
                    });
                    this.props.actions.makeToast([{ message: "Impact Statement Upload Failed", type: "failure" }]);
                })
        })
    }

    togglehideAllEvents() {
        this.setState({
            hideAllEvents: !this.state.hideAllEvents
        })
    }

    checkForDuplicates(summary) {
        let results = summary.match(/[H|S][B|J|R][\s|&nbsp;]+[0-9]+/g);
        if (results) {
            //.trim() and .replace(" ", "") both didn't work, even after being run through .toString(). I'm just glad this works. - JGB
            results.forEach((match) => {
                let breaklessMatch = match.replace('&nbsp;', ' ')
                let billNum = breaklessMatch.slice(0, 2) + breaklessMatch.slice(3);
                let link = `<a href="/bill-details/` + this.state.billData.SessionCode + `/` + billNum + `">` + breaklessMatch + `</a>`;
                summary = summary.replace(match, link);
            });
            return summary
        } else {
            return summary
        }
    }

    toggleDeleteSummaryModal(summary, summaryIndex) {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            summaryToDelete: { ...summary, index: summaryIndex }
        })
    }

    togglePatronModal() {
        this.props.history.push({
            pathname: `/bill-details/${this.state.billData.SessionCode}/${this.state.billData.LegislationNumber}/text/${this.state.versionsList.find(v => v.LegislationVersion === "Introduced")?.DocumentCode}`,
            state: { patronUpdate: true }
        })
    }

    handleToggleDescriptionEdit(index) {
        let versionsList = this.state['slideVersions'] ? [...this.state.versionsList] : [...this.state.versionsList.filter(version => version.IsActive === true || (version.TextDisposition === "Offered" && version.CommitteeID !== undefined))];
        if (versionsList.length > 0) {
            if (versionsList[index].editing === undefined) {
                versionsList[index].editing = true;
                versionsList[index].tempDescription = versionsList[index].Description;
            } else {
                versionsList[index].editing = !versionsList[index].editing;
            }
            this.setState({
                versionsList
            })
        }
    }

    handleDescriptionChange(value, index) {
        let versionsList = this.state['slideVersions'] ? [...this.state.versionsList] : [...versionsList.filter(version => version.IsActive === true || (version.TextDisposition === "Offered" && version.CommitteeID !== undefined))];
        versionsList[index].tempDescription = value;
        this.setState({
            versionsList
        })
    }

    saveVersionDescription(index) {
        let bill = this.state['slideVersions'] ? { ... this.state.versionsList[index] } : { ...versionsList.filter(version => version.IsActive === true || (version.TextDisposition === "Offered" && version.CommitteeID !== undefined))[index] };
        bill.Description = bill.tempDescription;
        this.props.actions.saveBillText({ TextsList: [bill] })
            .then(() => {
                this.getBillVersion(this.props.match.params.billnumber, this.props.match.params.sessionCode);
            });
    }

    render() {
        const { sessionData, summariesList, historyList, billData, senatePatronsList, housePatronsList, chiefPatronsList, versionsList, editing, description, saving, patronList } = this.state;
        // Show the active and offered versions when drawer is collapsed. If there are no active or offered versions then just display the last item
        let activeVersions = versionsList.filter(version => version.IsActive === true || (version.TextDisposition === "Offered" && version.CommitteeID !== undefined) || (version.TextDisposition === "Recommended"));
        if (activeVersions.length === 0) {
            activeVersions = [versionsList[versionsList.length - 1]];
        }
        let billSummary = '';
        if (summariesList.length > 0) {
            billSummary = summariesList[0];
        }
        const sessionHeader = sessionData && !isStatic ? sessionData.SessionYear + ' ' + sessionData.DisplayName : '';
        const billHeader = billData && !isStatic ? <React.Fragment>
            <span style={{ marginRight: '1em' }}>{billData.LegislationNumber}</ span>
            <span></span>
            <span>{renderHTML(billData.Description || '')}</span>
            {this.props.login.userClaims.resources.find(resource => resource === BILL_AUTHOR) && <a className="icon edit" style={{ marginLeft: "5px" }} onClick={() => this.toggleEdit(DESCRIPTION, this.state.billData.Description)}></a>}
        </React.Fragment> : '';

        const billHeaderEditor = <div className="bill-description-editor">
            <span>{billData.LegislationNumber}</ span>
            <input value={description} onChange={(e) => this.handleChange(DESCRIPTION, e)} disabled={saving[DESCRIPTION]} />
            {saving[DESCRIPTION]
                ? <div className="small-spinner"></div>
                : <React.Fragment><a className="icon save" onClick={() => this.saveDescription()}></a>
                    <a className="icon delete" onClick={() => this.toggleEdit(DESCRIPTION)}></a></React.Fragment>
            }
        </div>;

        const formatSummaryVersion = (billSummary) => (
            billSummary.SummaryVersion.toLowerCase().split("").map((x, i) => { return billSummary.SummaryVersion[i - 1] === " " || i === 0 ? x.toUpperCase() : x })
        )

        const billSummaryEditor = index => <div className="bill-summary-editor">
            <Editor
                disabled={saving[SUMMARY] && saving[SUMMARY][index]}
                value={this.state.summary[index]}
                init={{
                    browser_spellcheck: true,
                    height: 350,
                    plugins: 'link paste save lists preview searchreplace pagebreak code table',
                    toolbar: 'undo redo | bold italic strikethrough | alignleft aligncenter alignright',
                    skin: false,
                    valid_children: '+body[style]',
                    content_css: false,
                    content_style: 'table { border-collapse: collapse; border: 1px solid #FFFFFF }' + 'td { padding: 12px 0px }',
                }}
                onEditorChange={content => this.handleChange(SUMMARY, content, index)}
            />
            {saving[SUMMARY] && saving[SUMMARY][index]
                ? <div className="small-spinner"></div>
                : <React.Fragment><a className="icon save" onClick={() => this.saveSummary(index)}></a>
                    <a className="icon delete" onClick={() => this.toggleEdit(SUMMARY, null, index)}></a></React.Fragment>
            }
        </div>;

        if (!this.state.billDataIsLoaded) {
            return (
                <div><LoaderComponent className="center-spinner" data={false} /></div>
            )
        } else if (!billData) {
            //Bill Data has been loaded but if it is empty then there have been zero results returned from the server
            return (<p>Bill data could not be found</p>)
        }
        return (
            <div id="bill-details" className="bill-theme public-details inner-grid" ref={el => (this.componentRef = el)}>
                {this.state.showDeleteModal &&
                    <div className='schedule-modal'>
                        <div className='schedule-modal-content'>
                            <div className='grid-wrapper'>
                                <div className='inner-grid eight-one-and-one'>
                                    <span>Are you sure you want to delete this summary ({formatSummaryVersion(this.state.summaryToDelete)})?</span>
                                    {saving[SUMMARY] && saving[SUMMARY][this.state.summaryToDelete.index]
                                        ? <div className="small-spinner"></div>
                                        : <button className='button primary' onClick={() => this.saveSummary(this.state.summaryToDelete.index, true)}>Yes</button>
                                    }
                                    <button className='button secondary' onClick={() => this.toggleDeleteSummaryModal()}>No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {!isStatic &&
                    <div>
                        <h2 style={{ marginBottom: "10px" }}>{sessionHeader}</h2>
                    </div>
                }
                {!isStatic &&
                    <div className="details-header bill-details-header">
                        <h3>{!editing[DESCRIPTION] ? billHeader : billHeaderEditor}</h3>
                        {!this.state.displayStatementForm &&
                            <div style={{ padding: "10px 10px 0px 10px" }} className={`flex-row ${!this.state.editingHistory && this.props.login.userClaims.claims.find(claim => claim.RoleName === BILL_EVENT_AUTHOR) ? 'space-around' : 'flex-end'}`}>
                                {!this.state.editingHistory && this.props.login.userClaims.claims.find(claim => claim.RoleName === BILL_EVENT_AUTHOR) &&
                                    <button type="button" className="button-link remove-from-print" style={{ fontSize: '0.8em' }} onClick={this.toggleEditContent}>Edit History</button>
                                }
                                <ReactToPrint
                                    trigger={() => <a type="button" className="button print remove-from-print"> Print</a>}
                                    content={() => {
                                        let printedContent = this.componentRef.cloneNode(true);
                                        //remove icons from printed document
                                        const removedContent = printedContent.getElementsByClassName('remove-from-print');
                                        for (const el of removedContent) {
                                            el.innerHTML = ''
                                        }
                                        return printedContent
                                    }}
                                    pageStyle={"break-inside: avoid"}
                                />
                            </div>
                        }
                    </div>
                }
                <div>
                    <div style={this.state.editingHistory || this.state.displayStatementForm ? { display: 'none' } : { display: 'block' }}>
                        {this.props.login.isAuthenticated && <div className="dlas-forms collection-controls" style={{ marginBottom: "5px" }}>
                            <a style={{ fontSize: "14px", cursor: "pointer" }} onClick={() => this.toggleWatchlistForm()}>{this.state.showWatchlistForm ? "Hide watchlist form" : "Add bill to watchlist"}</a>
                            {this.state.showWatchlistForm &&
                                <div className="inner-grid two">
                                    <div>
                                        <label htmlFor="sr-watchlist-name-control">Watchlist Name</label>
                                        <CreatableSelect
                                            id="sr-watchlist-name-control"
                                            styles={selectStyle}
                                            options={this.props.collection.collections}
                                            getOptionLabel={opt => opt.label || `${opt.Name} ${opt.Description && `- ${opt.Description}`}`}
                                            getOptionValue={opt => opt.value || opt.WatchListID}
                                            value={this.state.selectedWatchlists}
                                            onChange={this.handleWatchlistChange}
                                            createOptionPosition="first"
                                            formatCreateLabel={input => <span>Create new: {input}</span>}
                                            isMulti
                                        />
                                    </div>
                                    <div className="inline-list">
                                        <br />
                                        {this.props.collectionIsSaving ?
                                            <div><span className="small-spinner"></span></div>
                                            :
                                            <React.Fragment>
                                                <button disabled={(this.state.selectedWatchlists && this.state.selectedWatchlists.length < 1 ? true : false) || !this.state.selectedWatchlists} style={{ marginLeft: "0px" }} className="button" type="button" onClick={() => this.handleAddToWatchlists(false)}>Add</button>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>}
                        </div>}
                        {!isStatic &&
                            <div style={{ marginTop: "-3px" }} className="list-desc">
                                <p>Status: {billData.LegislationStatus}</p>
                            </div>
                        }
                        {this.props.login.userClaims.resources.find(resource => resource === BILL_COLLECTION_AUTHOR) && billData && !isStatic &&
                            <CollectionNotes
                                billData={billData}
                                sessionId={this.props.session.selectedSession.SessionID}
                            />
                        }
                        <div style={{ marginBottom: "0px" }} className={this.state['slidePatron'] ? 'parent-li slidedown-open' : 'parent-li slidedown-closed'}>
                            <div className="toggle-row">
                                <div className="description" style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                    <h4>{chiefPatronsList.length + housePatronsList.length + senatePatronsList.length > 1 ? "Patrons" : "Patron"}</h4>
                                    {versionsList.find(v => v.LegislationVersion === "Introduced") && this.props.login.userClaims.claims.find(claim => claim.RoleName === "Admin" || (claim.Resource === PATRON_AUTHOR && claim.Scope === (this.state.billData.ChamberCode === "H" ? "House" : "Senate"))) &&
                                        <button className="button small-button" onClick={this.togglePatronModal}>Update Patrons</button>
                                    }
                                </div>
                                {chiefPatronsList.length + housePatronsList.length + senatePatronsList.length > 1 &&
                                    <div className="button-container">
                                        <label id="sr-patrons-drawer-label" className="arrow-down-label" onClick={this.toggleShowData.bind(this, 'slidePatron')}>All Patrons</label>
                                        <button aria-labelledby="sr-patrons-drawer-label" aria-expanded={!!this.state['slidePatron']} className="arrow-down" onClick={this.toggleShowData.bind(this, 'slidePatron')}>More info</button>
                                    </div>
                                }
                            </div>
                        </div>
                        {!this.state.patronIsLoaded ? <LoaderComponent data={false} /> :
                            <div style={{ marginBottom: "10px" }}>
                                <div className="list-desc">
                                    <p style={this.state['slidePatron'] ? { marginBottom: "0px" } : { marginBottom: "20px" }}>Introduced by: <PatronList session={sessionData} patrons={chiefPatronsList} /></p>
                                </div>
                                {this.state['slidePatron'] &&
                                    <div className="patrons-list list-desc">
                                        {this.state.billData.ChamberCode === "H"
                                            ?
                                            <React.Fragment>
                                                {housePatronsList.length > 0 && <div><span>House: </span><PatronList session={sessionData} patrons={housePatronsList} list /></div>}
                                                {senatePatronsList.length > 0 && <div><span>Senate: </span><PatronList session={sessionData} patrons={senatePatronsList} list /></div>}
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                {senatePatronsList.length > 0 && <div><span>Senate: </span><PatronList session={sessionData} patrons={senatePatronsList} list /></div>}
                                                {housePatronsList.length > 0 && <div><span>House: </span><PatronList session={sessionData} patrons={housePatronsList} list /></div>}
                                            </React.Fragment>
                                        }
                                    </div>
                                }
                            </div>
                        }
                        {summariesList.length > 1 &&
                            <div className={this.state['slideSummaries'] ? 'parent-li slidedown-open' : 'parent-li slidedown-closed'}>
                                <div className="toggle-row">
                                    <br />
                                    <div className="button-container">
                                        <label id="sr-summaries-drawer-label" className="arrow-down-label" onClick={this.toggleShowData.bind(this, 'slideSummaries')}>All Summaries</label>
                                        <button aria-labelledby="sr-summaries-drawer-label" aria-expanded={!!this.state['slideSummaries']} className="arrow-down" onClick={this.toggleShowData.bind(this, 'slideSummaries')}>More info</button>
                                    </div>
                                </div>
                            </div>
                        }
                        {!this.state.summaryIsLoaded ? <LoaderComponent data={false} /> :
                            <div className="bill-details-summary">
                                <div className="list-desc">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <h4 style={{ paddingLeft: '0px' }}>{formatSummaryVersion(billSummary)}</h4>
                                        {(!editing[SUMMARY] || !editing[SUMMARY][0]) && this.props.login.userClaims.resources.find(resource => resource === BILL_AUTHOR) &&
                                            <React.Fragment>
                                                <a className="icon edit" style={{ margin: "0px 7px" }} onClick={() => this.toggleEdit(SUMMARY, billSummary.Summary ? this.checkForDuplicates(billSummary.Summary) : '', 0)}></a>
                                                <a className="icon delete" onClick={() => this.toggleDeleteSummaryModal(billSummary, 0)}></a>
                                            </React.Fragment>
                                        }
                                    </div>
                                    {!editing[SUMMARY] || !editing[SUMMARY][0] ?
                                        renderHTML(this.checkForDuplicates(billSummary.Summary) || '') :
                                        billSummaryEditor(0)
                                    }
                                </div>
                                {this.state['slideSummaries'] &&
                                    <div className="list-desc" style={{ marginTop: "10px" }}>
                                        <span>
                                            {
                                                summariesList.map((summaries, i) => {
                                                    if (i > 0) {
                                                        return (
                                                            <div>
                                                                <div key={i} style={{ display: 'flex' }}> <p><strong>{summaries.SummaryVersion}</strong></p>
                                                                    {(!editing[SUMMARY] || !editing[SUMMARY][i]) && this.props.login.userClaims.resources.find(resource => resource === BILL_AUTHOR) &&
                                                                        <React.Fragment>
                                                                            <a className="icon edit" style={{ margin: "0px 7px" }} onClick={() => this.toggleEdit(SUMMARY, summaries.Summary ? this.checkForDuplicates(summaries.Summary) : '', i)}></a>
                                                                            <a className="icon delete" onClick={() => this.toggleDeleteSummaryModal(summaries, i)}></a>
                                                                        </React.Fragment>
                                                                    }
                                                                </div>
                                                                {!editing[SUMMARY] || !editing[SUMMARY][i] ?
                                                                    renderHTML(summaries.Summary ? this.checkForDuplicates(summaries.Summary) : '')
                                                                    :
                                                                    billSummaryEditor(i)
                                                                }
                                                            </div>
                                                        )
                                                    } else {
                                                        return false;
                                                    }

                                                })
                                            }
                                        </span>
                                    </div>
                                }
                            </div>
                        }
                        <div>
                            <div style={{ marginBottom: "0px" }} className={this.state['slideVersions'] ? 'parent-li slidedown-open' : 'parent-li slidedown-closed'}>
                                <div className="toggle-row">
                                    <div className="table-container description">
                                        <h4>Bill Versions</h4>
                                        <button onClick={this.toggleShowTooltip.bind(this)} className="button info">Info</button>
                                        {this.state.toolTip &&
                                            <div className="info-box">
                                                <ol>
                                                    <li> Introduced - A bill that has been introduced and referred to a committee. </li>
                                                    <li> Engrossed - A stage in the legislative process when a bill passes the second reading in the house of origin. If amended, the engrossed version of the legislation is printed incorporating all amendments that are agreed to. If not amended, the introduced version of the legislation becomes the engrossed bill, and if an amendment in the nature of a substitute is agreed to, the substitute becomes the engrossed bill.</li>
                                                    <li> Enrolled - Legislation which has passed both the Senate and the House of Delegates. It has been signed by the President of the Senate and the Speaker of the House and it has been sent to the Governor, but not yet signed. If signed by the Governor, the bill becomes a law and a Chapter of the Acts of Assembly.</li>
                                                    <li> Chaptered - A bill passed and signed into law by the Governor. The term also applies to constitutional amendments passed by the General Assembly and to bills passed notwithstanding the Governor’s objections or amendments.</li>
                                                    <li> Substitute - The redrafting of legislation that incorporates proposed changes in a new version. </li>
                                                </ol>
                                            </div>
                                        }
                                    </div>
                                    {versionsList.length > 1 && versionsList.find(version => !version.IsActive) &&
                                        <div className="button-container">
                                            <label id="sr-versions-drawer-label" className="arrow-down-label" onClick={this.toggleShowData.bind(this, 'slideVersions')}>{this.state.slideVersions ? "Hide All Versions" : "Show All Versions"}</label>
                                            <button aria-labelledby="sr-versions-drawer-label" aria-expanded={!!this.state['slideVersions']} className="arrow-down" onClick={this.toggleShowData.bind(this, 'slideVersions')}>More info</button>
                                        </div>
                                    }
                                </div>
                                {this.props.login.userClaims.resources.find(resource => resource === VERSION_AUTHOR) &&
                                    <Link to={`/bill-details/${sessionData.SessionCode}/${billData.LegislationNumber}/text/new-amendment`} className="button small-button remove-from-print">Create Amendment</Link>
                                }
                            </div>
                            <table className="line-table">
                                {!this.state.versionsIsLoaded ? (<LoaderComponent data={false} />) : (
                                    <React.Fragment>
                                        {versionsList.length > 0 &&
                                            <div>
                                                {this.state['slideVersions'] ?
                                                    <VersionList userClaims={this.props.login.userClaims} descriptionEditable={true} handleDescriptionChange={this.handleDescriptionChange} handleToggleDescriptionEdit={this.handleToggleDescriptionEdit} saveDescription={this.saveVersionDescription} version={versionsList} toggleStatementForm={this.toggleStatementForm} legislationClass={billData.LegislationClass} />
                                                    :
                                                    <VersionList userClaims={this.props.login.userClaims} descriptionEditable={true} handleDescriptionChange={this.handleDescriptionChange} handleToggleDescriptionEdit={this.handleToggleDescriptionEdit} saveDescription={this.saveVersionDescription} version={activeVersions} toggleStatementForm={this.toggleStatementForm} legislationClass={billData.LegislationClass} />
                                                }
                                            </div>
                                        }
                                    </React.Fragment>
                                )}
                            </table>
                        </div>
                    </div>
                    <div>
                        {!this.state.displayStatementForm &&
                            <React.Fragment>
                                <h4 style={{ display: 'inline-block', marginRight: '8px' }}>History</h4>
                                {!this.state.editingHistory && this.props.login.userClaims.claims.find(claim => claim.RoleName === BILL_EVENT_AUTHOR) &&
                                    <button type="button" className="button-link" style={{ fontSize: '0.8em' }} onClick={this.toggleEditContent}>(Edit)</button>
                                }
                            </React.Fragment>}
                        {this.state.editingHistory && this.state.historyIsLoaded ?
                            <BillHistoryForm
                                switchedToBill={this.state.switchedToBill}
                                legislationNumber={this.state.legislationNumber}
                                historyList={historyList}
                                organizedEvents={this.state.organizedEvents}
                                handleEventChange={this.handleEventChange}
                                billEvents={this.state.billEvents}
                                billData={this.state.billData}
                                toggleEditContent={this.toggleEditContent}
                                saveEvents={this.saveEvents}
                                hideAllEvents={this.state.hideAllEvents}
                                togglehideAllEvents={this.togglehideAllEvents}
                                changeToNewBillHistoryForm={this.changeToNewBillHistoryForm}
                                actionTypes={this.state.actionTypes}
                                memberList={this.state.memberList}
                                committeeList={this.state.committeeList}
                                versionsList={this.state.versionsList.map(text => ({
                                    ...text,
                                    label: text.DocumentCode + " " + text.Description,
                                    value: text.LegislationTextID
                                }))}
                                reconsiderationEvents={this.state.reconsiderationEvents}
                                getReconsiderationEvents={this.getReconsiderationEvents}
                            />
                            :
                            <React.Fragment>
                                {!this.state.historyIsLoaded ? <LoaderComponent data={false} /> :
                                    !this.state.displayStatementForm &&
                                    <React.Fragment>
                                        <table className="line-table">
                                            <tbody>
                                                {historyList.filter(item => !item.DeletionDate).map((item, index) => (
                                                    <tr key={index} className={`parent-tr ${item.opened ? 'slidedown-open' : 'slidedown-closed'} bill-details-table-row${item.IsPublic ? '' : ' not-public'}`}>
                                                        <td className="left">{moment(item.EventDate).format('l')}</td>
                                                        <td className="left">{item.ActorType}</td>
                                                        <td className="left">
                                                            {item.Description}
                                                            {item.VoteLinks && item.opened &&
                                                                <div className="link-row">
                                                                    {item.VoteLinks.map((link, index) =>
                                                                        <div key={index}>
                                                                            {link.VoteID && item.VoteTally !== "(Voice Vote)" ? link.IsPublic ? <Link to={`/vote-details/${this.state.billData.LegislationNumber}/${item.SessionCode}/${link.VoteNumber}`}>Vote</Link> : <span>Vote Detail Pending</span> : ''}
                                                                            {link.CommitteeNumber ? <Link to={`/session-details/${item.SessionCode}/committee-information/${link.CommitteeNumber}/committee-details`}>{link.ParentCommitteeID ? 'Subcommittee' : 'Committee'}</Link> : ''}
                                                                            {link.LegislationNumber && link.SessionCode ? <Link to={`/bill-details/${link.SessionCode}/${link.LegislationNumber}`}>Legislation</Link> : ''}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            }
                                                        </td>
                                                        <td className="button-container">
                                                            {item.ReferenceType && item.ReferenceType.toLowerCase() === "committee" ||
                                                                item.ReferenceType && item.ReferenceType.toLowerCase() === "subcommittee" ||
                                                                item.ReferenceType && item.ReferenceType.toLowerCase() === "legislation" ||
                                                                item.VoteID && item.ReferenceType && item.ReferenceType.toLowerCase() === "vote" && item.VoteTally !== "(Voice Vote)"
                                                                ? <React.Fragment>
                                                                    {item.isLoading ?
                                                                        <span className="small-spinner"></span>
                                                                        :
                                                                        <button className="arrow-down" onClick={() => this.toggleShowLinks(item)}>Show links</button>
                                                                    }
                                                                </React.Fragment>
                                                                : null
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </div>
                    <div>
                        {this.state.displayStatementForm &&
                            <ImpactStatementForm
                                submit={this.submitImpactStatement}
                                isSubmitting={this.state.statementSubmitting}
                                toggleStatementForm={this.toggleStatementForm}
                                statementDate={this.state.statementDate}
                                statementDescription={this.state.statementDescription}
                                agencyOptions={this.state.agencyOptions}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const PublicBillDetails = connect(
    (state) => {
        const { bills, session, patrons, votes, login, nav, minutes, members, committee, organizations, collection } = state;
        return {
            bills,
            session,
            patrons,
            votes,
            login,
            nav,
            minutes,
            members,
            committee,
            organizations,
            collection
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, voteActionCreators, billActionCreators, sessionActionCreators, patronActionCreators, authActionCreators, navActionCreators, minutesActionCreators, memberActionCreators, committeeActionCreators, orgActionCreators, collectionActionCreators), dispatch)
        }
    }
)(BillDetailsComponent)

export default PublicBillDetails;
